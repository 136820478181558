<template>
  <div class="container-lddc">
    <v-row class="mt-10 px-5">
      <h2 class="mullerFont" style="font-size: 1.5rem">GÉNÉRATEUR DE LETTRE DE DEVOIR DE CONSEIL</h2>
    </v-row>
    <v-divider class="my-3"></v-divider>
    <v-row class="mt-6">
      <v-col v-if="e1 > 1" cols="12" :md="e1 < 9 ? '2' : '12'" class="d-flex">
        <v-card class="flex d-flex flex-column" outlined>
          <v-progress-linear
            v-if="e1 >= 2 && e1 <= 7"
            color="EoleBlue"
            height="5"
            :value="progressionPdfProgressBar"
            top
          >
          </v-progress-linear>
          <v-card-title class="">Mes étapes</v-card-title>
          <v-divider class="mx-5"></v-divider>
          <v-card-text class="flex-grow-1">
            <v-stepper v-model="e1" vertical elevation="0">
              <v-stepper-header style="height: auto !important">
                <template>
                  <v-stepper-step
                    :key="`0-step`"
                    :color="e1 > 1 ? 'EoleYellow' : 'EoleBlueLighten'"
                    :complete="e1 > 1"
                    :step="1"
                    :rules="[() => this.steppersInformations[0].rule]"
                    editable
                  >
                    {{ this.steppersInformations[0].name }}<small>{{ this.steppersInformations[0].description }}</small>
                  </v-stepper-step>

                  <v-stepper-step
                    :key="`1-step`"
                    :color="e1 > 2 ? 'EoleYellow' : 'EoleBlueLighten'"
                    :complete="e1 > 2"
                    :step="2"
                    :rules="[() => this.steppersInformations[1].rule]"
                    editable
                  >
                    {{ this.steppersInformations[1].name }}<small>{{ this.steppersInformations[1].description }}</small>
                  </v-stepper-step>

                  <v-stepper-step
                    :key="`2-step`"
                    :color="e1 > 3 ? 'EoleYellow' : 'EoleBlueLighten'"
                    :complete="e1 > 3"
                    :step="3"
                    :rules="[() => this.steppersInformations[2].rule]"
                    editable
                  >
                    {{ this.steppersInformations[2].name }}<small>{{ this.steppersInformations[2].description }}</small>
                  </v-stepper-step>

                  <v-stepper-step
                    :key="`3-step`"
                    :step="4"
                    :color="e1 > 4 ? 'EoleYellow' : 'EoleBlueLighten'"
                    :complete="e1 > 4"
                    editable
                  >
                    {{ this.steppersInformations[3].name }}<small>{{ this.steppersInformations[3].description }}</small>
                  </v-stepper-step>

                  <div class="ml-5">
                    <v-stepper
                      v-if="!!productName && productName === 'Automobile' && e1 === 4"
                      v-model="autoStepper"
                      vertical
                      style="padding-bottom: 0px !important"
                      elevation="0"
                    >
                      <v-stepper-header style="height: auto !important">
                        <v-stepper-step :complete="autoStepper > 1" step="1"> Informations générales </v-stepper-step>

                        <v-stepper-step v-show="autoStepper > 1" :complete="autoStepper > 2" step="2">
                          Informations complémentaires</v-stepper-step
                        >

                        <v-stepper-step v-show="autoStepper > 2" :complete="autoStepper > 3" step="3">
                          Découverte usage du véhicule
                        </v-stepper-step>

                        <v-stepper-step v-show="autoStepper > 3" :complete="autoStepper > 4" step="4">
                          Découverte lieu de stationnement
                        </v-stepper-step>

                        <v-stepper-step v-show="autoStepper > 4" :complete="autoStepper > 5" step="5">
                          Découvert du financement du véhicule</v-stepper-step
                        >

                        <v-stepper-step v-show="autoStepper > 5" :complete="autoStepper > 6" step="6">
                          Découverte remorque</v-stepper-step
                        >

                        <v-stepper-step v-show="autoStepper > 6" :complete="autoStepper > 7" step="7">
                          Découverte éléments d’équipements et effets personnels
                        </v-stepper-step>
                        <v-stepper-step v-show="autoStepper > 7" :complete="autoStepper > 8" step="8">
                          Notre préconisation
                        </v-stepper-step>
                      </v-stepper-header>
                    </v-stepper>
                  </div>

                  <v-stepper-step
                    :key="`4-step`"
                    :step="5"
                    :color="e1 > 5 ? 'EoleGreen' : 'EoleBlueLighten'"
                    :complete="e1 > 5"
                    editable
                  >
                    {{ this.steppersInformations[4].name }}<small>{{ this.steppersInformations[4].description }}</small>
                  </v-stepper-step>

                  <v-stepper-step
                    :key="`5-step`"
                    :step="6"
                    :color="e1 > 6 ? 'EoleGreen' : 'EoleBlueLighten'"
                    :complete="e1 > 6"
                  >
                    {{ this.steppersInformations[5].name }}<small>{{ this.steppersInformations[5].description }}</small>
                  </v-stepper-step>

                  <v-stepper-step
                    :key="`6-step`"
                    :step="7"
                    :color="e1 > 7 ? 'EoleGreen' : 'EoleBlueLighten'"
                    :complete="e1 > 7"
                    editable
                  >
                    {{ this.steppersInformations[6].name }}<small>{{ this.steppersInformations[6].description }}</small>
                  </v-stepper-step>
                </template>
              </v-stepper-header>
            </v-stepper>
          </v-card-text>
          <v-divider class="mx-5"></v-divider>
          <v-card-actions class="text-center justify-center no-paddinLeftRight">
            <v-btn
              block
              elevation="0"
              :disabled="(!existingDrafts && newDocument) || loadingDataSave"
              @click="
                () => {
                  newDocument = false
                  existingDrafts = true
                  radios = null
                  e1 = 1
                  updateDrafts()
                }
              "
            >
              Retourner à mes LDDC
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col v-show="e1 < 9" cols="12" :md="e1 > 1 ? '10' : '12'">
        <v-row v-if="existingDrafts && !newDocument" class="no-padding draft-placement">
          <v-col cols="12" md="12">
            <v-row align="stretch">
              <v-col cols="12" md="9" class="">
                <v-card outlined>
                  <v-card-title class="EoleBlue" style="color: white !important; font-size: 1rem">
                    Mes lettres en attentes
                    <v-spacer></v-spacer>
                  </v-card-title>
                  <v-data-table
                    :search="searchIntoDrafts"
                    :headers="draftsHeaders"
                    :items="drafts"
                    hide-default-footer
                    :hide-default-header="drafts.length === 0"
                    :custom-filter="searchByClientName"
                    :items-per-page="10"
                    :page.sync="page"
                    @page-count="pageCount = $event"
                    no-results-text="Nous n'avons trouvé aucun résultat correspondant à votre recherche"
                  >
                    <template v-slot:item="props">
                      <tr>
                        <td>
                          {{ props.item.customerData.civility }}.
                          {{ props.item.customerData.lastname }}
                          {{ props.item.customerData.firstname }}
                        </td>
                        <td>{{ props.item.productName }}</td>
                        <td>{{ props.item.lastUpdate }}</td>
                        <td>
                          <v-tooltip top v-if="props.item.adviceSend">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" color="EoleGreen" small> mdi-check-circle</v-icon>
                            </template>
                            <span>Conseil envoyé</span>
                          </v-tooltip>
                          <v-tooltip top v-else>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" color="EoleError" small> mdi-close-thick</v-icon>
                            </template>
                            <span>Non envoyé pour le moment</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <div v-if="props.item.documents.documentSend">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" color="EoleGreen" small> mdi-check-circle</v-icon>
                              </template>
                              <span>Devis envoyé {{ props.item.documents.documentSendDate }}</span>
                            </v-tooltip>
                          </div>
                          <div v-else>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" color="EoleError" small> mdi-close-thick</v-icon>
                              </template>
                              <span>Non envoyé pour le moment</span>
                            </v-tooltip>
                          </div>
                        </td>
                        <td>
                          <v-tooltip top v-if="!props.item.documents.documentSend">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" text icon @click="workingDraft(props.item.id)" small>
                                <v-icon> mdi-file-replace </v-icon>
                              </v-btn>
                            </template>
                            <span>Injecter mes devis</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-menu right bottom transition="slide-x-transition" close-on-content-click>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>

                            <v-list>
                              <v-menu offset-x right>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-list-item v-bind="attrs" v-on="on" link>
                                    <v-list-item-title>Afficher les informations d'éditions </v-list-item-title>
                                  </v-list-item>
                                </template>

                                <v-card outlined>
                                  <v-timeline align-top dense>
                                    <v-timeline-item
                                      icon-color="white"
                                      v-for="time in props.item.timeline"
                                      v-bind:key="time.log"
                                      :color="
                                        time.type === 'finish'
                                          ? 'EoleGreen'
                                          : time.type === 'wait'
                                          ? 'EoleYellow'
                                          : 'EoleBlue'
                                      "
                                      :icon="
                                        time.type === 'finish'
                                          ? 'mdi-check'
                                          : time.type === 'wait'
                                          ? 'mdi-pause'
                                          : 'mdi-content-save'
                                      "
                                    >
                                      <v-row class="pt-1 px-5">
                                        <v-col>
                                          <strong>{{ time.dateTime }}</strong>
                                          <div class="text-caption">{{ time.log }}</div>
                                        </v-col>
                                      </v-row>
                                    </v-timeline-item>
                                  </v-timeline>
                                </v-card>
                              </v-menu>

                              <v-list-item link>
                                <v-list-item-title>Visualiser les documents </v-list-item-title>
                              </v-list-item>
                              <v-list-item link>
                                <v-list-item-title>Afficher la fiche client </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <div class="text-center pt-2">
                    <v-pagination v-model="page" :length="pageCount"></v-pagination>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" md="3">
                <v-card
                  class="justify-center text-center"
                  link
                  outlined
                  height="100%"
                  @click="
                    () => {
                      existingDrafts = false
                      initializeAllData()
                    }
                  "
                >
                  <v-row align="center" class="fill-height">
                    <v-col cols="12" md="12">
                      <v-card-text><i class="fa-solid fa-file-circle-plus fa-8x EoleBlue--text"></i> </v-card-text>
                      <v-card-text class="text-h6 EoleBlue--text">Nouvelle DDAC</v-card-text>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-stepper v-else v-model="e1" class="transparent-bg" flat style="height: 100%">
          <v-stepper-content :step="1" class="text-center justify-center transparent-bg">
            <v-card-title class="justify-center" style="color: #fcc03c !important"
              >Choix de la typologie de votre client
            </v-card-title>
            <v-card-subtitle>Pour commencer, veuillez choisir la typologie de votre client. </v-card-subtitle>
            <v-row
              :class="[
                'text-center align-center d-inline-flex row-lddc mb-5 mt-5',
                $vuetify.breakpoint.xs ? 'justify-center' : '',
              ]"
            >
              <v-col cols="12" md="6" v-for="item in typologyChoice" v-bind:key="item.name">
                <v-card
                  @mouseover="item.hovered = true"
                  @mouseleave="item.hovered = false"
                  v-on:click="chooseTypology(item.name)"
                  outlined
                  width="200"
                  height="200"
                  elevation="3"
                  class="align-center d-flex rounded-xl"
                >
                  <v-card-text class="text-center pa-5">
                    <i
                      :class="['fa-solid', item.icon, 'fa-5x', item.hovered ? 'EoleYellow--text' : 'EoleBlue--text']"
                    ></i>
                    <p class="mt-5">{{ item.name }}</p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-items v-if="this.radios === 'Particulier'">
            <v-stepper-content :step="2" class="no-padding">
              <v-card-title class="EoleBlue" style="color: white !important; font-size: 1rem">
                <v-icon class="mr-2" color="white">mdi-account</v-icon> Informations personnelles
              </v-card-title>

              <v-card outlined>
                <v-card-text>
                  <v-form ref="formClientInformations" v-model="validityFormClientInformations" lazy-validation>
                    <v-row>
                      <v-col cols="12" md="2">
                        <v-select
                          dense
                          :rules="civilityRules"
                          :items="genderItems"
                          outlined
                          v-model="customerData.civility"
                          label="Civilité"
                          prepend-inner-icon="mdi-gender-male-female"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-text-field
                          dense
                          :rules="firstnameRules"
                          outlined
                          v-model="customerData.firstname"
                          label="Prénom"
                          prepend-inner-icon="mdi-account"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-text-field
                          dense
                          :rules="lastnameRules"
                          outlined
                          v-model="customerData.lastname"
                          label="Nom"
                          prepend-inner-icon="mdi-account"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="3">
                        <v-text-field
                          dense
                          :rules="phoneNumberRules"
                          outlined
                          v-model="customerData.phone"
                          label="Numéro téléphone"
                          prepend-inner-icon="mdi-phone"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          dense
                          :rules="emailRules"
                          outlined
                          v-model="customerData.email"
                          label="Email"
                          prepend-inner-icon="mdi-account"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="3">
                        <v-text-field
                          dense
                          :rules="addressRules"
                          outlined
                          v-model="customerData.street"
                          label="Adresse complète"
                          prepend-inner-icon="mdi-map-marker"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-autocomplete
                          :loading="isLoading"
                          :search-input.sync="search"
                          :items="cityItems"
                          outlined
                          v-model="customerData.city"
                          :value="customerData.zip"
                          label="Commune"
                          clearable
                          hide-details
                          hide-selected
                          prepend-inner-icon="mdi-home"
                          dense
                          no-filter
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-title> Rechercher une ville... </v-list-item-title>
                            </v-list-item>
                          </template>

                          <template v-slot:item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title>{{ item }}</v-list-item-title>
                            </v-list-item-content>
                          </template></v-autocomplete
                        >
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-text></v-card-text>
                <v-spacer></v-spacer>
                <v-divider></v-divider>

                <v-card-actions>
                  <v-btn @click="lastStep(2)" elevation="0">
                    <v-icon left> mdi-chevron-left </v-icon>
                    <span>Précédent</span>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn @click="validateFormClientInformations()" elevation="0">
                    <span>Continuer</span>
                    <v-icon right> mdi-chevron-right </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>

          <v-stepper-items v-else>
            <v-stepper-content :step="2" class="no-padding">
              <v-card-title class="EoleBlue">
                <v-icon left>mdi-office-building</v-icon> Renseignement de société
              </v-card-title>
              <v-card flat>
                <v-card-text class="pa-5">
                  <loading
                    transition="fade"
                    :active.sync="loadingData"
                    :can-cancel="false"
                    :is-full-page="false"
                    color="#efb639"
                    :opacity="0.9"
                  >
                  </loading>

                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        :error="siretNotFound"
                        :error-messages="siretNotFoundMessage"
                        type="number"
                        counter="14"
                        outlined
                        v-model="siret"
                        label="Entrez votre n° de siret"
                        prepend-inner-icon="mdi-briefcase"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="1">
                      <v-divider class="ml-7" vertical></v-divider>
                    </v-col>
                    <v-col cols="7" v-if="dataPappers">
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            outlined
                            v-model="pappersInformations[0].nom_entreprise"
                            label="Raison sociale"
                            prepend-inner-icon="mdi-briefcase"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            outlined
                            v-model="pappersInformations[0].siege.siret"
                            label="Siret"
                            prepend-inner-icon="mdi-briefcase"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-text-field
                            outlined
                            v-model="pappersInformations[0].siege.code_postal"
                            label="Code Postale"
                            prepend-inner-icon="mdi-map-marker"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            outlined
                            v-model="pappersInformations[0].siege.adresse_ligne_1"
                            label="Addresse"
                            prepend-inner-icon="mdi-map-marker"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            outlined
                            v-model="pappersInformations[0].siege.ville"
                            label="Ville"
                            prepend-inner-icon="mdi-map-marker"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-title v-if="dataPappers" class="EoleBlueLighten">
                  <v-icon class="mr-2">mdi-information</v-icon> Description de l'activité de
                  {{ pappersInformations[0].nom_entreprise }}
                </v-card-title>

                <v-card-text v-if="dataPappers" class="pa-5">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        v-model="pappersInformations[0].siege.code_naf"
                        label="Code NAF/APE"
                        prepend-inner-icon="mdi-briefcase"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        v-model="pappersInformations[0].siege.libelle_code_naf"
                        label="Description de l'activité"
                        prepend-inner-icon="mdi-briefcase"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-title v-if="dataPappers" class="EoleBlueLighten">
                  <v-icon class="mr-2">mdi-account-tie</v-icon> A propos des dirigeants
                </v-card-title>
                <v-card-text v-if="dataPappers" class="pa-5 mb-5">
                  <v-row>
                    <v-col cols="12" md="4"></v-col>
                    <v-col cols="12" md="5"></v-col>
                    <v-col class="text-right" cols="12" md="3">Signataire du document :</v-col>
                  </v-row>
                  <v-row v-for="(director, index) in pappersInformations[0].representants" v-bind:key="index">
                    <v-col cols="12" md="3">
                      <v-select
                        @click:prepend="removeDirector(director)"
                        prepend-icon="mdi-close-thick"
                        outlined
                        :items="['M', 'Mme']"
                        v-model="director.sexe"
                        label="Genre"
                        prepend-inner-icon="mdi-account"
                      >
                        <template v-slot:prepend>
                          <v-btn icon @click="removeDirector(director)" color="EoleError" class="mt-4">
                            <v-icon>mdi-close-thick</v-icon>
                          </v-btn>
                        </template></v-select
                      >
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field outlined v-model="director.nom" label="Nom" prepend-inner-icon="mdi-account">
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        outlined
                        v-model="director.prenom_usuel"
                        label="Prénom"
                        prepend-inner-icon="mdi-account"
                      ></v-text-field>
                    </v-col>

                    <v-col style="display: flex; justify-content: flex-end !important" cols="12" md="1">
                      <v-checkbox
                        :disabled="director.nom === '' || director.prenom_usuel === ''"
                        v-model="signatory"
                        :value="{
                          civility: director.sexe === 'M' ? 'M' : 'Mme',
                          name: director.nom + ' ' + director.prenom_usuel,
                        }"
                        :key="director.nom"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  {{ signatory }}
                  <v-data-table
                    v-model="signatory"
                    :headers="headersDirectors"
                    :items="pappersInformations[0].representants"
                    item-key="nom"
                    @item-selected="test"
                    hide-default-footer
                  >
                    <template v-slot:[`item.checkbox`]="{ item }">
                      <v-checkbox
                        :disabled="item.nom === '' || item.prenom_usuel === ''"
                        :color="getCheckboxColor(item)"
                        v-model="signatory"
                        :value="{
                          civility: item.sexe === 'M' ? 'M' : 'Mme',
                          name: item.nom + ' ' + item.prenom_usuel,
                        }"
                        :key="item.nom"
                      ></v-checkbox>
                    </template>
                    <template v-slot:[`item.sexe`]="{ item }">
                      <v-select
                        class="mt-5"
                        dense
                        :value="item.sexe"
                        :items="['M', 'Mme']"
                        prepend-inner-icon="mdi-account"
                      >
                      </v-select>
                    </template>

                    <template v-slot:[`item.nom`]="{ item }">
                      <v-text-field
                        class="mt-5"
                        dense
                        v-model="item.nom"
                        prepend-inner-icon="mdi-account"
                      ></v-text-field>
                    </template>
                    <template v-slot:[`item.prenom_usuel`]="{ item }">
                      <v-text-field
                        class="mt-5"
                        dense
                        v-model="item.prenom_usuel"
                        prepend-inner-icon="mdi-account"
                      ></v-text-field>
                    </template>
                  </v-data-table>
                  <v-row class="px-2">
                    <v-btn block text elevation="0" @click="addDirector">
                      <v-icon left>mdi-plus</v-icon> Ajouter un dirigeant
                    </v-btn>
                  </v-row>
                </v-card-text>
                <v-divider></v-divider>

                <v-card-actions>
                  <v-btn @click="lastStep(2)" elevation="0">
                    <v-icon left> mdi-chevron-left </v-icon>
                    <span>Précédent</span>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn @click="!dataPappers ? fetchPappers(siret) : nextStep(2)" elevation="0">
                    <span>Continuer</span>
                    <v-icon right> mdi-chevron-right </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>

          <v-stepper-items>
            <v-stepper-content :step="3" class="no-padding">
              <v-card-title class="EoleBlue" style="color: white !important; font-size: 1rem">
                <v-icon class="mr-2" color="white">mdi-sitemap</v-icon> Quel est le produit concerné ?
              </v-card-title>

              <v-card>
                <v-card-text>
                  <v-row class="text-center my-5">
                    <v-col cols="12" md="2" v-for="product in productsArray" v-bind:key="product.name">
                      <v-card
                        v-on:click="
                          productSelected = product
                          productName = product.name
                        "
                        flat
                        outlined
                        width="200"
                        height="200"
                        class="align-center"
                      >
                        <v-card-text class="text-center pa-5">
                          <i
                            :class="[
                              `fa-solid fa-${product.icon} fa-3x`,
                              productSelected.name === product.name ? 'EoleYellow--text' : 'EoleBlue--text',
                            ]"
                          ></i>
                        </v-card-text>
                        <v-card-text>
                          <p
                            :class="[
                              'mt-5',
                              productSelected.name === product.name ? 'EoleYellow--text' : 'EoleBlue--text',
                            ]"
                          >
                            {{ product.name.toUpperCase() }}
                          </p>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions class="no-padding mt-1">
                  <v-btn @click="lastStep(3)" elevation="0">
                    <v-icon left> mdi-chevron-left </v-icon>
                    <span>Précédent</span>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="
                      () => {
                        e1++
                        fetchGuaranteesByProduct(productSelected.name)
                      }
                    "
                    elevation="0"
                  >
                    <span>Continuer</span>
                    <v-icon right> mdi-chevron-right </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>

          <v-stepper-items>
            <v-stepper-content :step="4" class="no-padding">
              <v-stepper v-if="productName === 'Automobile'" v-model="autoStepper" class="no-padding">
                <v-stepper-items>
                  <v-stepper-content step="1" class="no-padding">
                    <v-card-title class="EoleBlue" style="color: white !important; font-size: 1rem">
                      <v-icon class="mr-2" color="white">mdi-magnify</v-icon> Découverte client
                    </v-card-title>
                    <v-card>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left"></th>
                              <th class="text-left"></th>
                              <th class="text-left"></th>
                              <th class="text-left"></th>
                              <th class="text-left"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Combien de kilomètres effectuez-vous par an ?</td>
                              <td></td>

                              <td>
                                <v-edit-dialog
                                  class="edit-dialog"
                                  :return-value.sync="customerDiscoveryVehicle.vehicleKilometersByYear"
                                >
                                  {{ customerDiscoveryVehicle.vehicleKilometersByYear }} km
                                  <template v-slot:input>
                                    <div class="edit-dialog">
                                      <v-text-field
                                        outlined
                                        class="my-5"
                                        suffix="km / an"
                                        dense
                                        v-model="customerDiscoveryVehicle.vehicleKilometersByYear"
                                        label="Edit"
                                        single-line
                                      ></v-text-field>
                                    </div>
                                  </template>
                                </v-edit-dialog>
                              </td>
                              <td></td>
                              <td>
                                <i
                                  v-if="customerDiscoveryVehicle.vehicleKilometersByYear == 0"
                                  class="EoleYellow--text fa-solid fa-triangle-exclamation fa-2x fa-beat"
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>Quel est le prix d'acquisition du véhicule ?</td>
                              <td></td>

                              <td>
                                <v-edit-dialog
                                  class="edit-dialog"
                                  :return-value.sync="customerDiscoveryVehicle.vehiclePrice"
                                >
                                  {{ customerDiscoveryVehicle.vehiclePrice }} €
                                  <template v-slot:input>
                                    <div class="edit-dialog">
                                      <v-text-field
                                        outlined
                                        class="my-5"
                                        append-icon="mdi-currency-eur"
                                        dense
                                        suffix="€"
                                        v-model="customerDiscoveryVehicle.vehiclePrice"
                                        label="Edit"
                                        single-line
                                      ></v-text-field>
                                    </div>
                                  </template>
                                </v-edit-dialog>
                              </td>
                              <td></td>
                              <td>
                                <i
                                  v-if="customerDiscoveryVehicle.vehiclePrice == 0"
                                  class="EoleYellow--text fa-solid fa-triangle-exclamation fa-2x fa-beat"
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>Quel est l'âge du véhicule ?</td>
                              <td></td>

                              <td>
                                <v-edit-dialog
                                  class="edit-dialog"
                                  :return-value.sync="customerDiscoveryVehicle.vehicleAge"
                                >
                                  {{ customerDiscoveryVehicle.vehicleAge }} an(s)
                                  <template v-slot:input>
                                    <div class="edit-dialog">
                                      <v-text-field
                                        outlined
                                        class="my-5"
                                        append-icon="mdi-currency-eur"
                                        dense
                                        suffix="ans"
                                        v-model="customerDiscoveryVehicle.vehicleAge"
                                        label="Edit"
                                        single-line
                                      ></v-text-field>
                                    </div>
                                  </template>
                                </v-edit-dialog>
                              </td>
                              <td></td>
                              <td>
                                <i
                                  v-if="customerDiscoveryVehicle.vehicleAge == 0"
                                  class="EoleYellow--text fa-solid fa-triangle-exclamation fa-2x fa-beat"
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <v-switch color="EoleBlue" v-model="customerDiscoveryVehicle.vehicleModelKnown" inset>
                                  <template v-slot:label>
                                    <span class="switchFont">Connaissez-vous la version du véhicule ?</span>
                                  </template>
                                </v-switch>
                              </td>

                              <td></td>
                              <td>
                                <v-btn
                                  small
                                  v-if="customerDiscoveryVehicle.vehicleModelKnown"
                                  color="EoleGreen"
                                  icon
                                  outlined
                                >
                                  <v-icon>mdi-check</v-icon>
                                </v-btn>

                                <v-btn small outlined v-else color="EoleError" text icon>
                                  <v-icon color="EoleError">mdi-close-thick</v-icon>
                                </v-btn>
                              </td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr v-if="customerDiscoveryVehicle.vehicleModelKnown">
                              <td>Modèle du véhicule :</td>
                              <td></td>

                              <td>
                                <v-edit-dialog
                                  class="edit-dialog"
                                  :return-value.sync="customerDiscoveryVehicle.vehicleModel"
                                >
                                  {{
                                    !!customerDiscoveryVehicle.vehicleModel
                                      ? customerDiscoveryVehicle.vehicleModel
                                      : "Merci de renseigner ce champ"
                                  }}
                                  <template v-slot:input>
                                    <div class="edit-dialog">
                                      <v-text-field
                                        outlined
                                        class="my-5"
                                        dense
                                        v-model="customerDiscoveryVehicle.vehicleModel"
                                        label="Modèle de la voiture"
                                        single-line
                                      ></v-text-field>
                                    </div>
                                  </template>
                                </v-edit-dialog>
                              </td>
                              <td></td>
                              <td>
                                <i
                                  v-if="customerDiscoveryVehicle.vehicleModel == ''"
                                  class="EoleYellow--text fa-solid fa-triangle-exclamation fa-2x fa-beat"
                                ></i>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <v-switch
                                  class="switchFont"
                                  color="EoleBlue"
                                  v-model="customerDiscoveryVehicle.vehicleCollectible"
                                  inset
                                >
                                  <template v-slot:label>
                                    <span class="switchFont">Est-ce un véhicule de collection ?</span>
                                  </template>
                                </v-switch>
                              </td>
                              <td></td>
                              <td>
                                <v-btn
                                  small
                                  v-if="customerDiscoveryVehicle.vehicleCollectible"
                                  color="EoleGreen"
                                  icon
                                  outlined
                                >
                                  <v-icon>mdi-check</v-icon>
                                </v-btn>

                                <v-btn small outlined v-else color="EoleError" text icon>
                                  <v-icon color="EoleError">mdi-close-thick</v-icon>
                                </v-btn>
                              </td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr v-if="customerDiscoveryVehicle.vehicleCollectible">
                              <td>
                                <v-switch
                                  class="switchFont"
                                  color="EoleBlue"
                                  v-model="customerDiscoveryVehicle.vehicleCollectibleExpert"
                                  inset
                                >
                                  <template v-slot:label>
                                    <span class="switchFont">Il y a-t-il une expertise préalable ?</span>
                                  </template>
                                </v-switch>
                              </td>
                              <td></td>
                              <td>
                                <v-btn
                                  small
                                  v-if="customerDiscoveryVehicle.vehicleCollectibleExpert"
                                  color="EoleGreen"
                                  icon
                                  outlined
                                >
                                  <v-icon>mdi-check</v-icon>
                                </v-btn>

                                <v-btn small outlined v-else color="EoleError" text icon>
                                  <v-icon color="EoleError">mdi-close-thick</v-icon>
                                </v-btn>
                              </td>
                              <td>
                            
                              </td>

                              <td></td>
                            </tr>

                            <tr
                              v-if="
                                customerDiscoveryVehicle.vehicleCollectibleExpert &&
                                customerDiscoveryVehicle.vehicleCollectible
                              "
                            >
                              <td>Valeur expertisée :</td>
                              <td></td>

                              <td>
                                <v-edit-dialog
                                  class="edit-dialog"
                                  :return-value.sync="customerDiscoveryVehicle.vehicleCollectibleExpertPrice"
                                >
                                  {{ customerDiscoveryVehicle.vehicleCollectibleExpertPrice }} €
                                  <template v-slot:input>
                                    <div class="edit-dialog">
                                      <v-text-field
                                        outlined
                                        class="my-5"
                                        append-icon="mdi-currency-eur"
                                        dense
                                        suffix="€"
                                        v-model="customerDiscoveryVehicle.vehicleCollectibleExpertPrice"
                                        label="Edit"
                                        single-line
                                      ></v-text-field>
                                    </div>
                                  </template>
                                </v-edit-dialog>
                              </td>
                              <td></td>
                              <td>
                                <i
                                  v-if="customerDiscoveryVehicle.vehicleCollectibleExpertPrice == 0"
                                  class="EoleYellow--text fa-solid fa-triangle-exclamation fa-2x fa-beat"
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <v-switch
                                  class="switchFont"
                                  color="EoleBlue"
                                  v-model="customerDiscoveryVehicle.vehicleValueKnown"
                                  inset
                                >
                                  <template v-slot:label>
                                    <span class="switchFont">La valeur du véhicule est-elle connu ?</span>
                                  </template>
                                </v-switch>
                              </td>
                              <td></td>
                              <td>
                                <v-btn
                                  small
                                  v-if="customerDiscoveryVehicle.vehicleValueKnown"
                                  color="EoleGreen"
                                  icon
                                  outlined
                                >
                                  <v-icon>mdi-check</v-icon>
                                </v-btn>

                                <v-btn small outlined v-else color="EoleError" text icon>
                                  <v-icon color="EoleError">mdi-close-thick</v-icon>
                                </v-btn>
                              </td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr v-if="customerDiscoveryVehicle.vehicleValueKnown">
                              <td>Valeur du véhicule :</td>
                              <td></td>

                              <td>
                                <v-edit-dialog
                                  class="edit-dialog"
                                  :return-value.sync="customerDiscoveryVehicle.vehicleValue"
                                >
                                  {{ customerDiscoveryVehicle.vehicleValue }} €
                                  <template v-slot:input>
                                    <div class="edit-dialog">
                                      <v-text-field
                                        outlined
                                        class="my-5"
                                        append-icon="mdi-currency-eur"
                                        dense
                                        suffix="€"
                                        v-model="customerDiscoveryVehicle.vehicleValue"
                                        label="Edit"
                                        single-line
                                      ></v-text-field>
                                    </div>
                                  </template>
                                </v-edit-dialog>
                              </td>
                              <td></td>
                              <td>
                                <i
                                  v-if="customerDiscoveryVehicle.vehicleValue == 0"
                                  class="EoleYellow--text fa-solid fa-triangle-exclamation fa-2x fa-beat"
                                ></i>
                              </td>
                            </tr>
                          </tbody>

                          <tbody></tbody>
                        </template>
                      </v-simple-table>
                    
                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="autoStepper++" elevation="0">
                          <span>Continuer</span>
                          <v-icon right> mdi-chevron-right </v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-stepper-content>

                  <v-stepper-content step="2" class="no-padding">
                    <v-card-title class="EoleBlue" style="color: white !important; font-size: 1rem">
                      <v-icon class="mr-2" color="white">mdi-magnify</v-icon> Découverte client
                    </v-card-title>
                    <v-card>
                      <v-simple-table>
                        <template v-slot:default>
                          <tbody>
                            <tr>
                              <td>
                                <v-switch color="EoleBlue" v-model="customerDiscoveryVehicle.vehicleSinister" inset>
                                  <template v-slot:label>
                                    <span class="switchFont"
                                      >Avez-vous eu des sinistres au cours des 36 derniers mois (y compris bris de
                                      glaces) ? ?</span
                                    >
                                  </template>
                                </v-switch>
                              </td>
                              <td></td>
                              <td>
                                <v-btn
                                  small
                                  v-if="customerDiscoveryVehicle.vehicleSinister"
                                  color="EoleGreen"
                                  icon
                                  outlined
                                >
                                  <v-icon>mdi-check</v-icon>
                                </v-btn>

                                <v-btn small outlined v-else color="EoleError" text icon>
                                  <v-icon color="EoleError">mdi-close-thick</v-icon>
                                </v-btn>
                              </td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>
                                <v-switch color="EoleBlue" v-model="customerDiscoveryVehicle.licenceJunior" inset>
                                  <template v-slot:label>
                                    <span class="switchFont">Détenez-vous votre permis depuis plus de 3 ans ?</span>
                                  </template></v-switch
                                >
                              </td>
                              <td></td>
                              <td>
                                <v-btn
                                  small
                                  v-if="customerDiscoveryVehicle.licenceJunior"
                                  color="EoleGreen"
                                  icon
                                  outlined
                                >
                                  <v-icon>mdi-check</v-icon>
                                </v-btn>

                                <v-btn small outlined v-else color="EoleError" text icon>
                                  <v-icon color="EoleError">mdi-close-thick</v-icon>
                                </v-btn>
                              </td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <h3 class="pa-5">Au cours des 5 dernières années :</h3>
                      <v-simple-table>
                        <template v-slot:default>
                          <tbody>
                            <tr>
                              <td>
                                <v-switch color="EoleBlue" v-model="customerDiscoveryVehicle.termination" inset>
                                  <template v-slot:label>
                                    <span class="switchFont"
                                      >Le conducteur a-t-il été résilié par un assureur quelle qu’en soit la cause
                                      ?</span
                                    >
                                  </template></v-switch
                                >
                              </td>

                              <td></td>
                              <td>
                                <v-btn
                                  small
                                  v-if="customerDiscoveryVehicle.termination"
                                  color="EoleGreen"
                                  icon
                                  outlined
                                >
                                  <v-icon>mdi-check</v-icon>
                                </v-btn>

                                <v-btn small outlined v-else color="EoleError" text icon>
                                  <v-icon color="EoleError">mdi-close-thick</v-icon>
                                </v-btn>
                              </td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>
                                <v-switch color="EoleBlue" v-model="customerDiscoveryVehicle.licenceCancel" inset>
                                  <template v-slot:label>
                                    <span class="switchFont"
                                      >Le conducteur a-t-il eu son permis suspendu ou annulé ?</span
                                    >
                                  </template>
                                </v-switch>
                              </td>
                              <td></td>
                              <td>
                                <v-btn
                                  small
                                  v-if="customerDiscoveryVehicle.licenceCancel"
                                  color="EoleGreen"
                                  icon
                                  outlined
                                >
                                  <v-icon>mdi-check</v-icon>
                                </v-btn>

                                <v-btn small outlined v-else color="EoleError" text icon>
                                  <v-icon color="EoleError">mdi-close-thick</v-icon>
                                </v-btn>
                              </td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr v-if="customerDiscoveryVehicle.licenceCancel">
                              <td>Temps de suspension :</td>
                              <td></td>

                              <td>
                                <v-edit-dialog
                                  class="edit-dialog"
                                  :return-value.sync="customerDiscoveryVehicle.licenceCancelTime"
                                >
                                  {{ customerDiscoveryVehicle.licenceCancelTime }} mois
                                  <template v-slot:input>
                                    <div class="edit-dialog">
                                      <v-text-field
                                        outlined
                                        class="my-5"
                                        dense
                                        suffix="mois"
                                        v-model="customerDiscoveryVehicle.licenceCancelTime"
                                        label="Edit"
                                        single-line
                                      ></v-text-field>
                                    </div>
                                  </template>
                                </v-edit-dialog>
                              </td>
                              <td></td>
                              <td>
                                <i
                                  v-if="customerDiscoveryVehicle.licenceCancelTime == 0"
                                  class="EoleYellow--text fa-solid fa-triangle-exclamation fa-2x fa-beat"
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <v-switch color="EoleBlue" v-model="customerDiscoveryVehicle.sentenced" inset>
                                  <template v-slot:label>
                                    <span class="switchFont"
                                      >Le conducteur a-t-il été condamné pour :<br />
                                      - délit de fuite suite à accident<br />
                                      - conduite sous l’emprise de l’alcool ou de stupéfiant<br />
                                      - excès de vitesse ?</span
                                    >
                                  </template>
                                </v-switch>
                              </td>
                              <td></td>

                              <td>
                                <v-btn small v-if="customerDiscoveryVehicle.sentenced" color="EoleGreen" icon outlined>
                                  <v-icon>mdi-check</v-icon>
                                </v-btn>

                                <v-btn small outlined v-else color="EoleError" text icon>
                                  <v-icon color="EoleError">mdi-close-thick</v-icon>
                                </v-btn>
                              </td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                          <tbody></tbody>
                        </template>
                      </v-simple-table>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-btn @click="autoStepper--" elevation="0">
                          <v-icon left> mdi-chevron-left </v-icon>

                          <span>Retour</span>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          @click="
                            () => {
                              analyzeVehiclePrice()
                              autoStepper++
                            }
                          "
                          elevation="0"
                        >
                          <span>Continuer</span>
                          <v-icon right> mdi-chevron-right </v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-stepper-content>

                  <v-stepper-content step="3" class="no-padding">
                    <v-card-title class="EoleBlue" style="color: white !important; font-size: 1rem">
                      <v-icon class="mr-2" color="white">mdi-magnify</v-icon> Découverte usage du véhicule client
                    </v-card-title>
                    <v-card>
                      <v-data-table
                        class="no-padding"
                        :headers="vehicleUsageHeaders"
                        :items="vehicleUsage"
                        hide-default-footer
                      >
                        <template v-slot:[`item.select`]="{ item }">
                          <v-switch
                            color="EoleBlue"
                            v-model="vehicleUsageSelected"
                            :value="item.guarantee"
                            :key="item.guarantee"
                            inset
                          ></v-switch>
                        </template>
                        <template v-slot:[`item.name`]="{ item }">
                          {{ item.guarantee }}
                        </template>

                        <template v-slot:[`item.chip`]="{ item }">
                          <v-btn small v-if="vehicleUsageSelected === item.guarantee" color="EoleGreen" icon outlined>
                            <v-icon>mdi-check</v-icon>
                          </v-btn>

                          <v-btn small outlined v-else color="EoleError" text icon>
                            <v-icon color="EoleError">mdi-close-thick</v-icon>
                          </v-btn>
                        </template>

                        <template v-slot:[`item.information`]="{ item }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon color="grey lighten-1"> mdi-information </v-icon>
                              </v-btn>
                            </template>
                            <span
                              >Qu'est-ce que {{ item.guarantee }} ? <br />
                              {{ item.comments }}
                            </span>
                          </v-tooltip>
                        </template>
                      </v-data-table>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-btn @click="autoStepper--" elevation="0">
                          <v-icon left> mdi-chevron-left </v-icon>

                          <span>Retour</span>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          :disabled="vehicleUsageSelected === '' || vehicleUsageSelected === null"
                          @click="autoStepper++"
                          elevation="0"
                        >
                          <span>Continuer</span>
                          <v-icon right> mdi-chevron-right </v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-stepper-content>

                  <v-stepper-content v-if="guaranteesFetch" step="4" class="no-padding">
                    <v-card-title class="EoleBlue" style="color: white !important; font-size: 1rem">
                      <v-icon class="mr-2" color="white">mdi-magnify</v-icon> Découverte lieu et stationnement du
                      véhicule
                    </v-card-title>
                    <v-card flat>
                      <v-data-table
                        class="no-padding"
                        :headers="vehicleParkingHeaders"
                        :items="vehicleParking"
                        hide-default-footer
                      >
                        <template v-slot:[`item.select`]="{ item }">
                          <v-switch
                            color="EoleBlue"
                            v-model="vehicleParkingSelected"
                            :value="item.guarantee"
                            :key="item.guarantee"
                            inset
                          ></v-switch>
                        </template>
                        <template v-slot:[`item.name`]="{ item }">
                          {{ item.guarantee }}
                        </template>

                        <template v-slot:[`item.chip`]="{ item }">
                          <v-btn small v-if="vehicleUsageSelected === item.guarantee" color="EoleGreen" icon outlined>
                            <v-icon>mdi-check</v-icon>
                          </v-btn>

                          <v-btn small outlined v-else color="EoleError" text icon>
                            <v-icon color="EoleError">mdi-close-thick</v-icon>
                          </v-btn>
                        </template>

                        <template v-slot:[`item.information`]="{ item }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon color="grey lighten-1"> mdi-information </v-icon>
                              </v-btn>
                            </template>
                            <span
                              >Qu'est-ce que {{ item.guarantee }} ? <br />
                              Ce mode de parking sert essentiellement à ...
                            </span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-card>
                    <v-card-title
                      v-if="!!vehicleParkingSelected"
                      class="EoleBlue"
                      style="color: white !important; font-size: 1rem"
                    >
                      <v-icon class="mr-2" color="white"></v-icon> Lieu de stationnement
                    </v-card-title>
                    <v-card-text v-if="!!vehicleParkingSelected" class="pa-5">
                      <div>
                        <p>
                          Merci d'indiquer si le véhicule sera stationné à l'adresse indiquée précedemment où si il sera
                          stationné à une adresse différente.
                        </p>
                        <v-switch
                          color="EoleBlue"
                          v-model="otherAddress"
                          :label="otherAddress ? `A l'adresse enregistrée précédemment` : `Modifier l'adresse`"
                          inset
                        ></v-switch>
                      </div>
                      <div v-show="otherAddress">
                        <v-row>
                          <v-col cols="12" md="3">
                            <v-text-field
                              @change="fetchCityByZip(otherZipClient)"
                              outlined
                              v-model="otherZipClient"
                              label="Code postal"
                              prepend-inner-icon="mdi-mailbox"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="3">
                            <v-select
                              :disabled="isDisabled"
                              no-data-text="Merci de rentrer un code postal valide pour trouver la ville"
                              outlined
                              :items="cityItems"
                              label="Ville"
                              v-model="otherCityClient"
                              prepend-inner-icon="mdi-town-hall"
                            ></v-select>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              :rules="addressRules"
                              outlined
                              v-model="otherStreetClient"
                              label="Adresse complète"
                              prepend-inner-icon="mdi-map-marker"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </div>
                    </v-card-text>
                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-btn @click="autoStepper--" elevation="0">
                        <v-icon left> mdi-chevron-left </v-icon>
                        <span>Retour</span>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        :disabled="vehicleUsageSelected === '' || vehicleUsageSelected === null"
                        @click="autoStepper++"
                        elevation="0"
                      >
                        <span>Continuer</span>
                        <v-icon right> mdi-chevron-right </v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-stepper-content>

                  <v-stepper-content v-if="guaranteesFetch" step="5" class="no-padding">
                    <v-card-title class="EoleBlue" style="color: white !important; font-size: 1rem">
                      <v-icon class="mr-2" color="white">mdi-magnify</v-icon>Découverte du financement du véhicule
                    </v-card-title>
                    <v-card flat>
                      <v-data-table
                        class="no-padding"
                        :headers="vehicleProcurementHeaders"
                        :items="vehicleProcurement"
                        hide-default-footer
                      >
                        <template v-slot:item="props">
                          <tr>
                            <td :colspan="1">
                              <v-switch
                                color="EoleBlue"
                                v-model="vehicleProcurementSelected"
                                :value="props.item.guarantee"
                                :key="props.item.guarantee"
                                inset
                              >
                              </v-switch>
                            </td>
                            <td>{{ props.item.guarantee }}</td>
                            <td></td>
                            <td>
                              <v-btn
                                v-if="vehicleProcurementSelected === props.item.guarantee"
                                color="EoleGreen"
                                icon
                                outlined
                              >
                                <v-icon>mdi-check</v-icon>
                              </v-btn>
                              <v-btn outlined v-else color="EoleError" text icon>
                                <v-icon color="EoleError">mdi-close-thick</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                          <tr
                            v-if="
                              vehicleProcurementSelected === 'Au comptant' && props.item.guarantee === 'Au comptant'
                            "
                          >
                            <td>
                              <v-switch color="EoleBlue" v-model="guaranteePurchase" inset> </v-switch>
                            </td>
                            <td>Financement bancaire en tout ou partie</td>
                            <td></td>
                            <td>
                              <v-btn v-if="guaranteePurchase" color="EoleGreen" icon outlined>
                                <v-icon>mdi-check</v-icon>
                              </v-btn>
                              <v-btn outlined v-else color="EoleError" text icon>
                                <v-icon color="EoleError">mdi-close-thick</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                          <tr
                            class="no-hover"
                            v-else-if="
                              (props.item.guarantee === 'Via loa/ldd' &&
                                vehicleProcurementSelected === 'Via loa/ldd') ||
                              (props.item.guarantee === 'Via crédit-bail' &&
                                vehicleProcurementSelected === 'Via crédit-bail')
                            "
                          >
                            <v-row class="mt-5 justify-center mx-auto">
                              <v-col align="center" align-self="center" cols="12" md="3"> </v-col>
                              <v-col cols="12" md="3" class="mt-3">Durée :</v-col>
                              <v-col align-self="center" cols="12" md="3">
                                <v-text-field
                                  v-model="customerDiscoveryVehicle.funding.duration"
                                  type="number"
                                  dense
                                  label="Durée"
                                  required
                                  outlined
                                  suffix="mois"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col align="center" align-self="center" cols="12" md="3"> </v-col>
                            </v-row>
                            <v-row class="justify-center mx-auto">
                              <v-col align="center" align-self="center" cols="12" md="3"> </v-col>

                              <v-col cols="12" md="3" class="mt-3">Organisme :</v-col>

                              <v-col align-self="center" cols="12" md="3">
                                <v-select
                                  v-model="customerDiscoveryVehicle.funding.bank"
                                  label="Organisme"
                                  :items="banks"
                                  item-text="name"
                                  dense
                                  outlined
                                >
                                  <template v-slot:prepend-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        <v-text-field
                                          prepend-inner-icon="mdi-magnify"
                                          v-model="searchItem"
                                          placeholder="Rechercher un organisme bancaire"
                                          @input="searchBanks"
                                          clearable
                                          clear-icon="mdi-close-circle"
                                        ></v-text-field>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </template>
                                </v-select>
                              </v-col>
                              <v-col align="center" align-self="center" cols="12" md="3"> </v-col>
                            </v-row>

                            <v-row class="justify-center mx-auto">
                              <v-col align="center" align-self="center" cols="12" md="3"> </v-col>

                              <v-col cols="12" md="3" class="mt-3">Montant mensuel :</v-col>
                              <v-col align-self="center" cols="12" md="3">
                                <v-text-field
                                  v-model="customerDiscoveryVehicle.funding.amount"
                                  type="number"
                                  dense
                                  label="Montant"
                                  required
                                  outlined
                                  suffix="€ /mois"
                                ></v-text-field>
                              </v-col>
                              <v-col align="center" align-self="center" cols="12" md="3"> </v-col>
                            </v-row>
                          </tr>
                        </template>

                        <template v-slot:[`item.select`]="{ item }">
                          <v-switch
                            color="EoleBlue"
                            v-model="vehicleParkingSelected"
                            :value="item.guarantee"
                            :key="item.guarantee"
                            inset
                          ></v-switch>
                        </template>
                        <template v-slot:[`item.name`]="{ item }">
                          {{ item.guarantee }}
                        </template>

                        <template v-slot:[`item.chip`]="{ item }">
                          <v-btn small v-if="vehicleUsageSelected === item.guarantee" color="EoleGreen" icon outlined>
                            <v-icon>mdi-check</v-icon>
                          </v-btn>

                          <v-btn small outlined v-else color="EoleError" text icon>
                            <v-icon color="EoleError">mdi-close-thick</v-icon>
                          </v-btn>
                        </template>

                        <template v-slot:[`item.information`]="{ item }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon color="grey lighten-1"> mdi-information </v-icon>
                              </v-btn>
                            </template>
                            <span
                              >Qu'est-ce que {{ item.guarantee }} ? <br />
                              Ce mode de parking sert essentiellement à ...
                            </span>
                          </v-tooltip>
                        </template>
                      </v-data-table>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-btn @click="autoStepper--" elevation="0">
                          <v-icon left> mdi-chevron-left </v-icon>
                          <span>Retour</span>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          :disabled="vehicleUsageSelected === '' || vehicleUsageSelected === null"
                          @click="autoStepper++"
                          elevation="0"
                        >
                          <span>Continuer</span>
                          <v-icon right> mdi-chevron-right </v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-stepper-content>

                  <v-stepper-content step="6" class="no-padding">
                    <v-card-title class="EoleBlue" style="color: white !important; font-size: 1rem">
                      <v-icon class="mr-2" color="white">mdi-magnify</v-icon> Découverte remorque
                    </v-card-title>
                    <v-card>
                      <v-card-text class="pa-5">
                        <v-row>
                          <v-col cols="12" md="5" class="mt-4"> Avez-vous une ou des remorque(s) ? </v-col>
                          <v-col cols="12" md="3">
                            <v-radio-group v-model="customerDiscoveryVehicle.trailerOwner" row>
                              <v-radio label="Oui" :value="true"></v-radio>
                              <v-radio label="Non" :value="false"></v-radio>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                        <v-row v-if="customerDiscoveryVehicle.trailerOwner">
                          <v-col cols="12" md="5" class="mt-4"> Êtes-vous amené à prêter votre remorque ? </v-col>
                          <v-col cols="12" md="3">
                            <v-radio-group v-model="customerDiscoveryVehicle.trailersLent" row>
                              <v-radio label="Oui" :value="true"></v-radio>
                              <v-radio label="Non" :value="false"></v-radio>
                            </v-radio-group>
                          </v-col>
                        </v-row>

                        <v-row v-if="customerDiscoveryVehicle.trailerOwner">
                          <v-col
                            cols="12"
                            md="4"
                            v-for="trailer in customerDiscoveryVehicle.trailers"
                            :key="trailer.id"
                          >
                            <v-row>
                              <v-card-title class="text-center justify-center">Remorque {{ trailer.id }}</v-card-title>
                              <v-col cols="12" md="12">
                                <v-card outlined>
                                  <v-card-title class="text-center justify-center align-center">
                                    <v-icon size="64px">mdi-truck-trailer</v-icon>
                                  </v-card-title>
                                  <v-card-text>
                                    <v-select
                                      label="Poids remorque"
                                      v-model="trailer.weight"
                                      outlined
                                      dense
                                      :items="['< 500 kg', '[500 - 750]kg', '< 750kg > 3500kg']"
                                    >
                                    </v-select>
                                  </v-card-text>
                                </v-card>
                              </v-col>

                              <v-col cols="12" md="12">
                                <v-card outlined>
                                  <v-card-title class="text-center justify-center align-center">
                                    <v-icon size="64px" left>mdi-truck-trailer</v-icon> +
                                    <v-icon size="64px" right>mdi-car-hatchback</v-icon>
                                  </v-card-title>
                                  <v-card-text>
                                    <v-select
                                      label="Somme PTAC remorque + véhicule"
                                      :disabled="trailer.weight === '' || trailer.weight === null"
                                      @change="analyzeTrailerLicence(trailer)"
                                      v-model="trailer.sumVehicleTrailer"
                                      outlined
                                      dense
                                      :items="['< 3500 kg', '[3500 - 4250]kg', '> 4250 kg']"
                                    >
                                    </v-select>
                                  </v-card-text>
                                </v-card>
                              </v-col>

                              <v-col cols="12" md="12">
                                <v-card :disabled="trailer.sumVehicleTrailer === ''" outlined>
                                  <v-card-title class="text-center justify-center align-center">
                                    <v-btn
                                      large
                                      fab
                                      icon
                                      :color="trailer.hasMerchandises ? 'EoleGreen' : 'EoleError'"
                                      @click="trailer.hasMerchandises = !trailer.hasMerchandises"
                                    >
                                      <v-icon size="64px" v-if="!trailer.hasMerchandises">
                                        mdi-package-variant-closed</v-icon
                                      >
                                      <v-icon size="64px" v-else>mdi-package-variant</v-icon>
                                    </v-btn>
                                  </v-card-title>
                                  <v-card-text class="text-center">Marchandise transportées </v-card-text>
                                  <div v-if="trailer.hasMerchandises">
                                    <v-card-text>
                                      <v-row>
                                        <v-col cols="12" md="9">
                                          <v-text-field
                                            v-model="merchand"
                                            dense
                                            label="Ajouter une marchandise"
                                            required
                                            outlined
                                          >
                                          </v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                          <v-btn
                                            :disabled="merchand === ''"
                                            fab
                                            icon
                                            @click="insertMerchandises(trailer, merchand)"
                                          >
                                            <v-icon>mdi-plus</v-icon>
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-text>
                                      <v-row>
                                        <v-col cols="12" md="6"> Contenu : </v-col>
                                        <v-col cols="12" md="6">
                                          <ul>
                                            <li v-for="merchandise in trailer.merchandises" :key="merchandise">
                                              {{ merchandise }}
                                            </li>
                                          </ul>
                                        </v-col>
                                      </v-row>
                                    </v-card-text>
                                  </div>
                                </v-card>
                              </v-col>

                              <v-col cols="12" md="12">
                                <v-card v-if="trailer.licence != ''" outlined>
                                  <v-card-title class="text-center justify-center align-center font-weight-bold">
                                    PERMIS REQUIS</v-card-title
                                  >
                                  <v-divider class="mx-10"></v-divider>
                                  <v-card-text v-if="trailer.licence != 'B'" class="text-center">
                                    <v-icon size="48px" color="EoleYellow">mdi-alert</v-icon>
                                  </v-card-text>
                                  <v-card-text v-else class="text-center">
                                    <v-icon size="48px" color="EoleGreen">mdi-check</v-icon>
                                  </v-card-text>
                                  <v-card-text class="text-h5 text-center font-weight-bold">
                                    {{ trailer.licence }}
                                  </v-card-text>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-row>
                              <v-card-title class="text-center justify-center">Ajouter une remorque </v-card-title>
                              <v-col cols="12" md="12">
                                <v-card
                                  width="100%"
                                  class="flex d-flex flex-column text-center justify-center"
                                  link
                                  outlined
                                  @click="addNewTrailer()"
                                >
                                  <v-card-text>
                                    <v-icon size="32" color="EoleBlue">mdi-plus-circle</v-icon>
                                  </v-card-text>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-btn @click="autoStepper--" elevation="0">
                          <v-icon left> mdi-chevron-left </v-icon>
                          <span>Retour</span>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          :disabled="vehicleUsageSelected === '' || vehicleUsageSelected === null"
                          @click="autoStepper++"
                          elevation="0"
                        >
                          <span>Continuer</span>
                          <v-icon right> mdi-chevron-right </v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-stepper-content>

                  <v-stepper-content step="7" class="no-padding">
                    <v-card outlined>
                      <v-card-title class="EoleBlue" style="color: white !important; font-size: 1rem">
                        <v-icon class="mr-2" color="white">mdi-magnify</v-icon> Découverte éléments d’équipements et
                        effets personnels
                      </v-card-title>
                      <v-card-text class="no-padding">
                        <v-row class="pa-5">
                          <v-col cols="12" md="6">
                            <v-card flat outlined>
                              <v-card-title>Nombre d'équipements :</v-card-title>
                              <v-card-subtitle class="text-h5"> {{ itemsEquipementsSelected.length }}</v-card-subtitle>
                            </v-card>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-card flat outlined>
                              <v-card-title>Valeur des équipements :</v-card-title>
                              <v-card-subtitle class="text-h5"> {{ equipmentTotal }}€ </v-card-subtitle>
                            </v-card>
                          </v-col>
                        </v-row>
                        <v-data-table
                          class="no-padding"
                          :headers="itemsEquipmentsHeader"
                          :items="itemsEquipements"
                          v-model="itemsEquipementsSelected"
                          item-key="name"
                          hide-default-footer
                          no-results-text="Nous n'avons trouvé aucun résultat correspondant à votre recherche"
                        >
                          <template v-slot:item="props">
                            <tr>
                              <td>
                                <v-switch v-model="itemsEquipementsSelected" :value="props.item" inset> </v-switch>
                              </td>
                              <td>
                                {{ props.item.name }}
                              </td>
                              <td>
                                <v-edit-dialog class="edit-dialog" :return-value.sync="props.item.amount">
                                  {{ props.item.amount }}€
                                  <template v-slot:input>
                                    <div class="edit-dialog">
                                      <v-text-field
                                        outlined
                                        class="my-5"
                                        :disabled="
                                          itemsEquipementsSelected && !itemsEquipementsSelected.includes(props.item)
                                        "
                                        append-icon="mdi-currency-eur"
                                        :error-messages="
                                          itemsEquipementsSelected && !itemsEquipementsSelected.includes(props.item)
                                            ? 'Pour éditer le prix de cet équipement merci de le sélectionner'
                                            : ''
                                        "
                                        dense
                                        v-model="props.item.amount"
                                        label="Edit"
                                        single-line
                                      ></v-text-field>
                                    </div>
                                  </template>
                                </v-edit-dialog>
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-btn @click="autoStepper--" elevation="0">
                          <v-icon left> mdi-chevron-left </v-icon>
                          <span>Retour</span>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          @click="
                            () => {
                              createAdvice()
                              autoStepper++
                            }
                          "
                          elevation="0"
                        >
                          <span>Continuer</span>
                          <v-icon right> mdi-chevron-right </v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-stepper-content>

                  <v-stepper-content step="8" class="no-padding">
                    <v-card-title class="EoleBlue" style="color: white !important; font-size: 1rem">
                      <v-icon class="mr-2" color="white">mdi-magnify</v-icon> Formalisation du devoir de conseil
                    </v-card-title>
                    <v-card>
                      <v-card-text>
                        <v-row class="pa-5">
                          <v-col cols="12" md="6">
                            <v-card elevation="0" outlined>
                              <v-card-title class="justify-center"> Vos souhaits </v-card-title>
                              <v-card-text class="no-padding">
                                <v-expansion-panels flat v-model="panelGuarantees">
                                  <v-expansion-panel>
                                    <v-expansion-panel-header> Garanties principales </v-expansion-panel-header>
                                    <v-expansion-panel-content class="no-padding">
                                      <v-data-table
                                        :headers="mainVehicleHeaders"
                                        :items="mainVehicle"
                                        hide-default-footer
                                        hide-default-header
                                      >
                                        <template v-slot:[`item.select`]="{ item }">
                                          <v-switch
                                            color="EoleBlue"
                                            v-model="customerGuarantees.wish.mainGuarantees"
                                            :value="item.name"
                                            inset
                                          ></v-switch>
                                        </template>
                                        <template v-slot:[`item.name`]="{ item }">
                                          {{ item.name }}
                                        </template>

                                        <template v-slot:[`item.chip`]="{ item }">
                                          <v-chip
                                            outlined
                                            v-if="customerGuarantees.wish.mainGuarantees.includes(item.name)"
                                            color="EoleGreen"
                                          >
                                            <v-icon color="EoleGreen" left>mdi-check</v-icon> OK
                                          </v-chip>
                                          <v-chip outlined v-else color="EoleError">
                                            <v-icon color="EoleError" left>mdi-close-thick</v-icon>
                                            KO
                                          </v-chip>
                                        </template>

                                        <template v-slot:[`item.information`]="{ item }">
                                          <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-btn icon v-bind="attrs" v-on="on">
                                                <v-icon color="grey lighten-1"> mdi-information </v-icon>
                                              </v-btn>
                                            </template>
                                            <span
                                              >Pourquoi {{ item.name }} est précochée ? <br />
                                              Prendre cette garantie est mieux pour le client.......................
                                            </span>
                                          </v-tooltip>
                                        </template>
                                      </v-data-table>
                                    </v-expansion-panel-content>
                                  </v-expansion-panel>
                                  <v-expansion-panel>
                                    <v-expansion-panel-header> Garanties complémentaires </v-expansion-panel-header>
                                    <v-expansion-panel-content class="no-padding">
                                      <v-data-table
                                        :headers="vehicleAdditionalHeaders"
                                        :items="vehicleAdditional"
                                        hide-default-footer
                                        hide-default-header
                                      >
                                        <template v-slot:[`item.select`]="{ item }">
                                          <v-switch
                                            color="EoleBlue"
                                            v-model="customerGuarantees.wish.additionalGuarantees"
                                            :value="item.name"
                                            inset
                                          ></v-switch>
                                        </template>
                                        <template v-slot:[`item.name`]="{ item }">
                                          {{ item.name }}
                                        </template>

                                        <template v-slot:[`item.chip`]="{ item }">
                                          <v-chip
                                            outlined
                                            v-if="customerGuarantees.wish.additionalGuarantees.includes(item.name)"
                                            color="EoleGreen"
                                          >
                                            <v-icon color="EoleGreen" left>mdi-check</v-icon> OK
                                          </v-chip>
                                          <v-chip outlined v-else color="EoleError">
                                            <v-icon color="EoleError" left>mdi-close-thick</v-icon>
                                            KO
                                          </v-chip>
                                        </template>

                                        <template v-slot:[`item.information`]="{ item }">
                                          <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-btn icon v-bind="attrs" v-on="on">
                                                <v-icon color="grey lighten-1"> mdi-information </v-icon>
                                              </v-btn>
                                            </template>
                                            <span
                                              >Pourquoi {{ item.name }} est précochée ? <br />
                                              Prendre cette garantie est mieux pour le client.......................
                                            </span>
                                          </v-tooltip>
                                        </template>
                                      </v-data-table>
                                    </v-expansion-panel-content>
                                  </v-expansion-panel>
                                  <v-expansion-panel :disabled="!customerDiscoveryVehicle.trailerOwner">
                                    <v-expansion-panel-header disable-icon-rotate>
                                      Garanties remorque
                                      <template v-slot:actions>
                                        <v-icon v-if="customerDiscoveryVehicle.trailerOwner" color="EoleGreen">
                                          mdi-check
                                        </v-icon>
                                        <v-icon v-else color="error"> mdi-close-thick </v-icon>
                                      </template>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content class="no-padding">
                                      <v-data-table
                                        :headers="mainTrailerHeaders"
                                        :items="mainTrailers"
                                        hide-default-footer
                                        no-data-text="Le client à déclarer ne pas avoir de remorque"
                                        no-results-text="Le client à déclarer ne pas avoir de remorque"
                                        hide-default-header
                                      >
                                        <template v-slot:[`item.select`]="{ item }">
                                          <v-switch
                                            color="EoleBlue"
                                            v-model="customerGuarantees.wish.trailersGuarantees"
                                            :value="item"
                                            inset
                                          ></v-switch>
                                        </template>
                                        <template v-slot:[`item.name`]="{ item }">
                                          {{ item }}
                                        </template>

                                        <template v-slot:[`item.chip`]="{ item }">
                                          <v-chip
                                            outlined
                                            v-if="customerGuarantees.wish.trailersGuarantees.includes(item)"
                                            color="EoleGreen"
                                          >
                                            <v-icon color="EoleGreen" left>mdi-check</v-icon> OK
                                          </v-chip>
                                          <v-chip outlined v-else color="EoleError">
                                            <v-icon color="EoleError" left>mdi-close-thick</v-icon>
                                            KO
                                          </v-chip>
                                        </template>

                                        <template v-slot:[`item.information`]="{ item }">
                                          <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-btn icon v-bind="attrs" v-on="on">
                                                <v-icon color="grey lighten-1"> mdi-information </v-icon>
                                              </v-btn>
                                            </template>
                                            <span
                                              >Pourquoi {{ item.name }} est précochée ? <br />
                                              {{ item.information }}
                                            </span>
                                          </v-tooltip>
                                        </template>
                                      </v-data-table>
                                      <div
                                        v-if="
                                          customerGuarantees.wish.trailersGuarantees.includes(
                                            'Marchandises transportées',
                                          )
                                        "
                                      >
                                        <v-text-field
                                          class="pa-5"
                                          type="number"
                                          v-model="customerGuarantees.wish.trailerMerchandiseAmount"
                                          outlined
                                          suffix="€"
                                          label="Montant de la marchandise à assurer"
                                          required
                                        >
                                        </v-text-field>
                                      </div>
                                    </v-expansion-panel-content>
                                  </v-expansion-panel>

                                  <v-expansion-panel>
                                    <v-expansion-panel-header> Niveau de franchise </v-expansion-panel-header>
                                    <v-expansion-panel-content class="no-padding">
                                      <v-data-table
                                        :headers="mainDeductibleHeaders"
                                        :items="deductible"
                                        hide-default-footer
                                        no-data-text="Le client à déclarer ne pas avoir de remorque"
                                        no-results-text="Le client à déclarer ne pas avoir de remorque"
                                        hide-default-header
                                      >
                                        <template v-slot:[`item.select`]="{ item }">
                                          <v-switch
                                            color="EoleBlue"
                                            v-model="customerGuarantees.wish.deductibleGuarantees"
                                            :value="item.name"
                                            inset
                                          ></v-switch>
                                        </template>
                                        <template v-slot:[`item.name`]="{ item }">
                                          {{ item.name }}
                                        </template>

                                        <template v-slot:[`item.chip`]="{ item }">
                                          <v-chip
                                            outlined
                                            v-if="customerGuarantees.wish.deductibleGuarantees.includes(item.name)"
                                            color="EoleGreen"
                                          >
                                            <v-icon color="EoleGreen" left>mdi-check</v-icon> OK
                                          </v-chip>
                                          <v-chip outlined v-else color="EoleError">
                                            <v-icon color="EoleError" left>mdi-close-thick</v-icon>
                                            KO
                                          </v-chip>
                                        </template>

                                        <template v-slot:[`item.information`]="{ item }">
                                          <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-btn icon v-bind="attrs" v-on="on">
                                                <v-icon color="grey lighten-1"> mdi-information </v-icon>
                                              </v-btn>
                                            </template>
                                            <span
                                              >Pourquoi {{ item.name }} est précochée ? <br />
                                              Prendre ce niveau de franchise est mieux pour le
                                              client.......................
                                            </span>
                                          </v-tooltip>
                                        </template>
                                      </v-data-table>
                                    </v-expansion-panel-content>
                                  </v-expansion-panel>
                                </v-expansion-panels>
                              </v-card-text>
                            </v-card>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-card elevation="0" outlined>
                              <v-card-title class="justify-center"> Notre conseil </v-card-title>
                              <v-card-text class="no-padding">
                                <v-expansion-panels id="adviceGuaranteesMain" flat v-model="panelGuarantees">
                                  <v-expansion-panel>
                                    <v-expansion-panel-header> Garanties principales </v-expansion-panel-header>
                                    <v-expansion-panel-content class="no-padding">
                                      <v-data-table
                                        :headers="mainVehicleHeaders"
                                        :items="mainVehicle"
                                        hide-default-footer
                                        hide-default-header
                                      >
                                        <template v-slot:[`item.select`]="{ item }">
                                          <v-switch
                                            color="EoleBlue"
                                            v-model="customerGuarantees.advice.mainGuarantees"
                                            :value="item.name"
                                            inset
                                          ></v-switch>
                                        </template>
                                        <template v-slot:[`item.name`]="{ item }">
                                          {{ item.name }}
                                        </template>

                                        <template v-slot:[`item.chip`]="{ item }">
                                          <v-chip
                                            outlined
                                            v-if="customerGuarantees.advice.mainGuarantees.includes(item.name)"
                                            color="EoleGreen"
                                          >
                                            <v-icon color="EoleGreen" left>mdi-check</v-icon> OK
                                          </v-chip>
                                          <v-chip outlined v-else color="EoleError">
                                            <v-icon color="EoleError" left>mdi-close-thick</v-icon>
                                            KO
                                          </v-chip>
                                        </template>

                                        <template v-slot:[`item.information`]="{ item }">
                                          <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-btn icon v-bind="attrs" v-on="on">
                                                <v-icon color="grey lighten-1"> mdi-information </v-icon>
                                              </v-btn>
                                            </template>
                                            <span
                                              >Pourquoi {{ item.name }} est précochée ? <br />
                                              Prendre cette garantie est mieux pour le client.......................
                                            </span>
                                          </v-tooltip>
                                        </template>
                                      </v-data-table>
                                    </v-expansion-panel-content>
                                  </v-expansion-panel>
                                  <v-expansion-panel>
                                    <v-expansion-panel-header> Garanties complémentaires </v-expansion-panel-header>
                                    <v-expansion-panel-content class="no-padding">
                                      <v-data-table
                                        :headers="vehicleAdditionalHeaders"
                                        :items="vehicleAdditional"
                                        hide-default-footer
                                        hide-default-header
                                      >
                                        <template v-slot:[`item.select`]="{ item }">
                                          <v-switch
                                            color="EoleBlue"
                                            v-model="customerGuarantees.advice.additionalGuarantees"
                                            :value="item.name"
                                            inset
                                          ></v-switch>
                                        </template>
                                        <template v-slot:[`item.name`]="{ item }">
                                          {{ item.name }}
                                        </template>

                                        <template v-slot:[`item.chip`]="{ item }">
                                          <v-chip
                                            outlined
                                            v-if="customerGuarantees.advice.additionalGuarantees.includes(item.name)"
                                            color="EoleGreen"
                                          >
                                            <v-icon color="EoleGreen" left>mdi-check</v-icon> OK
                                          </v-chip>
                                          <v-chip outlined v-else color="EoleError">
                                            <v-icon color="EoleError" left>mdi-close-thick</v-icon>
                                            KO
                                          </v-chip>
                                        </template>

                                        <template v-slot:[`item.information`]="{ item }">
                                          <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-btn icon v-bind="attrs" v-on="on">
                                                <v-icon color="grey lighten-1"> mdi-information </v-icon>
                                              </v-btn>
                                            </template>
                                            <span
                                              >Pourquoi {{ item.name }} est précochée ? <br />
                                              Prendre cette garantie est mieux pour le client.......................
                                            </span>
                                          </v-tooltip>
                                        </template>
                                      </v-data-table>
                                    </v-expansion-panel-content>
                                  </v-expansion-panel>
                                  <v-expansion-panel :disabled="!customerDiscoveryVehicle.trailerOwner">
                                    <v-expansion-panel-header disable-icon-rotate>
                                      Garanties remorque
                                      <template v-slot:actions>
                                        <v-icon v-if="customerDiscoveryVehicle.trailerOwner" color="EoleGreen">
                                          mdi-check
                                        </v-icon>
                                        <v-icon v-else color="error"> mdi-close-thick </v-icon>
                                      </template>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content class="no-padding">
                                      <v-data-table
                                        :headers="mainTrailerHeaders"
                                        :items="mainTrailers"
                                        hide-default-footer
                                        no-data-text="Le client à déclarer ne pas avoir de remorque"
                                        no-results-text="Le client à déclarer ne pas avoir de remorque"
                                        hide-default-header
                                      >
                                        <template v-slot:[`item.select`]="{ item }">
                                          <v-switch
                                            color="EoleBlue"
                                            v-model="customerGuarantees.advice.trailersGuarantees"
                                            :value="item"
                                            inset
                                          ></v-switch>
                                        </template>
                                        <template v-slot:[`item.name`]="{ item }">
                                          {{ item }}
                                        </template>

                                        <template v-slot:[`item.chip`]="{ item }">
                                          <v-chip
                                            outlined
                                            v-if="customerGuarantees.advice.trailersGuarantees.includes(item)"
                                            color="EoleGreen"
                                          >
                                            <v-icon color="EoleGreen" left>mdi-check</v-icon> OK
                                          </v-chip>
                                          <v-chip outlined v-else color="EoleError">
                                            <v-icon color="EoleError" left>mdi-close-thick</v-icon>
                                            KO
                                          </v-chip>
                                        </template>

                                        <template v-slot:[`item.information`]="{ item }">
                                          <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-btn icon v-bind="attrs" v-on="on">
                                                <v-icon color="grey lighten-1"> mdi-information </v-icon>
                                              </v-btn>
                                            </template>
                                            <span
                                              >Pourquoi {{ item.name }} est précochée ? <br />
                                              Prendre cette garantie est mieux pour le client.......................
                                            </span>
                                          </v-tooltip>
                                        </template>
                                      </v-data-table>
                                      <div
                                        v-if="
                                          customerGuarantees.advice.trailersGuarantees.includes(
                                            'Marchandises transportées',
                                          )
                                        "
                                      >
                                        <v-text-field
                                          class="pa-5"
                                          type="number"
                                          v-model="customerGuarantees.advice.trailerMerchandiseAmount"
                                          outlined
                                          suffix="€"
                                          label="Montant de la marchandise à assurer"
                                          required
                                        >
                                        </v-text-field>
                                      </div>
                                    </v-expansion-panel-content>
                                  </v-expansion-panel>

                                  <v-expansion-panel>
                                    <v-expansion-panel-header> Niveau de franchise </v-expansion-panel-header>
                                    <v-expansion-panel-content class="no-padding">
                                      <v-data-table
                                        :headers="mainDeductibleHeaders"
                                        :items="deductible"
                                        hide-default-footer
                                        no-data-text="Le client à déclarer ne pas avoir de remorque"
                                        no-results-text="Le client à déclarer ne pas avoir de remorque"
                                        hide-default-header
                                      >
                                        <template v-slot:[`item.select`]="{ item }">
                                          <v-switch
                                            color="EoleBlue"
                                            v-model="customerGuarantees.advice.deductibleGuarantees"
                                            :value="item.name"
                                            inset
                                          ></v-switch>
                                        </template>
                                        <template v-slot:[`item.name`]="{ item }">
                                          {{ item.name }}
                                        </template>

                                        <template v-slot:[`item.chip`]="{ item }">
                                          <v-chip
                                            outlined
                                            v-if="customerGuarantees.advice.deductibleGuarantees.includes(item.name)"
                                            color="EoleGreen"
                                          >
                                            <v-icon color="EoleGreen" left>mdi-check</v-icon> OK
                                          </v-chip>
                                          <v-chip outlined v-else color="EoleError">
                                            <v-icon color="EoleError" left>mdi-close-thick</v-icon>
                                            KO
                                          </v-chip>
                                        </template>

                                        <template v-slot:[`item.information`]="{ item }">
                                          <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-btn icon v-bind="attrs" v-on="on">
                                                <v-icon color="grey lighten-1"> mdi-information </v-icon>
                                              </v-btn>
                                            </template>
                                            <span
                                              >Pourquoi {{ item.name }} est précochée ? <br />
                                              Prendre ce niveau de franchise est mieux pour le
                                              client.......................
                                            </span>
                                          </v-tooltip>
                                        </template>
                                      </v-data-table>
                                    </v-expansion-panel-content>
                                  </v-expansion-panel>
                                </v-expansion-panels>

                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-btn @click="autoStepper--" elevation="0">
                          <v-icon left> mdi-chevron-left </v-icon>
                          <span>Retour</span>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          @click="
                            () => {
                              e1++
                              pdfMake()
                              saveDataAsDraft(currentDraftId, true, false, [
                                {
                                  log: 'Lettre de devoir de conseil générée',
                                  dateTime: new Date().toLocaleString(),
                                  type: 'finish',
                                },
                                {
                                  log: 'En attente des devis',
                                  dateTime: new Date().toLocaleString(),
                                  type: 'wait',
                                },
                              ])
                            }
                          "
                          elevation="0"
                        >
                          <span>Terminer mon conseil</span>
                          <v-icon right> mdi-chevron-right </v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
              <v-card v-else-if="productName === 'Emprunteur'" elevation="0" outlined>
                <v-stepper v-model="borrowerStepper">
                  <v-stepper-header style="height: auto !important">
                    <v-stepper-step :complete="borrowerStepper > 1" step="1" editable edit-icon="mdi-check">
                      Objet du financement
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="borrowerStepper > 2" step="2" editable edit-icon="mdi-check">
                      Prêt(s)</v-stepper-step
                    >

                    <v-divider></v-divider>

                    <v-stepper-step :complete="borrowerStepper > 3" step="3" editable edit-icon="mdi-check">
                      Assuré(s)
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="borrowerStepper > 4" step="4" editable edit-icon="mdi-check">
                      Paramètrage des prêts
                    </v-stepper-step>
                  </v-stepper-header>

                  <v-stepper-items>
                    <v-stepper-content step="1">
                      <v-card class="mb-12" color="grey lighten-1">
                        <v-card class="" color="grey lighten-1">
                          <v-card class="mb-5" outlined elevation="0">
                            <v-card-title style="background-color: #e1e5e9">Objet du financement </v-card-title>
                            <v-card-text class="pa-5">
                              <v-row class="justify-center">
                                <v-col cols="12" md="1"></v-col>
                                <v-col cols="12" md="6">
                                  <h3>Nature</h3>
                                </v-col>
                                <v-col align="center" cols="12" md="4">
                                  <h3>Information</h3>
                                </v-col>
                              </v-row>
                              <div>
                                <v-row class="my-n5 justify-center" v-for="v in borrowerReasonPersonnal" :key="v">
                                  <v-col cols="12" md="12">
                                    <v-divider></v-divider>
                                  </v-col>
                                  <v-col cols="12" md="1">
                                    <v-switch
                                      color="EoleBlue"
                                      v-model="borrowerSelected"
                                      :value="v"
                                      :key="v"
                                      inset
                                    ></v-switch>
                                  </v-col>
                                  <v-col align-self="center" cols="12" md="6">{{ v }}</v-col>
                                  <v-col align="center" align-self="center" cols="12" md="4">
                                    <v-btn v-if="borrowerSelected === v" color="EoleGreen" icon outlined>
                                      <v-icon>mdi-check</v-icon>
                                    </v-btn>

                                    <v-btn outlined v-else color="EoleError" text icon>
                                      <v-icon color="EoleError">mdi-close-thick</v-icon>
                                    </v-btn>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-card>
                      </v-card>
                      <v-row>
                        <v-col cols="12" md="6"></v-col>
                        <v-col class="text-right" cols="12" md="6">
                          <v-btn
                            :disabled="borrowerSelected === '' || borrowerSelected === null"
                            color="primary"
                            @click="borrowerStepper = 2"
                          >
                            Continuer
                            <v-icon right>mdi-chevron-right</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>

                    <v-stepper-content class="no-padding-top" step="2">
                      <v-card elevation="0">
                        <v-card-text class="px-5">
                          <v-row>
                            <v-col
                              class="d-flex flex-column"
                              align-items="center"
                              cols="12"
                              md="6"
                              v-for="loanPersonnal in loan"
                              v-bind:key="loanPersonnal.id"
                            >
                              <v-card class="flex d-flex flex-column" elevation="0" outlined>
                                <v-card-title class="EoleBlueLighten">Prêt N°{{ loanPersonnal.numLoan }} </v-card-title>
                                <v-row class="text-left pa-5">
                                  <v-col cols="12" md="12">
                                    <v-icon left>mdi-bank</v-icon> Type de prêt :
                                    {{ loanPersonnal.loanType }}
                                  </v-col>

                                  <v-col cols="12" md="12">
                                    <v-icon left>mdi-currency-eur</v-icon> Montant et /ou restant dû :
                                    {{ loanPersonnal.loanAmount }}€
                                  </v-col>

                                  <v-col cols="12" md="12">
                                    <v-icon left>mdi-chart-line</v-icon> Taux du prêt : {{ loanPersonnal.loanRate }}%
                                  </v-col>

                                  <v-col cols="12" md="12">
                                    <v-icon left>mdi-update</v-icon> Différé :
                                    {{ loanPersonnal.loanDiffered }}
                                  </v-col>

                                  <v-col v-if="loanPersonnal.loanDiffered === 'Oui'" cols="12" md="12">
                                    <v-icon left>mdi-swap-horizontal</v-icon> Type de différé :
                                    {{ loanPersonnal.loanDifferedSelected }}
                                  </v-col>

                                  <v-col v-if="loanPersonnal.loanDiffered === 'Oui'" cols="12" md="12">
                                    <v-icon left>mdi-calendar-month</v-icon> Durée du différé :
                                    {{ loanPersonnal.loanDelayDiffered }} mois
                                  </v-col>

                                  <v-col cols="12" md="12">
                                    <v-icon left>mdi-calendar-clock</v-icon> Périodicité des échéances :
                                    {{ loanPersonnal.loanFrequencySelected }}
                                  </v-col>
                                </v-row>
                              </v-card>
                            </v-col>
                            <v-col class="d-flex flex-column" cols="12" md="6">
                              <v-dialog v-model="borrowerLoanDialog" max-width="800px">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-card class="flex d-flex flex-column" link v-bind="attrs" v-on="on" outlined>
                                    <v-row class="justify-center text-center">
                                      <v-col align-self="center">
                                        <v-card-text class="pa-5 align-center">
                                          <v-icon color="EoleBlue" size="64">mdi-plus-circle </v-icon>
                                          <h2>Ajouter prêt N° {{ loan.length + 1 }}</h2>
                                        </v-card-text>
                                      </v-col>
                                    </v-row>
                                  </v-card>
                                </template>

                                <v-card>
                                  <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                  </v-card-title>

                                  <v-card-text>
                                    <v-container>
                                      <v-row>
                                        <v-col cols="12" md="6">
                                          <v-text-field
                                            v-model="editedItem.loanAmount"
                                            append-icon="mdi-currency-eur"
                                            outlined
                                            :label="`Montant et /ou restant dû`"
                                          ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6"></v-col>
                                        <v-col cols="12" md="6" v-if="editedItem.loanAmount > 0">
                                          <v-select
                                            v-model="editedItem.loanType"
                                            prepend-inner-icon="mdi-bank"
                                            label="Type de prêt"
                                            outlined
                                            :items="loanType"
                                          >
                                          </v-select>
                                        </v-col>
                                        <v-col cols="12" md="6"></v-col>

                                        <v-col cols="12" md="6" v-if="editedItem.loanType != ''">
                                          <v-select
                                            outlined
                                            label="Type de taux"
                                            :items="['Fixe', 'Variable']"
                                            v-model="editedItem.loanRateType"
                                          ></v-select>
                                        </v-col>

                                        <v-col cols="12" md="3" v-if="editedItem.loanRateType != ''">
                                          <v-text-field
                                            suffix="%"
                                            v-model="editedItem.loanRate"
                                            outlined
                                            :label="`Taux du prêt`"
                                          ></v-text-field>
                                        </v-col>

                                        <v-col cols="12" md="12" v-if="editedItem.loanRate != null">
                                          <v-icon left>mdi-update</v-icon> Différé :<v-radio-group
                                            v-model="editedItem.loanDiffered"
                                          >
                                            <v-radio label="Oui" value="Oui"></v-radio>
                                            <v-radio label="Non" value="Non"></v-radio>
                                          </v-radio-group>
                                        </v-col>
                                        <v-col v-if="editedItem.loanDiffered === 'Oui'" cols="12" md="6">
                                          <v-select
                                            outlined
                                            label="Fréquence"
                                            :items="['Partielle', 'Total']"
                                            v-model="editedItem.loanDifferedSelected"
                                          ></v-select>
                                        </v-col>
                                        <v-col v-if="editedItem.loanDiffered === 'Oui'" cols="12" md="3">
                                          <v-text-field
                                            suffix="/mois"
                                            v-model="editedItem.loanDelayDiffered"
                                            outlined
                                            :label="`Durée du différé`"
                                          ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="editedItem.loanDiffered != ''">
                                          <v-select
                                            v-model="editedItem.loanFrequencySelected"
                                            prepend-inner-icon="mdi-calendar-clock"
                                            label="Périodicité des échéances"
                                            outlined
                                            :items="loanFrequency"
                                          >
                                          </v-select>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </v-card-text>

                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="warning" text @click="close">Annuler</v-btn>
                                    <v-btn class="white--text" color="EoleBlue" @click="save"> Ajouter le prêt</v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                      <v-divider class="my-5"></v-divider>
                      <v-row>
                        <v-col class="text-left" cols="12" md="6">
                          <v-btn text @click="borrowerStepper--"> Retour </v-btn>
                        </v-col>
                        <v-col class="text-right" cols="12" md="6">
                          <v-btn color="primary" @click="borrowerStepper++">
                            Continuer<v-icon right>mdi-chevron-right</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>

                    <v-stepper-content class="no-padding-top" step="3">
                      <v-card elevation="0">
                        <v-card-text class="px-5">
                          <v-row>
                            <v-col
                              class="d-flex flex-column"
                              cols="12"
                              md="6"
                              v-for="insuredPersonnal in insured"
                              v-bind:key="insuredPersonnal.id"
                            >
                              <v-card class="flex d-flex flex-column" elevation="0" outlined>
                                <v-card-title class="EoleBlueLighten"
                                  >Assuré N°{{ insuredPersonnal.num }} : {{ insuredPersonnal.lastname }}
                                  {{ insuredPersonnal.firstname }}
                                </v-card-title>
                                <v-row class="text-left pa-5">
                                  <v-col cols="12" md="12">
                                    <v-icon left>{{
                                      insuredPersonnal.sex === "F" ? "mdi-gender-female" : "mdi-gender-male"
                                    }}</v-icon
                                    >Genre :
                                    {{ insuredPersonnal.sex === "F" ? "Femme" : "Homme" }}
                                  </v-col>

                                  <v-col cols="12" md="12">
                                    <v-icon left>mdi-cake</v-icon>Date de naissance :
                                    {{ insuredPersonnal.birthdate }}
                                  </v-col>
                                </v-row>

                                <v-card-title class="EoleBlueLighten">Informations professionnelles </v-card-title>
                                <v-row class="pa-5">
                                  <v-col cols="12" md="12">
                                    <v-icon left>mdi-account-hard-hat</v-icon> Profession :
                                    {{ insuredPersonnal.occupation.name }}
                                  </v-col>
                                  <v-col cols="12" md="12">
                                    <v-icon left>mdi-alert</v-icon> Profession à risque :
                                    <v-icon v-if="insuredPersonnal.occupation.risky" color="EoleGreen"
                                      >mdi-check</v-icon
                                    >
                                    <v-icon v-else color="EoleError">mdi-close-thick</v-icon>
                                  </v-col>
                                  <v-col cols="12" md="12">
                                    <v-icon left>mdi-hammer</v-icon> Travail manuel :
                                    <v-icon v-if="insuredPersonnal.occupation.manual" color="EoleGreen"
                                      >mdi-check</v-icon
                                    >
                                    <v-icon v-else color="EoleError">mdi-close-thick</v-icon>
                                  </v-col>
                                  <v-col cols="12" md="12">
                                    <v-icon left>mdi-ladder</v-icon> Travail en hauteur :
                                    <v-icon v-if="insuredPersonnal.occupation.high" color="EoleGreen">mdi-check</v-icon>
                                    <v-icon v-else color="EoleError">mdi-close-thick</v-icon>
                                  </v-col>
                                  <v-col cols="12" md="12">
                                    <v-icon left>mdi-archive-alert</v-icon> Port de charges lourdes :
                                    <v-icon v-if="insuredPersonnal.occupation.heaveLoad" color="EoleGreen"
                                      >mdi-check</v-icon
                                    >
                                    <v-icon v-else color="EoleError">mdi-close-thick</v-icon>
                                  </v-col>
                                  <v-col cols="12" md="12">
                                    <v-icon left>mdi-car-hatchback</v-icon> Déplacements professionnels :
                                    {{ insuredPersonnal.occupation.trips }}
                                  </v-col>
                                  <v-col cols="12" md="12">
                                    <v-icon left>mdi-airplane</v-icon> Déplacements à l'étranger :
                                    {{ insuredPersonnal.occupation.tripsCountry }}
                                  </v-col>
                                </v-row>
                                <v-card-title class="EoleBlueLighten">Informations complémentaires </v-card-title>
                                <v-row class="pa-5">
                                  <v-col cols="12" md="12">
                                    <v-icon left>mdi-smoking</v-icon> Fumeur :
                                    <v-icon v-if="insuredPersonnal.occupation.smoking" color="EoleGreen"
                                      >mdi-check</v-icon
                                    >
                                    <v-icon v-else color="EoleError">mdi-close-thick</v-icon>
                                  </v-col>
                                  <v-col cols="12" md="12">
                                    <v-icon left>mdi-bike</v-icon> Pratique de sports spécifique :
                                    {{ insuredPersonnal.occupation.specificSportName }}
                                    <v-icon v-if="insuredPersonnal.occupation.specificSport" color="EoleGreen"
                                      >mdi-check</v-icon
                                    >
                                    <v-icon v-else color="EoleError">mdi-close-thick</v-icon>
                                  </v-col>

                                  <v-col cols="12" md="12">
                                    <v-icon left>mdi-head-cog</v-icon> Rachat dos/Psy :
                                    <v-icon v-if="insuredPersonnal.occupation.psySpineRepurchase" color="EoleGreen"
                                      >mdi-check</v-icon
                                    >
                                    <v-icon v-else color="EoleError">mdi-close-thick</v-icon>
                                  </v-col>
                                  <v-col cols="12" md="12">
                                    <v-icon left>mdi-alert</v-icon> Rachat sport à risques :
                                    <v-icon v-if="insuredPersonnal.occupation.riskySportRepurchase" color="EoleGreen"
                                      >mdi-check</v-icon
                                    >
                                    <v-icon v-else color="EoleError">mdi-close-thick</v-icon>
                                  </v-col>
                                </v-row>
                              </v-card>
                            </v-col>
                            <v-col class="d-flex flex-column" cols="12" md="6">
                              <v-dialog v-model="borrowerLoanInsuredDialog" max-width="1100px">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-card class="flex d-flex flex-column" link v-bind="attrs" v-on="on" outlined>
                                    <v-row class="justify-center text-center">
                                      <v-col align-self="center">
                                        <v-card-text class="pa-5">
                                          <v-icon color="EoleBlue" size="64">mdi-plus-circle </v-icon>
                                          <h2>Ajouter assuré N° {{ insured.length + 1 }}</h2>
                                        </v-card-text>
                                      </v-col>
                                    </v-row>
                                  </v-card>
                                </template>

                                <v-card>
                                  <v-card-title class="EoleBlueLighten">
                                    <span class="text-h5"
                                      >{{ formTitleLoanInsured }} - Assuré N°{{ editedLoanInsuredItem.num }}</span
                                    >
                                  </v-card-title>
                                  <v-stepper v-model="addInsureStepper">
                                    <v-stepper-header>
                                      <v-stepper-step
                                        :key="`1-step`"
                                        :complete="addInsureStepper > 1"
                                        :step="1"
                                        editable
                                      >
                                        Identité de l'assuré
                                        <small v-if="addInsureStepper === 1">
                                          <v-icon color="EoleError">mdi-map-marker</v-icon> Vous êtes ici
                                        </small>
                                      </v-stepper-step>

                                      <v-divider v-if="1 !== addInsureStepsCount" :key="1"> </v-divider>
                                      <v-stepper-step
                                        :key="`2-step`"
                                        :complete="addInsureStepper > 2"
                                        :step="2"
                                        editable
                                      >
                                        Informations professionnelles
                                        <small v-if="addInsureStepper === 2">
                                          <v-icon color="EoleError">mdi-map-marker</v-icon> Vous êtes ici
                                        </small>
                                      </v-stepper-step>

                                      <v-divider v-if="2 !== addInsureStepsCount" :key="2"> </v-divider>

                                      <v-stepper-step
                                        :key="`3-step`"
                                        :complete="addInsureStepper > 3"
                                        :step="3"
                                        editable
                                      >
                                        Informations complémentaires
                                        <small v-if="addInsureStepper === 3">
                                          <v-icon color="EoleError">mdi-map-marker</v-icon> Vous êtes ici
                                        </small>
                                      </v-stepper-step>
                                    </v-stepper-header>

                                    <v-stepper-items>
                                      <v-stepper-content :key="`1-content`" :step="1">
                                        <v-row class="pa-5">
                                          <v-col cols="12" md="6">
                                            <v-text-field
                                              prepend-inner-icon="mdi-cursor-text"
                                              outlined
                                              :label="`Nom assuré N°${editedLoanInsuredItem.num}`"
                                              v-model="editedLoanInsuredItem.lastname"
                                            >
                                            </v-text-field>
                                          </v-col>
                                          <v-col cols="12" md="6">
                                            <v-text-field
                                              prepend-inner-icon="mdi-cursor-text"
                                              outlined
                                              :label="`Prénom assuré N°${editedLoanInsuredItem.num}`"
                                              v-model="editedLoanInsuredItem.firstname"
                                            >
                                            </v-text-field>
                                          </v-col>
                                          <v-col cols="12" md="6">
                                            <v-menu
                                              v-model="fromDateMenu"
                                              :close-on-content-click="false"
                                              :nudge-right="40"
                                              lazy
                                              transition="scale-transition"
                                              offset-y
                                              full-width
                                              max-width="290px"
                                              min-width="290px"
                                            >
                                              <template v-slot:activator="{ on }">
                                                <v-text-field
                                                  label="Date de naissance"
                                                  readonly
                                                  outlined
                                                  :value="fromDateDisp"
                                                  v-on="on"
                                                >
                                                </v-text-field>
                                              </template>
                                              <v-date-picker
                                                locale="fr-FR"
                                                :min="minDate"
                                                :max="maxDate"
                                                v-model="fromDateVal"
                                                no-title
                                                @input="fromDateMenu = false"
                                              ></v-date-picker>
                                            </v-menu>
                                          </v-col>
                                          <v-col cols="12" md="6">
                                            <v-select
                                              outlined
                                              label="Sexe"
                                              v-model="editedLoanInsuredItem.sex"
                                              :items="loanInsuredGender"
                                              item-text="name"
                                              item-value="value"
                                            >
                                            </v-select>
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col cols="12" md="6" class="text-left"> </v-col>
                                          <v-col cols="12" md="6" class="text-right">
                                            <v-btn color="primary" @click="addInsureStepper++">
                                              Continuer
                                              <v-icon right>mdi-chevron-right</v-icon>
                                            </v-btn>
                                          </v-col>
                                        </v-row>
                                      </v-stepper-content>

                                      <v-stepper-content class="padding-insured" :key="`2-content`" :step="2">
                                        <v-row class="mb-2">
                                          <v-col class="d-flex" cols="12" md="6">
                                            <v-card class="flex d-flex flex-column" elevation="0" outlined>
                                              <v-card-title class="EoleBlueLighten"
                                                >Concernant la profession de l'assuré</v-card-title
                                              >

                                              <div>
                                                <v-row class="pt-5 pl-5 px-5 mb-n16">
                                                  <v-col cols="12" md="11">
                                                    <v-text-field
                                                      prepend-inner-icon="mdi-cursor-text"
                                                      outlined
                                                      :label="`Profession de l'assuré N°${editedLoanInsuredItem.num}`"
                                                      v-model="editedLoanInsuredItem.occupation.name"
                                                    >
                                                    </v-text-field>
                                                  </v-col>
                                                </v-row>
                                                <v-row class="justify-center px-5 mb-n10">
                                                  <v-col cols="12" md="2">
                                                    <v-switch
                                                      color="EoleBlue"
                                                      v-model="editedLoanInsuredItem.occupation.risky"
                                                      inset
                                                    ></v-switch>
                                                  </v-col>
                                                  <v-col align-self="center" cols="12" md="6">
                                                    Profession à risque</v-col
                                                  >
                                                  <v-col align="center" align-self="center" cols="12" md="4">
                                                    <v-btn
                                                      v-if="editedLoanInsuredItem.occupation.risky"
                                                      color="EoleGreen"
                                                      icon
                                                      outlined
                                                    >
                                                      <v-icon>mdi-check</v-icon>
                                                    </v-btn>

                                                    <v-btn outlined v-else color="EoleError" text icon>
                                                      <v-icon color="EoleError">mdi-close-thick </v-icon>
                                                    </v-btn>
                                                  </v-col>
                                                </v-row>

                                                <v-row class="justify-center px-5 mb-n10">
                                                  <v-col cols="12" md="2">
                                                    <v-switch
                                                      color="EoleBlue"
                                                      v-model="editedLoanInsuredItem.occupation.manual"
                                                      inset
                                                    ></v-switch>
                                                  </v-col>
                                                  <v-col align-self="center" cols="12" md="6"> Travail manuel</v-col>
                                                  <v-col align="center" align-self="center" cols="12" md="4">
                                                    <v-btn
                                                      v-if="editedLoanInsuredItem.occupation.manual"
                                                      color="EoleGreen"
                                                      icon
                                                      outlined
                                                    >
                                                      <v-icon>mdi-check</v-icon>
                                                    </v-btn>

                                                    <v-btn outlined v-else color="EoleError" text icon>
                                                      <v-icon color="EoleError">mdi-close-thick </v-icon>
                                                    </v-btn>
                                                  </v-col>
                                                </v-row>

                                                <v-row class="justify-center px-5 mb-n10">
                                                  <v-col cols="12" md="2">
                                                    <v-switch
                                                      color="EoleBlue"
                                                      v-model="editedLoanInsuredItem.occupation.high"
                                                      inset
                                                    ></v-switch>
                                                  </v-col>
                                                  <v-col align-self="center" cols="12" md="6">
                                                    Travail en hauteur</v-col
                                                  >
                                                  <v-col align="center" align-self="center" cols="12" md="4">
                                                    <v-btn
                                                      v-if="editedLoanInsuredItem.occupation.high"
                                                      color="EoleGreen"
                                                      icon
                                                      outlined
                                                    >
                                                      <v-icon>mdi-check</v-icon>
                                                    </v-btn>

                                                    <v-btn outlined v-else color="EoleError" text icon>
                                                      <v-icon color="EoleError">mdi-close-thick </v-icon>
                                                    </v-btn>
                                                  </v-col>
                                                </v-row>

                                                <v-row class="justify-center px-5">
                                                  <v-col cols="12" md="2">
                                                    <v-switch
                                                      color="EoleBlue"
                                                      v-model="editedLoanInsuredItem.occupation.heaveLoad"
                                                      inset
                                                    ></v-switch>
                                                  </v-col>
                                                  <v-col align-self="center" cols="12" md="6"
                                                    >Port de charges lourdres</v-col
                                                  >
                                                  <v-col align="center" align-self="center" cols="12" md="4">
                                                    <v-btn
                                                      v-if="editedLoanInsuredItem.occupation.heaveLoad"
                                                      color="EoleGreen"
                                                      icon
                                                      outlined
                                                    >
                                                      <v-icon>mdi-check</v-icon>
                                                    </v-btn>

                                                    <v-btn outlined v-else color="EoleError" text icon>
                                                      <v-icon color="EoleError">mdi-close-thick </v-icon>
                                                    </v-btn>
                                                  </v-col>
                                                </v-row>
                                              </div>
                                            </v-card>
                                          </v-col>
                                          <v-col class="d-flex" cols="12" md="6">
                                            <v-card class="flex d-flex flex-column" elevation="0" outlined>
                                              <v-card-title class="EoleBlueLighten"
                                                >Concernant les déplacements professionnels</v-card-title
                                              >
                                              <div>
                                                <v-row class="pl-5 px-5 mb-n12">
                                                  <v-col cols="12" md="6">
                                                    <v-select
                                                      label="Déplacement professionnel"
                                                      class="mt-5"
                                                      outlined
                                                      v-model="editedLoanInsuredItem.occupation.trips"
                                                      :items="tripsInsured"
                                                    ></v-select>
                                                  </v-col>
                                                  <v-col cols="12" md="6"></v-col>
                                                </v-row>
                                                <v-row class="px-5">
                                                  <v-col cols="12" md="12">
                                                    <v-icon left>mdi-airplane</v-icon> Déplacements à l'étranger (+ de 2
                                                    mois) :<v-radio-group
                                                      v-model="editedLoanInsuredItem.occupation.tripsCountry"
                                                    >
                                                      <v-radio label="Oui" value="Oui"></v-radio>
                                                      <v-radio label="Non" value="Non"></v-radio>
                                                    </v-radio-group>
                                                  </v-col>
                                                </v-row>
                                              </div>
                                            </v-card>
                                          </v-col>
                                        </v-row>

                                        <v-row>
                                          <v-col cols="12" md="6" class="text-left">
                                            <v-btn text @click="addInsureStepper--"> retour </v-btn>
                                          </v-col>
                                          <v-col cols="12" md="6" class="text-right">
                                            <v-btn color="primary" @click="addInsureStepper++">
                                              Continuer
                                              <v-icon right>mdi-chevron-right</v-icon>
                                            </v-btn>
                                          </v-col>
                                        </v-row>
                                      </v-stepper-content>

                                      <v-stepper-content :key="`3-content`" :step="3">
                                        <div class="mb-5">
                                          <v-row class="justify-center px-5 mb-n10">
                                            <v-col cols="12" md="1">
                                              <v-switch
                                                color="EoleBlue"
                                                v-model="editedLoanInsuredItem.occupation.smoking"
                                                inset
                                              ></v-switch>
                                            </v-col>
                                            <v-col align-self="center" cols="12" md="6">Fumeur </v-col>
                                            <v-col align="center" align-self="center" cols="12" md="4">
                                              <v-btn
                                                v-if="editedLoanInsuredItem.occupation.smoking"
                                                color="EoleGreen"
                                                icon
                                                outlined
                                              >
                                                <v-icon>mdi-check</v-icon>
                                              </v-btn>

                                              <v-btn outlined v-else color="EoleError" text icon>
                                                <v-icon color="EoleError">mdi-close-thick</v-icon>
                                              </v-btn>
                                            </v-col>
                                          </v-row>

                                          <v-row class="justify-center px-5 mb-n10">
                                            <v-col cols="12" md="1">
                                              <v-switch
                                                color="EoleBlue"
                                                v-model="editedLoanInsuredItem.occupation.specificSport"
                                                inset
                                              ></v-switch>
                                            </v-col>
                                            <v-col align-self="center" cols="12" md="6"
                                              >Pratique de sports spécifique</v-col
                                            >
                                            <v-col align="center" align-self="center" cols="12" md="4">
                                              <v-btn
                                                v-if="editedLoanInsuredItem.occupation.specificSport"
                                                color="EoleGreen"
                                                icon
                                                outlined
                                              >
                                                <v-icon>mdi-check</v-icon>
                                              </v-btn>

                                              <v-btn outlined v-else color="EoleError" text icon>
                                                <v-icon color="EoleError">mdi-close-thick</v-icon>
                                              </v-btn>
                                            </v-col>
                                          </v-row>

                                          <v-row
                                            class="justify-center px-5 mb-n10"
                                            v-if="editedLoanInsuredItem.occupation.specificSport"
                                          >
                                            <v-col cols="12" md="1"></v-col>
                                            <v-col align-self="center" cols="12" md="6">
                                              <v-text-field
                                                prepend-inner-icon="mdi-cursor-text"
                                                outlined
                                                :label="`Nom du sport`"
                                                v-model="editedLoanInsuredItem.occupation.specificSportName"
                                              >
                                              </v-text-field>
                                            </v-col>
                                            <v-col align="center" align-self="center" cols="12" md="4"> </v-col>
                                          </v-row>

                                          <v-row class="justify-center px-5 mb-n10">
                                            <v-col cols="12" md="1">
                                              <v-switch
                                                color="EoleBlue"
                                                v-model="editedLoanInsuredItem.occupation.psySpineRepurchase"
                                                inset
                                              ></v-switch>
                                            </v-col>
                                            <v-col align-self="center" cols="12" md="6">Rachat dos/psy</v-col>
                                            <v-col align="center" align-self="center" cols="12" md="4">
                                              <v-btn
                                                v-if="editedLoanInsuredItem.occupation.psySpineRepurchase"
                                                color="EoleGreen"
                                                icon
                                                outlined
                                              >
                                                <v-icon>mdi-check</v-icon>
                                              </v-btn>

                                              <v-btn outlined v-else color="EoleError" text icon>
                                                <v-icon color="EoleError">mdi-close-thick</v-icon>
                                              </v-btn>
                                            </v-col>
                                          </v-row>

                                          <v-row class="justify-center px-5">
                                            <v-col cols="12" md="1">
                                              <v-switch
                                                color="EoleBlue"
                                                v-model="editedLoanInsuredItem.occupation.riskySportRepurchase"
                                                inset
                                              ></v-switch>
                                            </v-col>
                                            <v-col align-self="center" cols="12" md="6">Rachat sport à risques</v-col>
                                            <v-col align="center" align-self="center" cols="12" md="4">
                                              <v-btn
                                                v-if="editedLoanInsuredItem.occupation.riskySportRepurchase"
                                                color="EoleGreen"
                                                icon
                                                outlined
                                              >
                                                <v-icon>mdi-check</v-icon>
                                              </v-btn>

                                              <v-btn outlined v-else color="EoleError" text icon>
                                                <v-icon color="EoleError">mdi-close-thick</v-icon>
                                              </v-btn>
                                            </v-col>
                                          </v-row>
                                        </div>
                                        <v-row>
                                          <v-col cols="12" md="6" class="text-left">
                                            <v-btn text @click="addInsureStepper--"> retour </v-btn>
                                          </v-col>
                                          <v-col cols="12" md="6" class="text-right">
                                            <v-btn class="white--text" color="EoleGreen" @click="saveInsured">
                                              <v-icon>mdi-account-plus</v-icon> Ajouter l'assuré
                                            </v-btn>
                                          </v-col>
                                        </v-row>
                                      </v-stepper-content>
                                    </v-stepper-items>
                                  </v-stepper>
                                </v-card>
                              </v-dialog>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                      <v-divider class="my-5"></v-divider>
                      <v-row>
                        <v-col class="text-left" cols="12" md="6">
                          <v-btn text @click="borrowerStepper--"> Retour </v-btn>
                        </v-col>
                        <v-col class="text-right" cols="12" md="6">
                          <v-btn color="primary" @click="borrowerStep4()">
                            Continuer <v-icon right>mdi-chevron-right</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>

                    <v-stepper-content class="no-padding" step="4">
                      <v-tabs
                        style="box-shadow: none !important"
                        icons-and-text
                        background-color="EoleBlueLighten"
                        centered
                      >
                        <v-tab v-for="loanPersonnal in loan" v-bind:key="loanPersonnal.id">
                          <span
                            >Prêt N°{{ loanPersonnal.numLoan }} - {{ loanPersonnal.loanType }} |
                            {{ loanPersonnal.loanAmount }}€
                          </span>
                          <v-icon size="26" class="me-3"> mdi-bank </v-icon>
                        </v-tab>

                        <v-tab-item v-for="loanPersonnal in loan" v-bind:key="loanPersonnal.id">
                          <v-row>
                            <v-col cols="12" md="12">
                              <v-card elevation="0">
                                <v-card-text class="pa-5">
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      md="6"
                                      v-for="insu in loanPersonnal.loanInsured"
                                      v-bind:key="insu.id"
                                    >
                                      <v-card>
                                        <v-card-title class="EoleBlueLighten title-insured">
                                          <v-row align="center">
                                            <v-col class="text-left align-center" cols="12" md="10">
                                              {{ insu.lastname }} {{ insu.firstname }}</v-col
                                            >
                                            <v-col class="text-right" cols="12" md="2">
                                              <v-switch
                                                v-model="insu.insure"
                                                :value="!insu.insure"
                                                inset
                                                :label="insu.insure ? `Assuré sur le prêt` : `Non assuré sur le prêt`"
                                              >
                                              </v-switch>
                                            </v-col>
                                          </v-row>
                                        </v-card-title>
                                        <v-card
                                          :disabled="!insu.insure"
                                          outlined
                                          :color="!insu.insure ? 'rgba(193, 39, 44, 0.3)' : ''"
                                          class="text-left mb-2"
                                        >
                                          <v-card-text class="pa-5">
                                            <v-row class="text-left" v-bind:key="insu.id">
                                              <v-col cols="12" md="4">
                                                <v-text-field
                                                  :disabled="!insu.insure"
                                                  outlined
                                                  label="Quotité"
                                                  suffix="%"
                                                  v-model="insu.quota"
                                                >
                                                </v-text-field>
                                              </v-col>
                                              <v-col cols="12" md="12">
                                                <v-slider
                                                  :disabled="!insu.insure"
                                                  class="test"
                                                  v-model="insu.quota"
                                                  :max="100"
                                                  thumb-label
                                                  :messages="`Quotité à : ${insu.quota}%`"
                                                >
                                                  <template v-slot:thumb-label="{ value }"> {{ value }}% </template>
                                                </v-slider>
                                              </v-col>
                                              <v-col cols="12" md="12">
                                                <v-icon left>mdi-shield-account</v-icon>
                                                Garanties :
                                              </v-col>
                                            </v-row>
                                            <v-row class="justify-center">
                                              <v-col cols="12" md="2"></v-col>
                                              <v-col cols="12" md="6">
                                                <h3>Nature</h3>
                                              </v-col>
                                              <v-col align="center" cols="12" md="4">
                                                <h3>Information</h3>
                                              </v-col>
                                            </v-row>
                                            <div>
                                              <v-row
                                                class="my-n5 justify-center"
                                                v-for="guarantee in insu.guarantees"
                                                :key="guarantee.name"
                                              >
                                                <v-col cols="12" md="12">
                                                  <v-divider></v-divider>
                                                </v-col>
                                                <v-col cols="12" md="2">
                                                  <v-switch
                                                    color="EoleBlue"
                                                    v-model="guarantee.value"
                                                    :value="!guarantee.value"
                                                    :key="guarantee.name"
                                                    inset
                                                  ></v-switch>
                                                </v-col>
                                                <v-col align-self="center" cols="12" md="6">
                                                  {{ guarantee.name }}</v-col
                                                >
                                                <v-col align="center" align-self="center" cols="12" md="4">
                                                  <v-btn v-if="guarantee.value" color="EoleGreen" icon outlined>
                                                    <v-icon>mdi-check</v-icon>
                                                  </v-btn>

                                                  <v-btn outlined v-else color="EoleError" text icon>
                                                    <v-icon color="EoleError">mdi-close-thick </v-icon>
                                                  </v-btn>
                                                </v-col>
                                                <div
                                                  v-if="
                                                    guarantee.name === 'ITT : Incapacité temporaire totale' &&
                                                    guarantee.value
                                                  "
                                                  style="width: 40rem"
                                                  class="mb-5"
                                                >
                                                  <v-select
                                                    label="Franchise"
                                                    outlined
                                                    :items="borrowerFranchise"
                                                    v-model="borrowerFranchiseSelected"
                                                  ></v-select>
                                                </div>
                                              </v-row>
                                            </div>
                                          </v-card-text>
                                        </v-card>
                                      </v-card>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-tab-item>
                      </v-tabs>
                      <v-row class="text-center mb-5">
                        <v-col cols="12" md="12">
                          <v-btn class="white--text" color="EoleGreen" @click="e1++">
                            <v-icon left>mdi-check</v-icon> Terminer le paramètrage de mes prêts
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-card>
              <v-card v-else-if="productName === 'Moto/Scooter'" elevation="0" outlined>
                <v-stepper v-model="autoStepper">
                  <v-stepper-header style="height: auto !important">
                    <v-stepper-step :complete="autoStepper > 1" step="1"> Type de permis </v-stepper-step>
                    <v-divider></v-divider>

                    <v-stepper-step :complete="autoStepper > 2" step="2"> Type de cylindrée </v-stepper-step>
                    <v-divider></v-divider>

                    <v-stepper-step
                      :complete="autoStepper > 3"
                      step="
                        3"
                    >
                      Usage du véhicule
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="autoStepper > 4" step="4">
                      Mode de stationnement et lieu du véhicule</v-stepper-step
                    >

                    <v-divider></v-divider>

                    <v-stepper-step :complete="autoStepper > 5" step="5">
                      Mode d'acquisition du véhicule
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="autoStepper > 6" step="6"> Garanties principales </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="autoStepper > 7" step="7"> Garanties complémentaires </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="autoStepper > 8" step="8"> Sécurité et vol </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="autoStepper === 9" step="9"> Niveau de franchise </v-stepper-step>
                  </v-stepper-header>

                  <v-stepper-items>
                    <v-stepper-content step="1">
                      <v-card class="mb-12" color="grey lighten-1">
                        <v-card class="" color="grey lighten-1">
                          <v-card class="mb-5" outlined elevation="0">
                            <v-card-title style="background-color: #e1e5e9"
                              >De quel permis êtes-vous titulaire ?</v-card-title
                            >
                            <v-card-text class="pa-5">
                              <v-row class="justify-center">
                                <v-col cols="12" md="1"></v-col>
                                <v-col cols="12" md="6">
                                  <h3>Document</h3>
                                </v-col>
                                <v-col align="center" cols="12" md="4">
                                  <h3>Information</h3>
                                </v-col>
                              </v-row>
                              <div>
                                <v-row class="my-n5 justify-center" v-for="v in licences" :key="v.guarantee">
                                  <v-col cols="12" md="12">
                                    <v-divider></v-divider>
                                  </v-col>
                                  <v-col cols="12" md="1">
                                    <v-switch
                                      color="EoleBlue"
                                      v-model="licencesSelected"
                                      :value="v"
                                      :key="v"
                                      inset
                                    ></v-switch>
                                  </v-col>
                                  <v-col align-self="center" cols="12" md="6">{{ v }}</v-col>
                                  <v-col align="center" align-self="center" cols="12" md="4">
                                    <v-chip outlined v-if="licencesSelected.includes(v)" color="EoleGreen">
                                      <v-icon color="EoleGreen" left>mdi-check</v-icon> Titulaire
                                    </v-chip>
                                    <v-chip outlined v-else color="EoleError">
                                      <v-icon color="EoleError" left>mdi-close-thick</v-icon> Non titulaire
                                    </v-chip>
                                  </v-col>
                                </v-row>
                                <div
                                  class="mt-5"
                                  v-if="vehicleUsageMoreSelected.includes('Usage inférieur à ... Kilomètres')"
                                >
                                  <v-text-field
                                    type="number"
                                    v-model="distance"
                                    outlined
                                    suffix="Km"
                                    label="Nombre de kilomètres"
                                    required
                                  >
                                  </v-text-field>
                                </div>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-card>
                      </v-card>

                      <v-btn text @click="autoStepper--"> Retour </v-btn>
                      <v-btn :disabled="licencesSelected.length === 0" color="primary" @click="autoStepper = 2">
                        Continuer vers le type de cylindrée
                      </v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                      <v-card class="" color="grey lighten-1">
                        <v-card v-if="guaranteesFetch" class="mb-5" outlined elevation="0">
                          <v-card-title style="background-color: #e1e5e9">
                            Quel est le type de cylindrée ?
                          </v-card-title>
                          <v-card-text class="pa-5">
                            <v-row class="justify-center">
                              <v-col cols="12" md="1"></v-col>
                              <v-col cols="12" md="6">
                                <h3>Cylindre</h3>
                              </v-col>
                              <v-col align="center" cols="12" md="4">
                                <h3>Information</h3>
                              </v-col>
                            </v-row>
                            <div>
                              <v-row class="my-n5 justify-center" v-for="v in engines" :key="v">
                                <v-col cols="12" md="12">
                                  <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12" md="1">
                                  <v-switch
                                    color="EoleBlue"
                                    v-model="engineSelected"
                                    :value="v"
                                    :key="v"
                                    inset
                                  ></v-switch>
                                </v-col>
                                <v-col align-self="center" cols="12" md="6">{{ v }}</v-col>
                                <v-col align="center" align-self="center" cols="12" md="4">
                                  <v-chip outlined v-if="engineSelected === v" color="EoleGreen">
                                    <v-icon color="EoleGreen" left>mdi-check</v-icon> Garantie souhaitée
                                  </v-chip>
                                  <v-chip outlined v-else color="EoleError">
                                    <v-icon color="EoleError" left>mdi-close-thick</v-icon> Garantie non souhaitée
                                  </v-chip>
                                </v-col>
                              </v-row>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-card>

                      <v-btn text @click="autoStepper--"> Retour </v-btn>
                      <v-btn color="primary" @click="autoStepper = 3"> Continuer vers l'usage du véhicule </v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                      <v-card class="mb-12" color="grey lighten-1">
                        <v-card class="" color="grey lighten-1">
                          <v-card class="mb-5" outlined elevation="0">
                            <v-card-title style="background-color: #e1e5e9"
                              >Quel est l'usage du véhicule ? - {{ vehicleUsageSelected }}</v-card-title
                            >
                            <v-card-text class="pa-5">
                              <v-row class="justify-center">
                                <v-col cols="12" md="1"></v-col>
                                <v-col cols="12" md="6">
                                  <h3>Garanties</h3>
                                </v-col>
                                <v-col align="center" cols="12" md="4">
                                  <h3>Information</h3>
                                </v-col>
                              </v-row>
                              <div>
                                <v-row class="my-n5 justify-center" v-for="v in vehicleUsageMoto" :key="v.guarantee">
                                  <v-col cols="12" md="12">
                                    <v-divider></v-divider>
                                  </v-col>
                                  <v-col cols="12" md="1">
                                    <v-switch
                                      color="EoleBlue"
                                      v-model="vehicleUsageSelected"
                                      :value="v.guarantee"
                                      :key="v.guarantee"
                                      inset
                                    ></v-switch>
                                  </v-col>
                                  <v-col align-self="center" cols="12" md="6">{{ v.guarantee }} </v-col>
                                  <v-col align="center" align-self="center" cols="12" md="4">
                                    <v-chip outlined v-if="vehicleUsageSelected === v.guarantee" color="EoleGreen">
                                      <v-icon color="EoleGreen" left>mdi-check</v-icon> Garantie souhaitée
                                    </v-chip>
                                    <v-chip outlined v-else color="EoleError">
                                      <v-icon color="EoleError" left>mdi-close-thick</v-icon>
                                      Garantie non souhaitée
                                    </v-chip>
                                  </v-col>
                                </v-row>
                                <v-row class="my-n5 justify-center" v-for="v in vehicleUsageMore" :key="v.guarantee">
                                  <v-col cols="12" md="12">
                                    <v-divider></v-divider>
                                  </v-col>
                                  <v-col cols="12" md="1">
                                    <v-switch
                                      color="EoleBlue"
                                      v-model="vehicleUsageMoreSelected"
                                      :value="v.guarantee"
                                      :key="v.guarantee"
                                      inset
                                    ></v-switch>
                                  </v-col>
                                  <v-col align-self="center" cols="12" md="6">{{ v.guarantee }} </v-col>
                                  <v-col align="center" align-self="center" cols="12" md="4">
                                    <v-chip
                                      outlined
                                      v-if="vehicleUsageMoreSelected.includes(v.guarantee)"
                                      color="EoleGreen"
                                    >
                                      <v-icon color="EoleGreen" left>mdi-check</v-icon> Garantie souhaitée
                                    </v-chip>
                                    <v-chip outlined v-else color="EoleError">
                                      <v-icon color="EoleError" left>mdi-close-thick</v-icon>
                                      Garantie non souhaitée
                                    </v-chip>
                                  </v-col>
                                </v-row>
                                <div
                                  class="mt-5"
                                  v-if="vehicleUsageMoreSelected.includes('Usage inférieur à ... Kilomètres')"
                                >
                                  <v-text-field
                                    type="number"
                                    v-model="distance"
                                    outlined
                                    suffix="Km"
                                    label="Nombre de kilomètres"
                                    required
                                  >
                                  </v-text-field>
                                </div>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-card>
                      </v-card>

                      <v-btn text @click="autoStepper--"> Retour </v-btn>
                      <v-btn
                        :disabled="vehicleUsageSelected === '' || vehicleUsageSelected === null"
                        color="primary"
                        @click="autoStepper = 4"
                      >
                        Continuer vers le mode de stationnement et le lieu du véhicule
                      </v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="4">
                      <v-card class="" color="grey lighten-1">
                        <v-card v-if="guaranteesFetch" class="mb-5" outlined elevation="0">
                          <v-card-title style="background-color: #e1e5e9">
                            Quel est le lieu et le mode de stationnement du véhicule ?
                          </v-card-title>
                          <v-card-text class="pa-5">
                            <v-row class="justify-center">
                              <v-col cols="12" md="1"></v-col>
                              <v-col cols="12" md="6">
                                <h3>Garanties</h3>
                              </v-col>
                              <v-col align="center" cols="12" md="4">
                                <h3>Information</h3>
                              </v-col>
                            </v-row>
                            <div>
                              <v-row class="my-n5 justify-center" v-for="v in vehicleParking" :key="v">
                                <v-col cols="12" md="12">
                                  <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12" md="1">
                                  <v-switch
                                    color="EoleBlue"
                                    v-model="vehicleParkingSelected"
                                    :value="v"
                                    :key="v"
                                    inset
                                  ></v-switch>
                                </v-col>
                                <v-col align-self="center" cols="12" md="6">{{ v }}</v-col>
                                <v-col align="center" align-self="center" cols="12" md="4">
                                  <v-chip outlined v-if="vehicleParkingSelected === v" color="EoleGreen">
                                    <v-icon color="EoleGreen" left>mdi-check</v-icon> Garantie souhaitée
                                  </v-chip>
                                  <v-chip outlined v-else color="EoleError">
                                    <v-icon color="EoleError" left>mdi-close-thick</v-icon> Garantie non souhaitée
                                  </v-chip>
                                </v-col>
                              </v-row>
                            </div>
                          </v-card-text>
                        </v-card>
                        <v-card v-if="!!vehicleParkingSelected" class="mb-5" outlined elevation="0">
                          <v-card-title style="background-color: #e1e5e9"> Adresse de stationnement </v-card-title>
                          <v-card-text class="pa-5">
                            <div>
                              <p>
                                Merci d'indiquer si le véhicule sera stationné à l'adresse indiquée précedemment où si
                                il sera stationné à une adresse différente.
                              </p>
                              <v-switch
                                color="EoleBlue"
                                v-model="otherAddress"
                                :label="
                                  otherAddress ? 'A une nouvelle addresse' : 'A l\'addresse indiquée précédemment'
                                "
                                inset
                              ></v-switch>
                            </div>
                            <div v-show="otherAddress">
                              <v-row>
                                <v-col cols="12" md="3">
                                  <v-text-field
                                    @change="fetchCityByZip(otherZipClient)"
                                    outlined
                                    v-model="otherZipClient"
                                    label="Code postal"
                                    prepend-inner-icon="mdi-mailbox"
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" md="3">
                                  <v-select
                                    :disabled="isDisabled"
                                    no-data-text="Merci de rentrer un code postal valide pour trouver la ville"
                                    outlined
                                    :items="cityItems"
                                    label="Ville"
                                    v-model="otherCityClient"
                                    prepend-inner-icon="mdi-town-hall"
                                  >
                                  </v-select>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-text-field
                                    :rules="addressRules"
                                    outlined
                                    v-model="otherStreetClient"
                                    label="Adresse complète"
                                    prepend-inner-icon="mdi-map-marker"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-card>

                      <v-btn text @click="autoStepper--"> Retour </v-btn>
                      <v-btn :disabled="vehicleParkingSelected === ''" color="primary" @click="autoStepper = 5">
                        Continuer vers le mode d'acquisition
                      </v-btn>
                    </v-stepper-content>
                    <v-stepper-content step="5">
                      <v-card class="" color="grey lighten-1">
                        <v-card v-if="guaranteesFetch" class="mb-5" outlined elevation="0">
                          <v-card-title style="background-color: #e1e5e9">
                            Quel est le mode d'acquisition du véhicule ?
                          </v-card-title>
                          <v-card-text class="pa-5">
                            <v-row class="justify-center">
                              <v-col cols="12" md="1"></v-col>
                              <v-col cols="12" md="6">
                                <h3>Garanties</h3>
                              </v-col>
                              <v-col align="center" cols="12" md="4">
                                <h3>Information</h3>
                              </v-col>
                            </v-row>
                            <div>
                              <v-row class="my-n5 justify-center" v-for="v in vehicleProcurement" :key="v.id">
                                <v-col cols="12" md="12">
                                  <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12" md="1">
                                  <v-switch
                                    color="EoleBlue"
                                    v-model="vehicleProcurementSelected"
                                    :value="v.guarantee"
                                    :key="v.id"
                                    inset
                                  ></v-switch>
                                </v-col>
                                <v-col align-self="center" cols="12" md="6">{{ v.guarantee }} </v-col>
                                <v-col align="center" align-self="center" cols="12" md="4">
                                  <v-chip outlined v-if="vehicleProcurementSelected === v.guarantee" color="EoleGreen">
                                    <v-icon color="EoleGreen" left>mdi-check</v-icon> Garantie souhaitée
                                  </v-chip>
                                  <v-chip outlined v-else color="EoleError">
                                    <v-icon color="EoleError" left>mdi-close-thick</v-icon> Garantie non souhaitée
                                  </v-chip>
                                </v-col>
                                <div
                                  v-if="v.guarantee === 'achat' && vehicleProcurementSelected === 'achat'"
                                  style="width: 40rem"
                                  class="mb-5"
                                >
                                  <v-row class="">
                                    <v-col cols="12" md="1">
                                      <v-switch color="EoleBlue" v-model="guaranteePurchase" inset> </v-switch>
                                    </v-col>
                                    <v-col align-self="center" cols="12" md="6">Garantie valeur d'achat à neuf</v-col>
                                    <v-col align="center" align-self="center" cols="12" md="4">
                                      <v-chip outlined v-if="guaranteePurchase" color="EoleGreen">
                                        <v-icon color="EoleGreen" left>mdi-check</v-icon> Garantie souhaitée
                                      </v-chip>
                                      <v-chip outlined v-else color="EoleError">
                                        <v-icon color="EoleError" left>mdi-close-thick</v-icon>
                                        Garantie non souhaitée
                                      </v-chip>
                                    </v-col>
                                  </v-row>
                                  <v-row class="" v-for="pc in guaranteesPurchase" :key="pc">
                                    <v-col cols="12" md="1">
                                      <v-switch color="EoleBlue" v-model="guaranteesPurchaseSelected" :value="pc" inset>
                                      </v-switch>
                                    </v-col>
                                    <v-col align-self="center" cols="12" md="6">{{ pc }}</v-col>
                                    <v-col align="center" align-self="center" cols="12" md="4">
                                      <v-chip outlined v-if="guaranteesPurchaseSelected.includes(pc)" color="EoleGreen">
                                        <v-icon color="EoleGreen" left>mdi-check</v-icon> Garantie souhaitée
                                      </v-chip>
                                      <v-chip outlined v-else color="EoleError">
                                        <v-icon color="EoleError" left>mdi-close-thick</v-icon>
                                        Garantie non souhaitée
                                      </v-chip>
                                    </v-col>
                                  </v-row>
                                </div>
                                <div
                                  v-else-if="
                                    (v.guarantee === 'LOA/LDD' && vehicleProcurementSelected === 'LOA/LDD') ||
                                    (v.guarantee === 'Crédit-bail' && vehicleProcurementSelected === 'Crédit-bail')
                                  "
                                  style="width: 45rem"
                                  class="mb-5"
                                >
                                  <v-row class="mb-5 justify-center mx-auto">
                                    <v-col cols="12" md="1">
                                      <v-switch color="EoleBlue" v-model="financialLoss" inset> </v-switch>
                                    </v-col>
                                    <v-col align-self="center" cols="12" md="6">Garantie perte financière</v-col>
                                    <v-col align="center" align-self="center" cols="12" md="4">
                                      <v-chip outlined v-if="financialLoss" color="EoleGreen">
                                        <v-icon color="EoleGreen" left>mdi-check</v-icon> Garantie souhaitée
                                      </v-chip>
                                      <v-chip outlined v-else color="EoleError">
                                        <v-icon color="EoleError" left>mdi-close-thick</v-icon>
                                        Garantie non souhaitée
                                      </v-chip>
                                    </v-col>
                                  </v-row>
                                </div>
                              </v-row>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-card>

                      <v-btn text @click="autoStepper--"> Retour </v-btn>
                      <v-btn color="primary" @click="autoStepper = 6"> Continuer vers les garanties principales </v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="6">
                      <v-card class="mb-12" color="grey lighten-1" outlined>
                        <v-card class="EoleBlueLighten">
                          <v-card-title>Les garanties principales</v-card-title>
                          <v-card-subtitle>{{ autoMainCategorySelected }}</v-card-subtitle>
                        </v-card>
                        <v-card-text>
                          {{ autoMainCategorySelected }}
                          <v-switch
                            @change="pushMain(v)"
                            v-for="v in autoMainCategory"
                            v-model="autoMainCategorySelected"
                            :value="v"
                            :key="v"
                            :label="v"
                            inset
                          >
                          </v-switch>
                          <v-switch
                            @change="pushMain('Tous risques')"
                            v-model="autoMainCategorySelected"
                            value="Tous risques"
                            key="Tous risques"
                            label="Tous risques"
                            inset
                          ></v-switch>
                        </v-card-text>
                      </v-card>

                      <v-card v-if="!!autoMainCategorySelected" class="my-5" outlined elevation="0">
                        <v-card-title style="background-color: #e1e5e9"
                          >Garantie principale - {{ autoMainCategorySelected }}</v-card-title
                        >
                        <v-card-text class="pa-5">
                          <v-row class="justify-center">
                            <v-col cols="12" md="1"></v-col>
                            <v-col cols="12" md="6">
                              <h3>Garanties</h3>
                            </v-col>
                            <v-col align="center" cols="12" md="4">
                              <h3>Information</h3>
                            </v-col>
                          </v-row>
                          <div>
                            <v-row class="my-n5 justify-center" v-for="v in mainVehicle" :key="v">
                              <v-col cols="12" md="12">
                                <v-divider></v-divider>
                              </v-col>
                              <v-col cols="12" md="1">
                                <v-switch
                                  color="EoleBlue"
                                  v-model="mainVehicleSelected"
                                  :value="v"
                                  :key="v"
                                  inset
                                ></v-switch>
                              </v-col>
                              <v-col align-self="center" cols="12" md="6">{{ v }}</v-col>
                              <v-col align="center" align-self="center" cols="12" md="4">
                                <v-chip outlined v-if="mainVehicleSelected.includes(v)" color="EoleGreen">
                                  <v-icon color="EoleGreen" left>mdi-check</v-icon> Garantie souhaitée
                                </v-chip>
                                <v-chip outlined v-else color="EoleError">
                                  <v-icon color="EoleError" left>mdi-close-thick</v-icon> Garantie non souhaitée
                                </v-chip>
                              </v-col>
                            </v-row>
                          </div>
                        </v-card-text>
                      </v-card>

                      <v-btn text @click="autoStepper--"> Retour </v-btn>
                      <v-btn
                        :disabled="autoMainCategorySelected === '' || autoMainCategorySelected === null"
                        color="primary"
                        @click="autoStepper = 7"
                      >
                        Continuer vers les garanties complémentaires
                      </v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="7">
                      <loading
                        transition="fade"
                        :active.sync="loadingAllGuaranteesSelected"
                        :can-cancel="false"
                        :is-full-page="false"
                        loader="dots"
                        color="#efb639"
                        :opacity="0.9"
                      >
                      </loading>
                      <v-card class="mb-12" color="grey lighten-1">
                        <v-card v-if="!!autoMainCategorySelected" class="" color="grey lighten-1">
                          <v-card class="mb-5" outlined elevation="0">
                            <v-card-title style="background-color: #e1e5e9">Garanties complémentaires</v-card-title>
                            <v-card-text class="pa-5">
                              <v-row class="justify-center">
                                <v-col cols="12" md="1"></v-col>
                                <v-col cols="12" md="6">
                                  <h3>Garanties</h3>
                                </v-col>
                                <v-col align="center" cols="12" md="4">
                                  <h3>Information</h3>
                                </v-col>
                              </v-row>
                              <div>
                                <v-row class="my-n5 justify-center" v-for="v in vehicleAdditional" :key="v">
                                  <v-col cols="12" md="12">
                                    <v-divider></v-divider>
                                  </v-col>
                                  <v-col cols="12" md="1">
                                    <v-switch
                                      color="EoleBlue"
                                      v-model="vehicleAdditionalSelected"
                                      :value="v"
                                      :key="v"
                                      inset
                                    ></v-switch>
                                  </v-col>
                                  <v-col align-self="center" cols="12" md="6">{{ v }}</v-col>
                                  <v-col align="center" align-self="center" cols="12" md="4">
                                    <v-chip outlined v-if="vehicleAdditionalSelected.includes(v)" color="EoleGreen">
                                      <v-icon color="EoleGreen" left>mdi-check</v-icon> Garantie souhaitée
                                    </v-chip>
                                    <v-chip outlined v-else color="EoleError">
                                      <v-icon color="EoleError" left>mdi-close-thick</v-icon>
                                      Garantie non souhaitée
                                    </v-chip>
                                  </v-col>
                                  <div
                                    v-if="
                                      v === 'Conduite exclusive' &&
                                      vehicleAdditionalSelected.includes('Conduite exclusive')
                                    "
                                    style="width: 45rem"
                                    class="mb-5"
                                  >
                                    <v-row class="mb-5 justify-center mx-auto">
                                      <v-col cols="12" md="1">
                                        <v-switch color="EoleBlue" v-model="bodyProctectLendWheel" inset></v-switch>
                                      </v-col>
                                      <v-col align-self="center" cols="12" md="6"
                                        >Garantie corporelle en cas de prêt de volant</v-col
                                      >
                                      <v-col align="center" align-self="center" cols="12" md="4">
                                        <v-chip outlined v-if="bodyProctectLendWheel" color="EoleGreen">
                                          <v-icon color="EoleGreen" left>mdi-check</v-icon> Garantie souhaitée
                                        </v-chip>
                                        <v-chip outlined v-else color="EoleError">
                                          <v-icon color="EoleError" left>mdi-close-thick</v-icon>
                                          Garantie non souhaitée
                                        </v-chip>
                                      </v-col>
                                    </v-row>
                                  </div>
                                </v-row>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-card>
                      </v-card>

                      <v-card
                        v-if="
                          !!autoMainCategorySelected &&
                          vehicleAdditionalSelected.includes('Protection corporelle du conducteur')
                        "
                        class=""
                        color="grey lighten-1"
                      >
                        <v-card class="mb-5" outlined elevation="0">
                          <v-card-title style="background-color: #e1e5e9"
                            >Protection corporelle du conducteur</v-card-title
                          >
                          <v-card-text class="pa-5">
                            <v-row class="my-n5 justify-center">
                              <v-select
                                class="mt-5"
                                v-model="bodyProctectAmount"
                                :items="bodyProtectProposition"
                                outlined
                                suffix="€"
                                label="Montant de la protection"
                                required
                              ></v-select>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-card>

                      <v-btn text @click="autoStepper--"> Retour </v-btn>
                      <v-btn color="primary" @click="autoStepper = 8"> Continuer vers la sécurité et vol </v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="8">
                      <v-card class="mb-12" color="grey lighten-1">
                        <v-card v-if="!!autoMainCategorySelected" class="" color="grey lighten-1">
                          <v-card class="mb-5" outlined elevation="0">
                            <v-card-title style="background-color: #e1e5e9"
                              >Avez-vous des équipements contre le vol ?</v-card-title
                            >
                            <v-card-text class="pa-5">
                              <v-row class="justify-center">
                                <v-col cols="12" md="1"></v-col>
                                <v-col cols="12" md="6">
                                  <h3>Garanties</h3>
                                </v-col>
                                <v-col align="center" cols="12" md="4">
                                  <h3>Information</h3>
                                </v-col>
                              </v-row>
                              <div>
                                <v-row class="my-n5 justify-center" v-for="v in itemsAgainstSteal" :key="v">
                                  <v-col cols="12" md="12">
                                    <v-divider></v-divider>
                                  </v-col>
                                  <v-col cols="12" md="1">
                                    <v-switch
                                      color="EoleBlue"
                                      v-model="itemsAgainstStealSelected"
                                      :value="v"
                                      :key="v"
                                      inset
                                    ></v-switch>
                                  </v-col>
                                  <v-col align-self="center" cols="12" md="6">{{ v }}</v-col>
                                  <v-col align="center" align-self="center" cols="12" md="4">
                                    <v-chip outlined v-if="itemsAgainstStealSelected.includes(v)" color="EoleGreen">
                                      <v-icon color="EoleGreen" left>mdi-check</v-icon> Garantie souhaitée
                                    </v-chip>
                                    <v-chip outlined v-else color="EoleError">
                                      <v-icon color="EoleError" left>mdi-close-thick</v-icon>
                                      Garantie non souhaitée
                                    </v-chip>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-card>
                      </v-card>

                      <v-btn text @click="autoStepper--"> Retour </v-btn>
                      <v-btn color="primary" @click="autoStepper = 9"> Continuer vers le niveau de franchise</v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="9">
                      <v-card v-if="!!autoMainCategorySelected" class="" color="grey lighten-1">
                     
                      </v-card>

                      <v-btn text @click="autoStepper--"> Retour </v-btn>
                      <v-btn
                        color="primary"
                        @click="
                          () => {
                            arrayDifference()
                            e1++
                          }
                        "
                      >
                        Terminer ma démarche auto et continuer
                      </v-btn>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-card>

              <div v-else v-for="(value, index) in guaranteesProposed" :key="index">
                <v-card class="my-5" outlined elevation="0">
                  <v-card-title style="background-color: #e1e5e9"
                    >Garantie pour les
                    {{ value.categoryName }}
                  </v-card-title>
                  <v-card-text class="pa-5">
                    <v-row class="justify-center">
                      <v-col cols="12" md="1"></v-col>
                      <v-col cols="12" md="6">
                        <h3>Garanties</h3>
                      </v-col>
                      <v-col align="center" cols="12" md="4">
                        <h3>Information</h3>
                      </v-col>
                    </v-row>
                    <v-row class="my-n5 justify-center" v-for="guarantee in value.guarantees" :key="guarantee">
                      <v-col cols="12" md="12">
                        <v-divider></v-divider>
                      </v-col>
                      <v-col cols="12" md="1">
                        <v-switch color="EoleBlue" v-model="guaranteeKeep[index]" :value="guarantee" inset></v-switch>
                      </v-col>
                      <v-col align-self="center" cols="12" md="6">{{ guarantee }}</v-col>
                      <v-col align="center" align-self="center" cols="12" md="4">
                        <v-chip outlined v-if="guaranteeKeep[index].includes(guarantee)" color="EoleGreen">
                          <v-icon color="EoleGreen" left>mdi-check</v-icon> Garantie souhaitée
                        </v-chip>
                        <v-chip outlined v-else color="EoleError">
                          <v-icon color="EoleError" left>mdi-close-thick</v-icon> Garantie non souhaitée
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </div>
              <v-card v-if="productName === 'Responsabilité civile des dirigeants'" class="my-5" outlined elevation="0">
                <v-card-title style="background-color: #e1e5e9">Montant de garantie minimum préconisé </v-card-title>
                <v-card-text class="my-12">
                  <v-card-text class="pt-0">
                    <v-slider
                      class="test"
                      v-model="recommandAmount"
                      :tick-labels="ticksLabels"
                      :max="10"
                      ticks="always"
                      tick-size="5"
                      append-icon="mdi-plus"
                      prepend-icon="mdi-minus"
                      @click:append="upRecommandAmount"
                      @click:prepend="downRecommandAmount"
                    >
                    </v-slider>
                  </v-card-text>
                  <v-row class="text-center">
                    <v-col>
                      <v-chip color="EoleBlue">Montant préconisé : {{ ticksLabels[recommandAmount] }}€</v-chip>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>

          <v-stepper-items>
            <v-stepper-content :step="5" class="no-padding">
              <v-card outlined class="">
                <v-card-title class="EoleBlue" style="color: white !important; font-size: 1rem">
                  Formalisation de la lettre de devoir de conseil
                </v-card-title>
                <v-card-text class="pa-5">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-card @click="testMerger" outlined flat class="text-center justify-center">
                        <v-card-title class="text-center justify-center">Télécharger le document </v-card-title>
                        <v-card-text> <i class="fa-solid fa-download fa-4x EoleBlue--text"></i> </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-card @click="sendmail" outlined flat class="text-center justify-center">
                        <v-card-title class="text-center justify-center"
                          >Envoyer le document par mail au client</v-card-title
                        >
                        <v-card-text>
                          <i class="fa-solid fa-envelope-open-text fa-4x EoleBlue--text"></i>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-card-text></v-card-text>
                  </v-row>
                  <iframe id="lddcVisualisator" style="width: 100%; height: 500px"> </iframe>
                  <!-- <iframe hidden id="studyVisualisator" style="width: 100%; height: 500px">
                  </iframe> -->
                </v-card-text>
                <v-divider></v-divider>

                <v-card-actions>
                  <v-btn @click="reinitializeDocument" elevation="0">
                    <v-icon left> mdi-chevron-left </v-icon>
                    <span>Retour</span>
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>

          <v-stepper-items>
            <v-stepper-content :step="6" class="no-padding">
              <v-card outlined class="">
                <v-card-title class="EoleBlue" style="color: white !important; font-size: 1rem">
                  Auprès de quelle compagnie un devis est-il réalisé ?
                </v-card-title>
                {{ insurerSelected }}
                <v-card-text class="pa-5">
                  <v-row class="text-center">
                    <v-col
                      class="d-flex text-center"
                      v-for="insurer in insurersNameForProduct"
                      :key="insurer.id"
                      cols="12"
                      md="3"
                    >
                      <v-card
                        width="150"
                        height="200"
                        v-model="insurerSelected"
                        outlined
                        :color="insurerSelected.includes(insurer) ? 'rgba(0, 31, 71, 0.3)' : ''"
                        link
                        @click="
                          () => {
                            addInsurerSelected(insurer)
                          }
                        "
                        class="text-center justify-center flex d-flex flex-column"
                      >
                        <v-card-title class="mullerFont justify-center">{{ insurer.name }} </v-card-title>
                        <v-card-text>
                          <v-img
                            alt="test"
                            class="mx-auto my-2"
                            max-height="50"
                            max-width="50"
                            :src="`https://logo.clearbit.com/https://${insurer.website_url}?size=128`"
                            @error="showErrorIcon(insurer)"
                          >
                          </v-img>
                          <v-icon v-if="insurer.showErrorIcon" size="50" color="EoleError"> mdi-alert-circle</v-icon>
                        </v-card-text>
                        <v-card-actions class="justify-center ml-2">
                          <v-checkbox
                            v-model="insurerSelected"
                            :value="insurer"
                            @click="
                              () => {
                                addInsurerSelected(insurer)
                              }
                            "
                          ></v-checkbox>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="e1++" elevation="0">
                    <span>INJECTER MES DEVIS</span>
                    <v-icon right> mdi-chevron-right </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>

          <v-stepper-items>
            <v-stepper-content :step="7" class="no-padding">
              <v-card outlined class="">
                <v-card-title class="EoleBlue" style="color: white !important; font-size: 1rem">
                  Injection des devis
                </v-card-title>
                <v-card-text v-if="insurerSelected.length > 0" class="pa-5">
                  <v-row>
                    <v-col v-for="insurer in insurerSelected" :key="insurer.id" class="d-flex" cols="12" md="6">
                      <v-card outlined class="flex d-flex flex-column">
                        <v-card-title>
                          <v-row>
                            <v-col cols="12" md="6" class="text-left">Devis</v-col>
                            <v-col cols="12" md="6" class="text-right">
                           
                              <v-img
                                alt="test"
                                class="mx-auto my-2"
                                max-height="50"
                                max-width="50"
                                :src="`https://logo.clearbit.com/https://${insurer.website_url}?size=128`"
                                @error="showErrorIcon(insurer)"
                              >
                              </v-img>
                              <v-icon v-if="insurer.showErrorIcon" size="50" color="EoleError">
                                mdi-alert-circle</v-icon
                              >
                            </v-col>
                          </v-row>
                        </v-card-title>
                        <v-card-subtitle>{{ insurer.name }}</v-card-subtitle>
                        <v-card-text class="flex-grow-1 no-padding">
                          <v-btn block @click="openFileExplorer(insurer)" elevation="0">INJECTER MES DEVIS</v-btn>
                        </v-card-text>
                        {{ insurer.documents }}
                        <v-card-text v-if="insurer.documents.length > 0" class="no-padding">
                          <v-data-table
                            :items="insurer.documents"
                            hide-default-footer
                            item-key="name"
                            :headers="headersFile"
                            no-results-text="Nous n'avons trouvé aucun résultat correspondant à votre recherche"
                          >
                            <template v-slot:item="props">
                              <tr>
                                <td v-if="props.item.type === 'application/pdf'">
                                  <i class="fa-solid fa-file-pdf fa-2x EoleError--text"></i>
                                </td>
                                <td
                                  v-else-if="
                                    props.item.type ===
                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                  "
                                >
                                  <i class="fa-solid fa-file-word fa-2x EoleBlue--text"></i>
                                </td>
                                <td v-else>
                                  <i class="fa-solid fa-file-circle-exclamation fa-2x"></i>
                                </td>
                                <td>
                                  {{ props.item.name }}
                                </td>
                                <td>{{ formatBytes(props.item.size, 0) }}</td>
                                <td>
                                  <v-tooltip v-if="props.item.type === 'application/pdf'" right>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="readFile(props.item, props.index)"
                                        text
                                        icon
                                      >
                                        <i class="fa-solid fa-eye"></i>
                                      </v-btn>
                                    </template>
                                    <span>Voir le fichier {{ props.item.name }}</span>
                                  </v-tooltip>
                                  <v-tooltip v-else right>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn v-bind="attrs" v-on="on" text icon disabled>
                                        <i class="fa-solid fa-eye-slash"></i>
                                      </v-btn>
                                    </template>
                                    <span>Il est impossible de visualiser un document word</span>
                                  </v-tooltip>
                                  <v-dialog
                                    v-if="props.item.type === 'application/pdf'"
                                    v-model="dialogFile[props.index]"
                                    max-width="600px"
                                  >
                                    <v-card>
                                      <v-card-title>
                                        <span class="text-h5">
                                          {{ props.item.name }}
                                        </span>
                                      </v-card-title>
                                      <v-divider></v-divider>

                                      <v-card-text v-if="props.item.type === 'application/pdf'" class="no-padding mt-5">
                                        <iframe
                                          :src="selectedDocumentUrl[props.index]"
                                          width="100%"
                                          height="700px"
                                        ></iframe>
                                      </v-card-text>

                                      <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="EoleYellow" text @click="dialogFile[props.index] = false">
                                          Annuler</v-btn
                                        >
                                      </v-card-actions>
                                    </v-card>
                                  </v-dialog>
                                </td>
                              </tr>
                            </template>
                          </v-data-table>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider></v-divider>

                <v-card-actions>
                  <v-btn @click="e1--" elevation="0">
                    <v-icon left> mdi-chevron-left </v-icon>
                    <span>Précédent</span>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="
                      () => {
                        uploadAllFiles()
                      }
                    "
                    elevation="0"
                  >
                    <span>Envoyer tous les documents</span>
                    <v-icon right> mdi-chevron-right </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue"
// import { Drag, Drop } from "vue-easy-dnd"

import emailjs from "@emailjs/browser"
import { init } from "@emailjs/browser"
import config from "@/views/config/config"
import imageData from "@/views/config/imageData"
import pdfMake, { async } from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
import moment from "moment"
import Loading from "vue-loading-overlay"
import "vue-loading-overlay/dist/vue-loading.css"
const PDFMerger = require("pdf-merger-js")
var merger = new PDFMerger()
moment.locale("fr")
pdfMake.vfs = pdfFonts.pdfMake.vfs
init("user_gUunL4KW2ImPaGngWHYhP")

export default Vue.extend({
  name: "Home",

  data: () => ({
    agencyPictureProfil: null,
    productSelected: {},
    /*** Variable générale  ***/
    typologyChoice: [
      {
        name: "PROFESSIONNEL",
        icon: "fa-building",
        hovered: false,
      },
      {
        name: "PARTICULIER",
        icon: "fa-people-arrows",
        hovered: false,
      },
    ],
    selectedDocumentUrl: [],
    headersFile: [
      {
        text: "Type de fichier",
        value: "type",
      },
      {
        text: "Nom du fichier",
        value: "name",
      },
      {
        text: "Taille du fichier",
        value: "size",
      },
      {
        text: "Action",
        value: "action",
      },
    ],
    dialogFile: [],
    loadedImages: new Set(),
    errorImages: new Set(),
    loadingImages: new Set(),
    customerDiscoveryHeaders: [
      {
        text: "Nom",
        align: "start",
        sortable: false,
        value: "name",
        width: "20%",
      },
      {
        text: "Prénom",
        align: "start",
        sortable: false,
        value: "firstname",
        width: "20%",
      },
      {
        text: "Date de naissance",
        align: "start",
        sortable: false,
        value: "birthdate",
        width: "20%",
      },
    ],

    filesUpload: [],
    lastLogs: [],
    showPage: false,
    lddc: null,
    estimate: null,
    insurerFileSelected: null,
    panelGuarantees: [],
    page: 1,
    pageCount: 0,
    searchIntoDrafts: "",
    headersDirectors: [
      {
        text: "",
        align: "start",
        sortable: false,
        value: "checkbox",
        width: "5%",
      },
      {
        text: "Civilité",
        align: "start",
        sortable: false,
        value: "sexe",
        width: "10%",
      },
      {
        text: "Nom",
        align: "start",
        sortable: false,
        value: "nom",
        width: "10%",
      },
      {
        text: "Prénom",
        align: "start",
        sortable: false,
        value: "prenom_usuel",
        width: "10%",
      },
    ],
    search: null,
    emailClient: null,
    documentsData: [],
    merchand: "",
    newDocument: false,
    existingDrafts: false,
    currentDraftId: null,
    loaderDataSave: null,
    loadingDataSave: false,
    hasUnsavedChanges: false,
    showInformations: false,
    mainVehicleHeaders: [
      {
        text: "",
        value: "select",
        sortable: false,
      },
      {
        text: "Garanties du véhicule",
        value: "name",
        sortable: false,
      },
      {
        text: "",
        value: "chip",
        sortable: false,
      },
      {
        text: "",
        value: "information",
        sortable: false,
      },
    ],
    vehicleAdditionalHeaders: [
      {
        text: "",
        value: "select",
        sortable: false,
      },
      {
        text: "Garanties complémentaires du véhicule",
        value: "name",
        sortable: false,
      },
      {
        text: "",
        value: "chip",
        sortable: false,
      },
      {
        text: "",
        value: "information",
        sortable: false,
      },
    ],
    drafts: [],
    draftsHeaders: [
      {
        text: "Client concerné",
        value: "clientName",
      },
      {
        text: "Produit",
        value: "name",
        sortable: false,
      },
      {
        text: "Dernière modification",
        value: "lastUpdate",
        sortable: true,
      },
      {
        text: "Conseil",
        value: "advice",
        sortable: false,
      },
      {
        text: "Devis",
        value: "documents",
        sortable: false,
      },
      {
        text: "",
        value: "actions",
        sortable: false,
      },

      {
        text: "",
        value: "others",
        sortable: false,
      },
    ],
    mainTrailerHeaders: [
      {
        text: "",
        value: "select",
        sortable: false,
      },
      {
        text: "Garanties de la remorque",
        value: "name",
        sortable: false,
      },
      {
        text: "",
        value: "chip",
        sortable: false,
      },
      {
        text: "",
        value: "information",
        sortable: false,
      },
    ],
    mainDeductibleHeaders: [
      {
        text: "",
        value: "select",
        sortable: false,
      },
      {
        text: "Niveau de franchise",
        value: "name",
        sortable: false,
      },
      {
        text: "",
        value: "chip",
        sortable: false,
      },
      {
        text: "",
        value: "information",
        sortable: false,
      },
    ],
    mainTrailerAmount: 0,
    itemsEquipmentsHeader: [
      {
        text: "",
        value: "select",
        sortable: false,
      },
      {
        text: "Équipement",
        value: "name",
        sortable: false,
      },
      {
        text: "Montant de l'équipement",
        value: "chip",
        sortable: false,
      },
    ],
    itemsEquipementsSelected: [],
    itemsEquipements: [
      {
        name: "Barre de toit",
        amount: 0,
        icon: "mdi-car-roof-box",
      },
      {
        name: "Toit ouvrant",
        amount: 0,
        icon: "mdi-car-door",
      },
      {
        name: "Coffre de toit",

        amount: 0,
        icon: "mdi-car-roof-box",
      },
      {
        name: "Covering",
        amount: 0,
        icon: "mdi-car-door",
      },
      {
        name: "Porte vélo",
        amount: 0,
        icon: "mdi-car-roof-box",
      },
      {
        name: "Rangements",
        amount: 0,
        icon: "mdi-car-door",
      },
      {
        name: "Jantes",
        amount: 0,
        icon: "mdi-car-roof-box",
      },
      {
        name: "Spoiler",
        amount: 0,
        icon: "mdi-car-door",
      },
      {
        name: "Tablettes",
        amount: 0,
        icon: "mdi-car-roof-box",
      },
      {
        name: "Fauteuil roulant",
        amount: 0,
        icon: "mdi-car-door",
      },
      {
        name: "Animal",
        amount: 0,
        icon: "mdi-car-roof-box",
      },
    ],
    oneDropped: false,
    twoDropped: false,
    aDropped: false,
    bDropped: false,
    /*** Produit emprunteur  ***/
    addInsureStepper: 1,
    addInsureStepsCount: 3,

    idTest: 4,
    loanStepper: 1,
    loanStepsCount: 0,
    quota: [
      "100 000",
      "250 000",
      "500 000",
      "750 000",
      "1 000 000",
      "1 500 000",
      "2 000 000",
      "2 500 000",
      "3 000 000",
      "4 000 000",
      "5 000 000",
    ],
    fromDateMenu: false,
    fromDateVal: null,

    minDate: "1900-01-01",
    maxDate: new Date().toISOString().substr(0, 10),
    loan: [],
    loanInsuredGender: [
      {
        name: "Homme",
        value: "H",
      },
      {
        name: "Femme",
        value: "F",
      },
    ],
    insured: [],
    loanType: ["Amortissable", "In fine", "Taux zéro", "Relais", "LOA/LDD"],
    loanTypeSelected: "",
    loanAmount: 0,
    loanDelay: "",
    loanRate: "",
    loanDeferred: false,
    loanFrequency: ["Mensuelle", "Trimestrielle", "Semestrielle", "Annuelle"],
    loanFrequencySelected: "",
    editedIndex: -1,
    editedLoanInsuredIndex: -1,
    tripsInsured: ["- 20 000 kms/an", "< 20 000 kms/an", "+40 000 kms/an"],
    defaultItem: {
      id: 0,
      numLoan: 1,
      loanType: "",
      loanAmount: 0,
      loanDelay: null,
      loanRateType: "",
      loanRate: null,
      loanDiffered: "",
      loanDifferedSelected: "",
      loanFrequencySelected: "",
      loanDelayDiffered: "",
      loanInsured: [],
    },
    editedItem: {
      id: 0,
      numLoan: 1,
      loanType: "",
      loanAmount: 0,
      loanDelay: null,
      loanRateType: "",
      loanRate: null,
      loanDiffered: "",
      loanDifferedSelected: "",
      loanFrequencySelected: "",
      loanDelayDiffered: "",
      loanInsured: [],
    },

    defaultLoanInsuredItem: {
      id: 0,
      insure: true,
      num: 1,
      lastname: "",
      firstname: "",
      sex: "",
      birthdate: "",
      occupation: {
        name: "",
        risky: false,
        manual: false,
        high: false,
        heaveLoad: false,
        trips: "- 20 000 kms/an",
        tripsCountry: false,
        smoking: false,
        specificSport: false,
        specificSportName: "",

        psySpineRepurchase: false,
        riskySportRepurchase: false,
      },
      quota: 0,

      guarantees: [
        {
          name: "DC : Décès",
          value: true,
        },
        {
          name: "PTIA : Perte totale et irréversible d’autonomie",
          value: true,
        },
        {
          name: "IPT : Invalidité permanente Totale",
          value: true,
        },
        {
          name: "IPP : invalidité permanente partielle (33%)",
          value: true,
        },
        {
          name: "ITT : Incapacité temporaire totale",
          value: true,
        },
        {
          name: "PE : Perte d'emploi",
          value: false,
        },
      ],
    },
    editedLoanInsuredItem: {
      id: 0,
      insure: true,
      num: 1,
      lastname: "",
      firstname: "",
      sex: "",
      birthdate: "",
      occupation: {
        name: "",
        risky: false,
        manual: false,
        high: false,
        heaveLoad: false,
        trips: "- 20 000 kms/an",
        tripsCountry: false,
        smoking: false,
        specificSport: false,
        specificSportName: "",

        psySpineRepurchase: false,
        riskySportRepurchase: false,
      },
      quota: 0,

      guarantees: [
        {
          name: "DC : Décès",
          value: true,
        },
        {
          name: "PTIA : Perte totale et irréversible d’autonomie",
          value: true,
        },
        {
          name: "IPT : Invalidité permanente Totale",
          value: true,
        },
        {
          name: "IPP : invalidité permanente partielle (33%)",
          value: true,
        },
        {
          name: "ITT : Incapacité temporaire totale",
          value: true,
        },
        {
          name: "PE : Perte d'emploi",
          value: false,
        },
      ],
    },
    borrowerLoanDialog: false,
    borrowerLoanInsuredDialog: false,
    borrowerLoanInsuredGuaranteesDialog: false,
    borrowerSelected: "",
    borrowerReasonProfessionnal: [
      "Acquisitions de titres / parts sociales",
      "Immobilier professionnel",
      "Rachat de comptes courants d’associés",
      "Investissements professionnels",
    ],
    borrowerReasonPersonnal: [
      "Acquisition de résidence principale",
      "Acquisition de résidence secondaire",
      "Investissement locatif",
      "Travaux immobiliers",
      "Acquisition d’un véhicule",
      "Acquisition d’un bateau",
      "Rachat de crédit immobilier",
    ],
    borrowerReasonSelected: "",
    borrowerBankGuarantees: [
      "DC : Décès",
      "PTIA : Perte totale et irréversible d’autonomie",
      "IPT : Invalidité permanente Totale",
      "IPP : invalidité permanente partielle (33%)",
      "ITT : Incapacité temporaire totale",
      "PE : Perte d’emploi",
    ],
    borrowerBankGuaranteesSelected: [
      "DC : Décès",
      "PTIA : Perte totale et irréversible d’autonomie",
      "IPT : Invalidité permanente Totale",
      "IPP : invalidité permanente partielle (33%)",
      "ITT : Incapacité temporaire totale",
    ],
    borrowerFranchise: [
      "Franchise : 30 jours",
      "Franchise 60 jours",
      "Franchise 90 jours",
      "Franchise 120 jours",
      "Franchise 180 jours",
    ],
    borrowerFranchiseSelected: "Franchise 90 jours",

    /*** Produit RCMS ***/
    /*** ---- ***/

    /*** Produit automobile ***/
    searchItem: "",

    banks: [],
    banksCopy: [],
    customerGuarantees: {
      wish: {
        mainGuarantees: [],
        additionalGuarantees: [],
        trailersGuarantees: [],
        deductibleGuarantees: [],
        trailerMerchandiseAmount: 0,
      },
      advice: {
        mainGuarantees: [],
        additionalGuarantees: [],
        trailersGuarantees: [],
        deductibleGuarantees: [],
        trailerMerchandiseAmount: 0,
      },
    },
    customerData: {
      civility: "",
      lastname: "",
      firstname: "",
      phone: "",
      email: "",
      city: "",
      zip: "",
      street: "",
    },
    customerDiscoveryVehicle: {
      vehicleSinister: false,
      vehicleKilometersByYear: 0,
      vehicleValueKnown: false,
      licenceJunior: false,
      termination: false,
      licenceCancel: false,
      licenceCancelTime: 0,
      sentenced: false,
      vehicleValue: 0,
      vehiclePrice: 0,
      vehicleAge: 0,
      vehicleModelKnown: false,
      vehicleModel: "Example",
      vehicleCollectible: false,
      vehicleCollectibleExpert: false,
      vehicleCollectibleExpertPrice: 0,
      funding: {
        duration: 0,
        bank: "",
        amount: 0,
      },
      trailerOwner: false,
      trailersLent: false,
      trailers: [],
      equipments: [],
    },
    /*** Produit Moto/Scooter ***/
    wintering: false,
    bodyProctectLendWheel: false,
    bodyProtectProposition: ["0 - 150 000", "150 000 - 250 000", "+ 250 000"],
    bodyProctectAmount: 0,
    studyItems: [],
    arrayNull: [],
    licencesSelected: [],
    licences: [],

    engines: [],
    engineSelected: "",
    itemsAgainstSteal: [],
    itemsAgainstStealSelected: [],
    otherAddress: false,
    otherCityClient: "",
    otherStreetClient: "",
    otherZipClient: "",
    /*** ---- ***/

    /*** Mention RGPD ***/
    personnalData: [
      "Données d’identité : prénoms, nom, titre, date de naissance et genre.",
      "Données de contact : adresse de facturation, adresse personnelle, adresse professionnelle, adresse e-mail et numéros de téléphone.",
      "Données financières : détails du compte et de la carte de paiement (nous utilisons ces données uniquement à des fins de traitement des paiements).",
      "Données techniques : adresse IP, données de connexion, données de trafic, type et version du navigateur, type et version du téléphone, paramètres et emplacement du fuseau horaire, types et versions du plug-in du navigateur, système d’exploitation et plateforme, et autres informations concernant la façon dont vous avez accédé à notre site web (où celui de nos partenaires) et notre application.",
      "Données d’utilisation : informations sur la lecture des propositions commerciales reçues, et la façon dont vous utilisez nos services.",
      "Données marketing et communication : vos préférences marketing et communication.",
    ],
    collectData: [
      "Des interactions directes : vous pouvez nous fournir des informations personnelles en remplissant des formulaires ou en nous contactant par courrier, téléphone, email, en personne lors d'événements, dans le cadre de notre accord contractuel avec vous, en saisissant ces données sur l'une de nos plateformes clients, etc. Cela inclut les données personnelles que vous fournissez lorsque :",
      "Vous commandez ou exprimez un intérêt pour nos produits ou services,",
      "Vous participez à un concours, une promotion ou un sondage,",
    ],
    reasonCollectData: [
      "Pour vérifier votre identité et vos droits à nos produits et services lorsque vous nous contactez ou accédez à nos services",
      "Pour vous protéger des fraudes",
      "Pour mener à bien nos obligations envers vous, reliées par notre contrat",
      "Pour vous envoyer des relevés et des factures et percevoir des paiements de votre part",
      "Pour vous fournir des devis commerciaux",
      "Pour exploiter et améliorer nos services",
      "Pour vous informer de tout changement apporté à notre activité (adresse, téléphones, horaires…)",
      "Pour vous fournir un support",
      "Pour obtenir votre avis ou vos commentaires sur nos services",
      "Pour faire respecter nos droits légaux ou se conformer aux exigences légales",
    ],
    useData: [
      "Lorsque nous devons exécuter le contrat que nous sommes sur le point de mettre en place ou de conclure avec vous.",
      "Lorsque nous devons nous conformer à une obligation légale ou réglementaire.",
    ],
    rightData: [
      "Un accès à vos informations personnelles",
      "De réclamer toute demande de modification ou correction de vos informations",
      "D’exiger la suppression des informations personnelles vous concernant dans certaines situations",
      "De recevoir les informations personnelles que vous nous avez fournies, dans un format structuré, communément utilisé et lisible par machine, et avoir le droit de transmettre ces données à un tiers dans certaines situations",
      "De s’opposer à tout moment au traitement des informations personnelles vous concernant pour le marketing direct",
      "De s’opposer aux décisions prises par des moyens automatisés (y compris le profilage)",
      "De s’opposer dans certaines au traitement continu de vos informations personnelles",
      "De limiter le traitement de vos informations personnelles dans certaines circonstances",
    ],

    shareData: [
      "Les fournisseurs que nous engageons pour proposer les services que nous offrons à nos clients (outils de signature électronique par exemple) ou pour proposer les garanties d’assurance conformes à vos besoins en votre nom. Ces sociétés sont basées en Europe.",
      "Des conseillers professionnels, y compris des avocats, des comptables des notaires… si nécessaire, pour leur permettre de nous fournir leurs services et leur accompagnement.",
      "Des régulateurs et autres autorités qui exigent la déclaration des activités dans certaines circonstances.",
    ],

    /*** ---- ***/

    //? LDDC variables
    guaranteeBrokenGlass: false,
    informationsAboutCompany: "",
    distance: 0,
    signatory: [],
    guaranteesFetch: false,
    recommandAmount: 0,
    ticksLabels: [
      "100 000",
      "250 000",
      "500 000",
      "750 000",
      "1 000 000",
      "1 500 000",
      "2 000 000",
      "2 500 000",
      "3 000 000",
      "4 000 000",
      "5 000 000",
    ],

    //? Automobile steps
    autoMainCategoryDontKeep: [],
    rcOnly: true,
    damageOnly: false,
    merchandiseTransported: false,
    merchandiseTransportedAmount: 0,
    merchandiseTransportedTrailer: false,
    merchandiseTransportedTrailerAmount: 0,

    guaranteePurchase: false,
    financialLoss: true,
    guaranteesPurchaseSelected: "",

    guaranteesPurchase: ["au comptant", "via un financement bancaire"],
    trailer: [],
    trailerSelected: "",
    deductible: [],
    deductibleSelected: [],
    deductibleDontKeep: [],
    vehicleReplacement: [],
    vehicleReplacementSelected: [],
    vehicleReplacementDontKeep: [],

    personneMorale: [],
    corporationKeep: [],
    corporationDontKeep: [],

    personnePhysique: [],
    physicalPersonKeep: [],
    physicalPersonDontKeep: [],

    mainVehicle: [],
    mainVehicleSelected: [],
    mainVehicleDontKeep: [],
    vehicleAdditional: [],
    vehicleAdditionalSelected: [],
    vehicleAdditionalDontKeep: [],
    mainTrailers: ["RC Seule", "Dommages", "Marchandises transportées"],
    mainTrailersSelected: ["RC Seule"],

    vehicleParkingSelected: "",

    vehicleParking: [],
    vehicleProcurementHeaders: [
      {
        text: "",
        value: "select",
        sortable: false,
      },
      {
        text: "Mode de financement",
        value: "guarantee",
        sortable: false,
      },
      {
        text: "",
        value: "chip",
        sortable: false,
      },
      {
        text: "",
        value: "information",
        sortable: false,
      },
    ],
    vehicleProcurementSelected: "",
    vehicleProcurement: [
      {
        id: 1,
        guarantee: "Au comptant",
        specificity: false,
      },
      {
        id: 2,
        guarantee: "Via loa/ldd",
        specificity: false,
      },
      {
        id: 3,
        guarantee: "Via crédit-bail",
        specificity: false,
      },
    ],
    vehicleUsageSelected: "",
    vehicleUsage: [
      {
        id: 1,
        guarantee: "Déplacement privé",
        comments: "Que vous ne vous servez de votre véhicule que pour vos loisirs",
        specificity: false,
      },
      {
        id: 2,
        guarantee: "Déplacement privé et domicile travail",
        comments:
          "Que vous vous ne vous servez de votre véhicule que pour vos loisirs et pour vous rendre sur votre lieu de travail",
        specificity: false,
      },
      {
        id: 3,
        guarantee: "Déplacement privé et professionnel (sauf tournée)",
        comments:
          "Que vous vous ne vous servez de votre véhicule que pour vos loisirs, vous rendre au travail et que vous êtes amené à vous déplacer chez des clients pour vos besoins professionnels.",
        specificity: false,
      },
      {
        id: 4,
        guarantee: "Déplacement privé et professionnel (y compris tournée)",
        comments:
          "Que vous vous ne vous servez de votre véhicule que pour vos loisirs, vous rendre au travail et que vous êtes amené à vous déplacer chez des clients pour vos besoins professionnels dans le cadre de tournées régulières.",
        specificity: false,
      },
      {
        id: 5,
        guarantee: "Tout déplacement",
        comments: "Que vous vous ne vous servez de votre véhicule pour tous les usages.",
        specificity: false,
      },
    ],
    vehicleUsageHeaders: [
      {
        text: "",
        value: "select",
        sortable: false,
      },
      {
        text: "Mode d'usage",
        value: "guarantee",
        sortable: false,
      },
      {
        text: "",
        value: "chip",
        sortable: false,
      },
      {
        text: "",
        value: "information",
        sortable: false,
      },
    ],
    vehicleParkingHeaders: [
      {
        text: "",
        value: "select",
        sortable: false,
      },
      {
        text: "Mode de stationnement",
        value: "guarantee",
        sortable: false,
      },
      {
        text: "",
        value: "chip",
        sortable: false,
      },
      {
        text: "",
        value: "information",
        sortable: false,
      },
    ],
    vehicleUsageMoto: [
      {
        id: 1,
        guarantee: "Trajet privé",
        specificity: false,
      },
      {
        id: 2,
        guarantee: "Trajet domicile travail",
        specificity: false,
      },
      {
        id: 3,
        guarantee: "Trajet professionnel (sauf tournée)",
        specificity: false,
      },
      {
        id: 4,
        guarantee: "Tout déplacement",
        specificity: false,
      },
      {
        id: 5,
        guarantee: "Terrain privé (hors voie publique)",
        specificity: false,
      },
    ],
    vehicleUsageMoreSelected: [],
    vehicleUsageMore: [
      {
        id: 1,
        guarantee: `Usage inférieur à ... Kilomètres`,
        specificity: true,
      },
    ],

    loadingAllGuaranteesSelected: false,
    gtest: [],
    allGuaranteesSelected: false,
    franchiseChoice: [],
    franchiseSelected: "",
    complementaryGuarantees: [],
    complementaryGuaranteeSelected: "",
    othersGuarantees: [],
    othersGuaranteeSelected: "",
    autoStepper: 1,
    borrowerStepper: 1,

    guaranteesFullForDifference: [],
    autoMainCategory: ["Tiers simple", "Tiers étendu"],
    autoMainCategorySelected: "",
    companySelected: "",
    guaranteeKeep: [],
    guaranteesDontKeep: [],
    helpItems: [
      "Hors politique de souscription",
      "Garanties et capitaux sous risques limites pour mon activité",
      "Augmentation tarifaire ou résiliation pour sinistralité",
      "Prix trop important",
      "Autre motif",
    ],
    helpReason: "",
    reason: "",
    siretNotFound: false,
    siretNotFoundMessage: "",

    insurerSelected: [],
    zipClientOK: false,
    radioGroup: 1,
    valueRadioInsurer: "",
    show: false,
    insurersNameForProduct: [],
    resume: [],
    productName: "",
    productNameText: "",

    // * General data *
    agentFor: null,
    siret: null,
    siretCounter: 0,
    dataPappers: false,
    pappersInformations: [],
    typologieClient: null,
    drawer: true,
    mini: true,

    btnNext: 0,

    userState: null,
    /* bad rules */

    productsArray: [],
    guaranteesProposed: [],
    guaranteesKeeped: [],
    guaranteesCopy: [],

    //? CLIENT INFORMATIONS :
    radios: null,

    directorsNames: [],
    firstnameClient: null,
    lastnameClient: null,
    genderClient: "Monsieur",
    companyNameClient: null,
    siretClient: null,
    apeCodeClient: null,
    descriptionActivityClient: null,
    zipClient: "",
    streetClient: null,
    cityItems: [],
    genderItems: ["M", "Mme"],
    nationalityClient: null,
    addressClient: null,
    cityClient: null,
    phoneNumberClient: null,

    //? RULES CONFIGURATION
    isLoading: false,
    //? RULES FOR STEP 2 - PRO
    /***  Règles de validation ***/
    rulesInformation: true,
    validityFormClientInformations: false,
    lastnameClientRules: false,
    firstnameClientRules: false,
    genderClientRules: false,
    cityRules: [v => !!v || "Une ville est requise"],
    addressRules: [v => !!v || "Une adresse valide est requise"],
    civilityRules: [v => !!v || "Une civilitée est requise"],
    firstnameRules: [v => !!v || "Un prénom est requis"],
    lastnameRules: [v => !!v || "Un nom est requis"],
    emailRules: [
      v => !!v || "L'adresse électronique est requise",
      v => /.+@.+\..+/.test(v) || "L'adresse électronique doit être valide",
    ],
    phoneNumberRules: [
      v => !!v || "Numéro de téléphone requis",
      v => /^0[1-9]([-. ]?[0-9]{2}){4}$/.test(v) || "Numéro de téléphone doit être valide",
    ],

    //? SUCCESS MESSAGES - PART
    successSelectCity: false,
    successSelectCityMessage: null,
    errorSelectCity: false,
    errorSelectCityMessage: null,

    //? STEPPERS CONFIGURATION */
    e1: 1,
    e2: 1,
    steps2: 3,
    steps: 9,
    steppersInformations: [
      {
        name: "Étape 1",
        description: "Typologie client",
        rule: true,
      },
      {
        name: "Étape  2",
        description: "Informations client",
        rule: true,
      },
      // { name: "Étape  3", description: "Nature du conseil", rule: true },
      {
        name: "Étape 3",
        description: "Produit",
        rule: true,
      },
      {
        name: "Étape  4",
        description: "Découverte client",
        rule: true,
      },
      {
        name: "Étape 5",
        description: "Premier conseil",
        rule: true,
      },
      {
        name: "Étape 6",
        description: "Compagnies solicitées",
        rule: true,
      },
      {
        name: "Étape 7",
        description: "Injection des devis",
        rule: true,
      },
      // { name: "Étape  7", description: "", rule: true },
      {
        name: "Étape finale",
        description: "Génération du document",
        rule: true,
      },
    ],
    progressionPdfProgressBar: 0,
    loader: null,
    loading: false,
    loadingData: false,
    /* -------------- */

    //? VALIDATION STEPPERS
    validationStep1: false,
    selection: 1,
    completeFinish: false,

    /*  --------------*/
  }),
  components: {
    Loading,
  },
  watch: {
    // zipClient(val) {
    //   if (val != null) this.tab = 0
    //   else this.tab = null
    // },
    search(val) {
      if (val.length === 5) {
        try {
          this.isLoading = true

          fetch("https://geo.api.gouv.fr/communes?codePostal=" + val)
            .then(res => res.clone().json())
            .then(res => {
              this.cityItems = res.map(item => item.nom)
              this.customerData.zip = val
            })
            .catch(err => {
              console.log(err)
            })
            .finally(() => (this.isLoading = false))

          // for (let index = 0; index < data.length; index++) {
          //   this.cityItems.push(data[index].nomCommune)
          // }

          this.successSelectCity = true
          this.successSelectCityMessage = `Nous avons trouvé ${data.length} villes ou communes ayant comme code postal ${val}`
        } catch (e) {
          this.errorSelectCity = true
          this.errorSelectCityMessage = "Une erreur est survenue"
        }
      } else {
        this.errorSelectCity = true
        this.errorSelectCityMessage = "Le code postal doit être composé de 5 chiffres"
      }
    },
    searchStreet(val) {
      try {
        this.isLoading = true

        fetch("https://api-adresse.data.gouv.fr/search/?q=" + val)
          .then(res => res.clone().json())
          .then(res => {
            this.cityItems = res.map(item => item.nom)
            this.customerData.zip = val
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))

        // for (let index = 0; index < data.length; index++) {
        //   this.cityItems.push(data[index].nomCommune)
        // }

        this.successSelectCity = true
        this.successSelectCityMessage = `Nous avons trouvé ${data.length} villes ou communes ayant comme code postal ${val}`
      } catch (e) {
        this.errorSelectCity = true
        this.errorSelectCityMessage = "Une erreur est survenue"
      }
    },
    loaderDataSave() {
      const l = this.loaderDataSave
      this[l] = !this[l]

      setTimeout(() => (this[l] = false), 3000)

      this.loaderDataSave = null
    },
    borrowerLoanInsuredDialog(val) {
      val || this.closeLoanInsured()
    },
    borrowerLoanInsuredGuaranteesDialog(val) {
      val || this.closeLoanInsuredGuarantees()
    },
    borrowerLoanDialog(val) {
      val || this.close()
    },
    loader() {
      const l = this.loader
      this[l] = !this[l]

      setTimeout(() => (this[l] = false), 3000)

      this.loader = null
    },
    otherAddress() {
      this.cityItems = []
    },
  },
  computed: {
    equipmentTotal() {
      let total = 0

      this.itemsEquipementsSelected.forEach(item => {
        total += parseInt(item.amount)
      })

      return total
    },

    fromDateDisp() {
      return this.fromDateVal
      // format date, apply validations, etc. Example below.
      // return this.fromDateVal ? this.formatDate(this.fromDateVal) : "";
    },
    formTitle() {
      return this.editedIndex === -1 ? "Ajout de prêt" : "Modification de prêt"
    },
    formTitleLoanInsured() {
      return this.editedLoanInsuredIndex === -1 ? "Ajout d'un assuré" : "Modification d'un assuré"
    },
    isDisabled() {
      if (this.zipClient.length < 5) return (this.zipClientOK = true)
    },
  },

  mounted() {
    // document.addEventListener("keydown", event => {
    //   if (event.ctrlKey && event.key === "s") {
    //     event.preventDefault() // empêcher le comportement par défaut (enregistrer la page)
    //     this.saveDataAsDraft(this.currentDraftId, false, false)
    //   }
    // })
    // window.addEventListener("beforeunload", event => {
    //   if (this.hasUnsavedChanges) {
    //     event.preventDefault()
    //     event.returnValue = "" // cette ligne est nécessaire pour afficher le message dans certains navigateurs
    //   }
    // })
  },

  async created() {
    this.agentFor = localStorage.getItem("agent_for")
    this.fetchBanks()

    indexedDB.databases().then(dbs => {
      if (dbs.length === 0) {
      } else {
        this.existingDrafts = true
        this.updateDrafts()
      }
    })
    this.getAgencyPicture()
  },
  methods: {
    getAgencyPicture() {
      let url
      try {
        let agency = localStorage.getItem("agency").toLowerCase().replace(/ /g, "_")
        let pictureProfilPng = `${config.ged}/${agency}/agency/profil.png?${Date.now()}`
        let pictureProfilJpg = `${config.ged}/${agency}/agency/profil.jpg?${Date.now()}`

        let img = new Image()
        img.onload = () => {
          url = img.src

          fetch(url)
            .then(response => response.arrayBuffer())
            .then(buffer => {
              this.agencyPictureProfil = btoa(
                new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), ""),
              )
              // Use the base64Data as needed (e.g., assign it to a variable or display it)
            })
            .finally(() => {
              console.log(this.agencyPictureProfil)
            })
            .catch(error => {
              console.error("Error fetching image:", error)
            })
        }
        img.onerror = () => {
          url = null
        }

        // Test pour l'image .png
        img.src = pictureProfilPng

        // Si l'image .png n'est pas disponible, tester pour l'image .jpg
        setTimeout(() => {
          if (url == null) {
            img.src = pictureProfilJpg
            fetch(url)
              .then(response => response.arrayBuffer())
              .then(buffer => {
                this.agencyPictureProfil = btoa(
                  new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), ""),
                )
                // Use the base64Data as needed (e.g., assign it to a variable or display it)
              })
              .catch(error => {
                console.error("Error fetching image:", error)
              })
          }
        }, 500)
      } catch (error) {
        url = null
      }

      return
    },
    async uploadFile(file) {
      let realSize = this.formatBytes(file.size)

      if (file.size > 1000000) {
        this.$toast.error(
          `Votre fichier est trop volumineux (${realSize}). \n Veuillez choisir un fichier de moins de 1 Mo.`,
          {
            position: "bottom-right",
            timeout: 3000,
          },
        )
      } else {
        switch (file.type) {
          case "application/pdf":
          case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            const id = localStorage.getItem("user_id")
            const username = localStorage.getItem("complete_name").replace(" ", "-")
            const formData = new FormData()
            formData.append("document", file)
            formData.append("documentName", file.name)
            formData.append("username", username.toLowerCase().replace(" ", ""))
            formData.append("agencyName", localStorage.getItem("agency").toLowerCase())
            formData.append("place", "customer")
            formData.append("customer", `${this.customerData.lastname} ${this.customerData.firstname}`)

            try {
              fetch(`${config.apiUri}/upload`, {
                method: "POST",
                body: formData,
              })
                .then(res => {
                  if (res.ok) {
                    this.$toast.success(`Votre fichier a bien été uploadé.`, {
                      position: "bottom-right",
                      timeout: 3000,
                    })
                    // if (this.estimate === "conseil") {
                    //   this.insurerFileSelected.documents.adviceUpload = true
                    // } else {
                    //   this.insurerFileSelected.documents.wishUpload = true
                    // }
                  } else {
                    alert("Erreur lors de l'upload de l'image")
                  }
                })
                .finally(() => {})
            } catch (err) {
              console.error(err)
              alert("Erreur lors de l'upload de l'image")
            }

            break
          default:
            this.fileSelected = false
            this.$toast.error(
              `Votre fichier n'est pas au bon format. \n Veuillez choisir un fichier au format pdf ou docx.`,
              {
                position: "bottom-right",
                timeout: 3000,
              },
            )
        }
      }
    },
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes"

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
    readFile(file, index) {
      this.$set(this.dialogFile, index, true)
      let source = URL.createObjectURL(file)
      this.$set(this.selectedDocumentUrl, index, source)
      console.log(this.selectedDocumentUrl)
      console.log(this.dialogFile)
    },

    openFileExplorer(insurer) {
      const input = document.createElement("input")
      this.insurerFileSelected = insurer

      input.type = "file"
      // only word docx pdf
      input.accept = ".docx,.pdf"
      input.multiple = true
      input.addEventListener("change", event => {
        insurer.documents.push(...event.target.files)

        console.log(insurer.documents)
        // for (let i = 0; i < event.target.files.length; i++) {
        //   const documente = event.target.files[i]
        //   const iframe = document.createElement("iframe")
        //   // iframe width
        //   iframe.width = "100%"

        //   iframe.src = URL.createObjectURL(documente)
        //   document.getElementById(`${insurer.name}`).appendChild(iframe)
        // }
      })
      input.click()
    },

    uploadAllFiles() {
      let logs = []
      this.insurerSelected.forEach(insurer => {
        insurer.documents.forEach(file => {
          logs.push({
            log: `Upload du fichier ${file.name}`,
            dateTime: new Date().toLocaleString(),
            type: "save",
          })
          this.uploadFile(file)
        })
      })
      logs.push({
        log: `Fin`,
        dateTime: new Date().toLocaleString(),
        type: "finish",
      })
      this.saveDataAsDraft(this.currentDraftId, true, true, logs)
    },

    putDraft(item) {
      this.workingDraft(item.id)
    },

    searchByClientName(value, search, item) {
      // verify if item.customerData.lastnameClient contains the search string
      return (
        item.customerData.lastnameClient.toLowerCase().includes(search.toLowerCase()) ||
        item.customerData.firstnameClient.toLowerCase().includes(search.toLowerCase())
      )
    },
    getCheckboxColor(item) {
      // verify if item is in the array of checked items and return the color
      // patern is : [{"civility": "M", "name":"FRICHOT ROMAN"}]

      return this.signatory.includes() ? "EoleGreen" : ""
    },
    createAdvice() {
      this.mainVehicle.forEach(element => {
        this.customerGuarantees.wish.mainGuarantees.push(element.name)
        this.customerGuarantees.advice.mainGuarantees.push(element.name)
      })
      if (this.itemsEquipementsSelected.length > 0) {
        this.customerGuarantees.advice.additionalGuarantees.push("Garantie aménagements du véhicule")
      }

      if (this.vehicleProcurementSelected === "Via load/ldd" || this.vehicleProcurementSelected === "Via crédit-bail") {
        this.customerGuarantees.advice.additionalGuarantees.push("Garantie perte financière")
      }

      this.customerDiscoveryVehicle.trailers.forEach(element => {
        if (element.weight === "< 500 kg") {
          this.customerGuarantees.advice.trailersGuarantees.push("RC Seule")
        }
        if (element.hasMerchandises) {
          this.customerGuarantees.advice.trailersGuarantees.push("Marchandises transportées")
        }
      })

      if (this.customerDiscoveryVehicle.equipments.equipmentCovering) {
        this.customerGuarantees.advice.push("Autocollant")
      }
    },
    async updateDrafts() {
      this.drafts = []
      let data = await this.readDataIndexedDb()
      this.drafts = data

      //sort by lastUpdate
      this.drafts.sort((a, b) => {
        return new Date(b.lastUpdate) - new Date(a.lastUpdate)
      })

      this.drafts.forEach((draft, index) => {
        draft.lastUpdate = moment(draft.lastUpdate).calendar()
        draft.documents.documentSendDate = moment(draft.documents.documentSendDate).calendar()
      })
    },
    workingDraft(id) {
      let index = this.drafts.findIndex(x => x.id === id)
      this.currentDraftId = id
      this.existingDrafts = false
      // this.genderClient = this.drafts[index].customerData.genderClient
      // this.firstnameClient = this.drafts[index].customerData.firstnameClient
      // this.lastnameClient = this.drafts[index].customerData.lastnameClient
      this.radios = this.drafts[index].customerData.typology
      this.productName = this.drafts[index].productName
      this.e1 = this.drafts[index].step
      this.customerData = this.drafts[index].customerData
      this.customerDiscoveryVehicle = this.drafts[index].customerDiscoveryVehicle
      this.insurerSelected = this.drafts[index].insurerSelected
      this.insurerSelected.forEach(insurer => {
        this.dialogFile.push(false)
      })
      this.lastLogs = this.drafts[index].timeline
      this.fetchGuaranteesByProduct(this.productName)
      this.fetchInsurersByProductName(this.productName)
      // this.pdfMake()
      this.e1 = 6
    },

    deleteDraft(id) {
      let request = window.indexedDB.open("anavel", 1)
      request.onsuccess = function (event) {
        let db = event.target.result
        let transaction = db.transaction(["customers"], "readwrite")
        let objectStore = transaction.objectStore("customers")
        let request = objectStore.delete(id)

        request.onerror = function (event) {
          console.log("error")
        }
      }
    },
    initializeAllData() {
      this.radios = null
      this.genderClient = null
      this.firstnameClient = null
      this.lastnameClient = null
      this.productName = null
      this.e1 = 1
      this.autoStepper = 1
      this.customerDiscoveryVehicle = {
        vehicleSinister: false,
        vehicleKilometersByYear: 0,
        vehicleValueKnown: false,
        licenceJunior: false,
        termination: false,
        licenceCancel: false,
        licenceCancelTime: 0,
        sentenced: false,
        vehicleValue: 0,
        vehiclePrice: 0,
        vehicleAge: 0,
        vehicleModelKnown: false,
        vehicleModel: "",
        vehicleCollectible: false,
        vehicleCollectibleExpert: false,
        vehicleCollectibleExpertPrice: 0,
        funding: {
          duration: 0,
          bank: "",
          amount: 0,
        },
        trailerOwner: false,
        trailersLent: false,
        trailers: [],
        equipments: [],
      }
    },
    saveDataAsDraft(currentDraftId, adviceSend, documentSendValue, log) {
      this.loaderDataSave = "loadingDataSave"
      const date = new Date()
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, "0")
      const day = String(date.getDate()).padStart(2, "0")
      const hours = String(date.getHours()).padStart(2, "0")
      const minutes = String(date.getMinutes()).padStart(2, "0")
      const seconds = String(date.getSeconds()).padStart(2, "0")
      const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`

      this.customerDiscoveryVehicle.equipments = this.itemsEquipementsSelected
      let data = this.customerDiscoveryVehicle

      let customerInformations = this.customerData
      customerInformations.typology = this.radios

      const step = this.e1
      const productName = this.productName

      let insurerSelected = this.productSelected.insurers

      //check if log is an array
      if (Array.isArray(log)) {
        log.forEach(element => {
          this.lastLogs.push(element)
        })
      } else {
        this.lastLogs.push(log)
      }
      let newLogs = this.lastLogs

      if (currentDraftId === null) {
        // Ouvre une connexion vers la base de données
        let request = indexedDB.open("anavel", 1)
        request.onerror = function (event) {
          console.error("Cannot open database", event.target.error)
        }
        request.onupgradeneeded = function (event) {
          // Crée la table "customers" si elle n'existe pas
          let db = event.target.result
          let test = db.objectStoreNames.contains("customers")

          if (!db.objectStoreNames.contains("customers")) {
            let objectStore = db.createObjectStore("customers", {
              keyPath: "id",
              autoIncrement: true,
            })
          } else {
          }
        }
        request.onsuccess = function (event) {
          let db = event.target.result

          // Vérifie si la table "customers" existe
          if (!db.objectStoreNames.contains("customers")) {
            return
          }

          // Obtient l'objet IDBObjectStore pour la table "customers"
          let transaction = db.transaction(["customers"], "readwrite")
          let objectStore = transaction.objectStore("customers")

          let addRequest = objectStore.add({
            customerData: customerInformations,
            customerDiscoveryVehicle: data,
            productName: productName,
            step: step,
            lastUpdate: formattedDate,
            insurerSelected: insurerSelected,
            adviceSend: adviceSend,
            documents: {
              documentSend: documentSendValue,
              documentSendDate: formattedDate,
            },
            timeline: newLogs,
          })

          addRequest.onerror = function (event) {}

          // Ferme la transaction
          transaction.oncomplete = function () {}
        }
      } else {
        // Ouvre une connexion vers la base de données
        let request = indexedDB.open("anavel", 1)
        request.onerror = function (event) {}
        request.onsuccess = function (event) {
          let db = event.target.result

          // Vérifie si la table "customers" existe
          if (!db.objectStoreNames.contains("customers")) {
            return
          }

          // Obtient l'objet IDBObjectStore pour la table "customers"
          let transaction = db.transaction(["customers"], "readwrite")
          let objectStore = transaction.objectStore("customers")

          // Récupère la ligne avec l'ID "123"
          let getRequest = objectStore.get(currentDraftId)
          getRequest.onerror = function (event) {}
          getRequest.onsuccess = function (event) {
            let customer = event.target.result

            // Si la ligne existe, la modifie
            if (customer) {
              let putRequest = objectStore.put({
                customerData: customerInformations,
                customerDiscoveryVehicle: data,
                productName: productName,
                step: step,
                lastUpdate: formattedDate,
                insurerSelected: insurerSelected,
                adviceSend: adviceSend,
                documents: {
                  documentSend: documentSendValue,
                  documentSendDate: formattedDate,
                },
                timeline: newLogs,
              })

              let removeData = db
                .transaction(["customers"], "readwrite")
                .objectStore("customers")
                .delete(currentDraftId)

              putRequest.onerror = function (event) {
                console.error("Cannot update customer", event.target.error)
              }
              putRequest.onsuccess = function (event) {}
            }
            // Si la ligne n'existe pas, affiche une erreur
            else {
            }
          }
        }
      }
    },
    readDataIndexedDb() {
      return new Promise((resolve, reject) => {
        const request = indexedDB.open("anavel", 1)

        request.onsuccess = function (event) {
          const db = event.target.result
          const transaction = db.transaction(["customers"], "readonly")
          const objectStore = transaction.objectStore("customers")
          const request = objectStore.getAll()

          request.onerror = function (event) {
            reject("Erreur lors de la récupération de l'objet " + event)
          }

          request.onsuccess = function (event) {
            resolve(request.result)
          }
        }
      })
    },
    analyzeAdvice() {},
    analyzeTrailerGuarantee() {
      // verify in trailer items if weight is more than > 500 kg if one is true then guarantee is obligatory
      let RCguarantee = false
      this.customerDiscoveryVehicle.trailers.forEach(trailer => {
        if (trailer.weight === "< 500 kg") {
          guarantee = true
        }
      })

      return RCguarantee
    },
    analyzeTrailerLicence(trailer) {
      // this.customerDiscoveryVehicle.trailers[id].test = "OK !"

      switch (trailer.weight) {
        case "< 500 kg":
          switch (trailer.sumVehicleTrailer) {
            case "< 3500 kg":
              trailer.licence = "B"
              break
            case "[3500 - 4250]kg":
              trailer.licence = "B96"
              break
            case "> 4250 kg":
              trailer.licence = "BE"
              break
          }
          break
        case "[500 - 750]kg":
          switch (trailer.sumVehicleTrailer) {
            case "< 3500 kg":
              trailer.licence = "B"
              break
            case "[3500 - 4250]kg":
              trailer.licence = "B96"
              break
            case "> 4250 kg":
              trailer.licence = "BE"
              break
          }
          break
        case "< 750kg > 3500kg":
          switch (trailer.sumVehicleTrailer) {
            case "< 3500 kg":
              trailer.licence = "B"
              break
            case "[3500 - 4250]kg":
              trailer.licence = "B96"
              break
            case "> 4250 kg":
              trailer.licence = "BE"
              break
          }
          break
      }

      if (trailer.weight === "< 500 kg" && trailer.sumVehicleTrailer === "< 3500 kg") {
        trailer.licence = "B"
      } else if (trailer.weight === "[500 - 750]kg" && trailer.sumVehicleTrailer === "> 3500 kg") {
        trailer.licence = "B + E"
      } else if (trailer.weight === "> 500 kg" && trailer.sumVehicleTrailer === "< 3500 kg") {
        trailer.licence = "B + E"
      } else if (trailer.weight === "> 500 kg" && trailer.sumVehicleTrailer === "> 3500 kg") {
        trailer.licence = "B + E"
      }
    },
    addNewTrailer() {
      this.customerDiscoveryVehicle.trailers.push({
        id: this.customerDiscoveryVehicle.trailers.length + 1,
        weight: null,
        sumVehicleTrailer: 0,
        licence: "",
        hasMerchandises: false,
        merchandises: [],
        disable: false,
        information: "",
      })
    },
    insertMerchandises(item, merchand) {
      item.merchandises.push(merchand)
      this.merchand = ""

      this.$toast.info(`Vous avez ajouté : ${merchand} comme marchandise à la remorque n°${item.id}.`, {
        position: "bottom-right",
        timeout: 3000,
      })
    },
    analyzeVehiclePrice() {
      if (this.customerDiscoveryVehicle.vehiclePrice < 4000) {
        this.pushMain("Tiers simple")
      } else {
        this.pushMain("Tous risques")
      }
    },
    verifyEmpty(item) {
      if (item === null || item === undefined || item === "") {
        item = 0
      } else {
      }
    },
    searchBanks(e) {
      if (!this.searchItem) {
        this.banks = this.banksCopy
      } else {
        this.banks = this.banksCopy.filter(fruit => {
          return fruit.name.toLowerCase().includes(this.searchItem.toLowerCase())
        })
      }
    },
    removeDirector(director) {
      this.pappersInformations[0].representants.splice(this.pappersInformations[0].representants.indexOf(director), 1)
    },
    addDirector() {
      this.pappersInformations[0].representants.push({
        nom: "",
        prenom_usuel: "",
        prenom: "",
      })
    },
    borrowerStep4() {
      this.borrowerStepper = 4

      this.loan.forEach(lo => {
        lo.loanInsured = structuredClone(this.insured)
      })

      this.loanStepsCount = this.loan.length
    },

    close() {
      this.borrowerLoanDialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)

        this.editedIndex = -1
      })
    },
    closeLoanInsured() {
      this.borrowerLoanInsuredDialog = false
      this.$nextTick(() => {
        this.editedLoanInsuredItem = Object.assign({}, this.defaultLoanInsuredItem)

        this.editedLoanInsuredIndex = -1
      })
    },

    closeLoanInsuredGuarantees() {
      this.borrowerLoanInsuredGuaranteesDialog = false
    },
    saveGuarantees(idLoan, idInsured) {
      this.loan[idLoan].loanInsured[idInsured].guarantees = structuredClone(this.guarantees)

      this.insured.push(this.editedLoanInsuredItem)

      //this.post({ name: this.editedItem.name, idClientTypology: this.editedItem.idClientTypology })

      this.closeLoanInsuredGuarantees()
    },
    saveInsured() {
      if (this.editedLoanInsuredIndex > -1) {
        Object.assign(this.insured[this.editedLoanInsuredIndex], this.editedLoanInsuredItem)
      } else {
        this.editedLoanInsuredItem.birthdate = moment(this.fromDateDisp).format("DD/MM/YYYY")
        this.defaultLoanInsuredItem.num++
        this.defaultLoanInsuredItem.id++

        this.insured.push(this.editedLoanInsuredItem)

        //this.post({ name: this.editedItem.name, idClientTypology: this.editedItem.idClientTypology })
      }
      this.closeLoanInsured()
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.loan[this.editedIndex], this.editedItem)
      } else {
        this.defaultItem.numLoan++
        this.defaultItem.id++
        this.loan.push(this.editedItem)

        // this.editedItem.loanInsured = this.insured

        //this.post({ name: this.editedItem.name, idClientTypology: this.editedItem.idClientTypology })
      }
      this.close()
    },

    getTodayFormatFile() {
      const d = new Date()
      let day
      let month
      let year = d.getFullYear()

      if (d.getDate() < 10) {
        day = "0" + d.getDate()
      } else {
        day = d.getDate()
      }

      month = d.getMonth() + 1
      if (month < 10) {
        month = "0" + month
      } else {
        month = month
      }

      return day + "-" + month + "-" + year
    },
    getToday() {
      const d = new Date()
      let day
      let month
      let year = d.getFullYear()

      if (d.getDate() < 10) {
        day = "0" + d.getDate()
      } else {
        day = d.getDate()
      }

      month = d.getMonth() + 1
      if (month < 10) {
        month = "0" + month
      } else {
        month = month
      }

      return day + "/" + month + "/" + year
    },
    addInsurerSelected(insurer) {
      if (this.insurerSelected.length === 0) {
        this.insurerSelected.push(insurer)
      } else {
        this.insurerSelected.forEach(insurerSelect => {
          if (insurer.name === insurerSelect.name) {
            // delete insurer in this.insurerSelected if it's already in the array
            this.insurerSelected.splice(this.insurerSelected.indexOf(insurerSelect), 1)
          } else {
            if (!this.insurerSelected.includes(insurer)) {
              //checking weather array contain the id
              this.insurerSelected.push(insurer) //adding to array because value doesnt exists
            }
          }
        })
      }
    },
    validateFormClientInformations() {
      this.validityFormClientInformations = true
      if (this.$refs.formClientInformations.validate()) {
        this.e1++
        console.log(this.customerData)
      }
    },
    async fetchInsurersByProductName(productName) {
      this.insurersNameForProduct = []
      // this.insurerSelected = []
      // let agent = localStorage.getItem("agent_for")

      // try {
      //   let headers = new Headers()

      //   headers.append("Content-Type", "application/json")
      //   headers.append("Accept", "application/json")
      //   headers.append("Origin", "*")

      //   const response = await fetch(
      //     `${config.apiUri}/products?productName=${productName}&clientTypology=${this.radios}&agent=${agent}`,
      //     {
      //       mode: "cors",
      //       method: "GET",
      //       headers: headers,
      //     },
      //   )
      //   const data = await response.json()
      //   let i = 0

      //   data.forEach(element => {
      //     i++
      //     this.insurersNameForProduct.push({
      //       id: i,
      //       name: element.name,
      //       website_url: element.website_url,
      //       documents: [],
      //     })
      //   })

      this.insurerSelected = this.productSelected.insurers

      // this.insurersNameForProduct.forEach(insurer => {
      //   // if insurerSElect don't include insurer check by name

      //   if (!this.insurerSelected.includes(insurer)) {
      //     if (insurer.name === agent) {
      //       this.insurerSelected.push(insurer)
      //     }
      //   }
      // })
      // } catch (e) {
      //   console.log(e)
      // }
    },

    async fetchBanks() {
      this.banks = []
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(`${config.apiUri}/banks`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        data.banks.forEach(element => {
          this.banks.push(element)
        })
        this.banksCopy = [...this.banks]
      } catch (e) {
        console.log(e)
      }
    },

    pdfMake() {
      let documentData = []
      //* Footer du pdf généré
      var footerText =
        "La SARL C2T Assurances, société au capital social de 445 900 €, agit en qualité d’agent général d’assurance GAN immatriculée auprès de l’ORIAS sous le numéro 12 065 563 (www.orias.fr) et est soumise à l’Autorité de Contrôle Prudentiel et de Résolution (ACPR) 4 place de Budapest, CS 92459 75436 Paris cedex 09. La SARL C2T Assurances est soumise à une obligation contractuelle d’exclusivité avec GAN ASSURANCES et est autorisée à réaliser des opérations de courtage accessoires. La SARL C2T Assurances ne dispose pas de lien financier avec GAN ASSURANCES.Numéro de RCS: 538 591 439 ; Garantie Financière et assurances de Responsabilité Civile Professionnelle conformes au code des assurances (contrat numéro 45880, chez l’assureur CGPA). En cas de réclamation, contactez l’agence au numéro suivant : 02 99 73 00 27 ou par mail : contact@eole-assurance.bzh Médiateur : www.mediateur-geia.fr. Par voie électronique, à l’adresse suivante : contact@mediateur-geia.fr "

      var dd = {
        info: {
          title: `LDDC-${this.productName}-${this.getTodayFormatFile()}`,
        },
        footer: function (currentPage, pageCount) {
          if (currentPage < 2 || currentPage > 2) {
            return {
              height: 300,
              // margin: [10, 10, 10, 10],
              // columns: [
              //   { text: footerText, alignment: "left", fontSize: 5 },
              //   { text: pageCount, fontSize: 10 },
              // ],
              margin: [10, 10, 10, 10],

              table: {
                widths: ["*", "auto"],

                body: [
                  [
                    {
                      text: footerText,
                      fontSize: 5,
                      alignment: "justify",
                    },
                    {
                      text: `${currentPage}/${pageCount}`,
                      fontSize: 7,
                    },
                  ],
                ],
              },
              layout: "noBorders",
            }
          } else {
            return ``
          }
        },
        content: [
          //? information sur notre proposition d’offre de services
          {
            alignment: "center",
            image: imageData.anavel_logo,
            width: 100,
          },
          "\n",
          "\n",
          "\n",
          "\n",
          {
            alignment: "justify",
            columns: [
              {
                //! A changer plus tard dynamiquement en fonction du nom de l'entreprise ayant l'abonnement
                //! à Mes Services Assurances
                text: `C2T Assurances\n 1 Avenue des prunus\n35270 Combourg`,
              },
            ],
          },
          "\n",

          {
            columns: [
              {},
              {},
              {
                text: `${
                  this.radios === "Particulier"
                    ? `${this.customerData.civility} ${this.customerData.lastname} ${this.customerData.firstname}\r${this.customerData.street}\r
                  ${this.customerData.zip} ${this.customerData.city}`
                    : `${this.pappersInformations[0].nom_entreprise}\r
                  ${this.pappersInformations[0].siege.adresse_ligne_1}\r
                  ${this.pappersInformations[0].siege.code_postal} ${this.pappersInformations[0].siege.ville}`
                }   
                  `,
              },
            ],
          },
          "\n",
          "\n",
          "\n",
          "\n",
          {
            text: `Objet : information sur notre proposition d’offre de services \n`,
            bold: true,
          },
          "\n",

          "\n",
          {
            text: `Vous nous avez sollicité pour vous accompagner dans l’étude de votre risque ${this.productName} et dans l’élaboration d’un programme d’assurance en cohérence avec vos besoins.`,
            style: "test",
          },
          "\n",
          {
            text: `A cette fin, nous avons dans un premier temps sollicité comme notre mandat nous l’impose, la compagnie GAN ASSURANCES afin de vous proposer une couverture adéquate. Néanmoins, les réponses apportées par cette dernière n'ont pas retenu votre attention.\n`,
            style: "test",
          },
          "\n",
          {
            text: `Pour ce faire, vous nous avez demandé de trouver une solution auprès de nos autres partenaires assureurs dans le cadre de notre activité de courtage accessoire.\n`,
            style: "test",
          },
          "\n",
          {
            text: `A cette fin, nous vous remercions de nous avoir confié les mandats correspondants.`,
            style: "test",
          },
          "\n",
          {
            text: `${
              this.radios === "Particulier"
                ? `Veuillez agréer ${this.genderClient} ${this.lastnameClient} ${this.firstnameClient}, l’expression de nos salutations respectueuses.`
                : `Veuillez agréer ${this.signatory.map(element => {
                    return " " + element.civility + ". " + element.name
                  })}, l’expression de nos salutations respectueuses.`
            }`,
            style: "test",
          },
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          {
            alignment: "justify",
            columns: [
              {},
              {},
              {
                text: `L'équipe C2T Assurances`,
                style: "test",
              },
            ],
            pageBreak: "after",
          },

          //? Ordre de placement en courtage

          "\n",
          "\n",
          "\n",
          "\n",
          {
            alignment: "justify",
            columns: [
              {
                text: `${
                  this.radios === "Particulier"
                    ? `${this.customerData.civility} ${this.customerData.lastname} ${this.customerData.firstname}\r${this.customerData.street}\r
                  ${this.customerData.zip} ${this.customerData.city}`
                    : `${this.pappersInformations[0].nom_entreprise}\r
                  ${this.pappersInformations[0].siege.adresse_ligne_1}\r
                  ${this.pappersInformations[0].siege.code_postal} ${this.pappersInformations[0].siege.ville}`
                }   
                  `,
              },
            ],
          },
          "\n",
          {
            alignment: "justify",
            columns: [
              {},
              {},
              {
                //! A changer plus tard dynamiquement en fonction du nom de l'entreprise ayant l'abonnement
                //! à Mes Services Assurances
                text: `C2T ASSURANCES\nCourtier en Assurance\n 1 Avenue des prunus\n35270 Combourg`,
              },
            ],
          },
          "\n",
          "\n",
          "\n",
          "\n",
          {
            text: `Objet : Mandat d’étude et de placement en courtage accessoire concernant votre assurance "${this.productName}"`,
            bold: true,
          },
          "\n",
          "\n",
          {
            text: `${
              this.radios === "Particulier"
                ? `Je soussigné, ${this.genderClient} ${this.lastnameClient} ${this.firstnameClient} certifie avoir été informé des solutions proposées GAN ASSURANCES et mandate :`
                : `${this.signatory.length > 1 ? `Nous soussignons` : `Je soussigné`}, ${this.signatory.map(element => {
                    return " " + element.civility + ". " + element.name
                  })}, ${this.signatory.length > 1 ? `dirigeants` : `dirigeant`} de ${
                    this.pappersInformations[0].nom_entreprise
                  } ${
                    this.signatory.length > 1 ? `certifient` : `certifie`
                  } avoir été informé des solutions GAN ASSURANCES et mandate :`
            }`,
            style: "test",
          },

          "\n",
          {
            text: `C2T ASSURANCES – 1 Avenue du 41ème régiment d’infanterie 35000 Rennes`,
            alignment: "center",
            style: "test",
            bold: true,
          },
          "\n",
          {
            text: `Afin de procéder à l’étude et au placement de mon assurance ${this.productName}\n
               ${
                 this.signatory.length > 1 ? `Nous avons refusé` : `J'ai refusé`
               } la proposition GAN ASSURANCE pour la raison suivante :\n - ${
              this.helpReason === "Autre motif" ? "Autres (préciser) : " + this.reason : this.helpReason
            }`,
            style: "test",
          },
          "\n",
          {
            text: `Le présent ordre annulant tout ordre qui aurait pu être donné antérieurement.`,
            style: "test",
          },
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          {
            alignment: "justify",
            columns: [
              {},
              {},
              {
                //! A changer plus tard dynamiquement en fonction du nom de l'entreprise ayant l'abonnement
                //! à Mes Services Assurances
                text: `Signature\nA Rennes le : ${this.getToday()}\nlu et approuvé.`,
                style: "test",
              },
            ],
            pageBreak: "after",
          },

          {
            alignment: "center",
            image: imageData.anavel_logo,
            width: 100,
          },
          "\n",
          "\n",
          {
            text: "Informations et conseils préalables à la conclusion du contrat d'assurance fournis par C2T ASSURANCES, courtier d'assurance (en application du code des assurances art L. 521-2 et R. 521-1).",
            alignment: "center",
            style: "header",
            bold: true,
          },
          "\n",
          {
            text: "RENSEIGNEMENTS NOUS CONCERNANT\n",
            style: "subheader",
          },
          "\n",
          {
            text: "SARL C2T ASSURANCES courtier d’assurance (code des assurances, art. L 521-2, II, 1°, b) : Nous tenons à votre disposition la liste des entreprises d’assurance avec lesquelles nous travaillons pour l’assurance des risques concernés par votre demande. Si vous souhaitez obtenir une communication de cette liste, il vous suffit de nous adresser un courrier simple à l’adresse suivante : C2T Assurances - 1 Avenue des prunus, 35270 Combourg. Nous sommes rémunérés par l’organisme d’assurances choisi, sous forme de commissions en pourcentage de la prime payée. Immatriculation au registre des intermédiaires en assurance : n° 12 065 563  (site Internet : www.orias.fr).",
            style: "test",
          },
          "\n",
          {
            text: "DECLARATIONS DE SINCERITE DU CLIENT",
            style: "subheader",
          },
          "\n",
          {
            text: `${
              this.radios === "Particulier"
                ? `${this.genderClient} ${this.lastnameClient} ${this.firstnameClient} précise que les déclarations ou réponses qui ont servi de base à l’établissement du présent document sont sincères et exactes et reconnaît avoir pris connaissance du présent document avant la conclusion du contrat d’assurance. Les équipes du cabinet SARL C2T Assurances restent à votre entière disposition pour tous renseignements complémentaires du lundi au vendredi à l’agence située 1 Avenue du 41éme régiment d’infanterie, 35000 RENNES ou par téléphone du lundi au vendredi de 9:00 à 12:00 et de 14:00 à 17:30 au 02 99 54 45 16 ou par mail à l’adresse suivante : contact@eole-assurance.bzh`
                : `${this.signatory.map(element => {
                    return " " + element.civility + ". " + element.name
                  })} représentant de la société ${
                    this.pappersInformations[0].nom_entreprise
                  } précise que les déclarations ou réponses qui ont servi de base à l’établissement du présent document sont sincères et exactes et reconnaît avoir pris connaissance du présent document avant la conclusion du contrat d’assurance. 
                  Les équipes du cabinet C2T Assurances restent à votre entière disposition pour tous renseignements complémentaires les Lundi, Mardi, Jeudi et Vendredi à l’agence située 1 Avenue du 41éme régiment d’infanterie, 35000 RENNES ou par téléphone du lundi au vendredi de 9:00 à 12:00 et de 14:00 à 17:30 au 02 99 54 45 16 ou par mail à l’adresse suivante : servicepro@eole-assurance.bzh`
            }`,
            style: "test",
          },
          "\n",
          {
            text: "RENSEIGNEMENTS VOUS CONCERNANT",
            style: "subheader",
          },
          "\n",
          {
            table: {
              body: [
                [
                  {
                    border: [true, false, false, false],
                    text: `${
                      this.radios === "Particulier"
                        ? `${this.genderClient} ${this.lastnameClient} ${this.firstnameClient}\r
                  ${this.streetClient}\r
                  ${this.zipClient} ${this.cityClient}`
                        : `Raison sociale : ${this.pappersInformations[0].nom_entreprise}\r
                  Adresse : ${this.pappersInformations[0].siege.adresse_ligne_1}\r
                  Code postal et Ville : ${this.pappersInformations[0].siege.code_postal} ${
                            this.pappersInformations[0].siege.ville
                          }\r
                  Activité :  ${this.pappersInformations[0].siege.libelle_code_naf}\r
                  Code NAF :  ${this.pappersInformations[0].siege.code_naf}\r
                  ${this.signatory.length > 1 ? `Représentant légaux` : `Représentant légal`} : ${this.signatory.map(
                            element => {
                              return " " + element.civility + ". " + element.name
                            },
                          )}`
                    }`,
                    style: "test",
                    marginLeft: 10,
                  },
                ],
              ],
            },
            layout: {
              vLineColor: function (i, node) {
                return "#FCC03C"
              },
              hLineColor: function (i, node) {
                return "#FCC03C"
              },
            },
          },
          // {
          //   text: `${
          //     this.radios === "Particulier"
          //       ? `${this.genderClient} ${this.lastnameClient} ${this.firstnameClient}\r
          //         ${this.streetClient}\r
          //         ${this.zipClient} ${this.cityClient}`
          //       : `Raison sociale : ${this.pappersInformations[0].nom_entreprise}\r
          //         Adresse : ${this.pappersInformations[0].siege.adresse_ligne_1}\r
          //         Code postal et Ville : ${this.pappersInformations[0].siege.code_postal} ${
          //           this.pappersInformations[0].siege.ville
          //         }\r
          //         Activité :  ${this.pappersInformations[0].siege.libelle_code_naf}\r
          //         Code NAF :  ${this.pappersInformations[0].siege.code_naf}\r
          //         ${this.signatory.length > 1 ? `Représentant légaux` : `Représentant légal`} : ${this.signatory.map(
          //           element => {
          //             return " " + element.civility + ". " + element.name
          //           },
          //         )}`
          //   }`,
          //   style: "test",
          // },
          "\n",
          {
            text: "MÉDIATION",
            style: "subheader",
          },
          "\n",
          {
            text: `En cas de litige né à l’occasion de votre relation contractuelle avec le courtier et à la suite d’une réclamation écrite qui n’aurait pas aboutie, vous pouvez saisir le Médiateur du Geia : \n
                  - soit par Internet : www.mediateur-geia.fr\n- soit par voie électronique : contact@mediateur-geia.fr\n- soit par voie postale : médiateur agéa solutions Libre réponse 95445 -75681 PARIS CEDEX 14
                  \nVous pouvez également vous adresser à : Autorité de Contrôle Prudentiel et de Résolution on ACPR, 4 Place de Budapest - CS 92459 - 75436 - Paris cedex 09`,
            style: "test",
          },
          "\n",
          {
            text: "VOS BESOINS, NOTRE CONSEIL",
            style: "subheader",
          },
          "\n",
          {
            text: `Lors de nos échanges, nous vous avons fait part de la nécessité de couvrir votre risque «  ${this.productName} ». La garantie que nous vous proposons réduirait les conséquences et s’intègrerait parfaitement dans votre politique de gestion de vos risques.\n
              Ainsi, vous nous avez dument mandaté pour interroger le marché et ce, afin que nous puissions trouver la solution la plus en adéquation à votre besoin et vos objectifs tarifaires.\n
              Après étude de votre situation spécifique et tenant compte des besoins que vous nous avez exprimés, nous vous conseillons la couverture des éléments suivants :`,
            style: "test",
          },
          `${this.productName === "Moto/Scooter" ? "\n" : ""}`,
          {
            text: `${
              this.productName === "Moto/Scooter"
                ? `Vous nous avez avez déclaré être titulaires du/des permis suivants : ${this.licencesSelected.map(
                    el => {
                      return el + " "
                    },
                  )}. \n
              Vous souhaitez garantir une moto/ un scooter de type : ${this.engineSelected}.`
                : ``
            }`,
            style: "test",
          },
          `${this.productName === "Automobile" || this.productName === "Moto/Scooter" ? "\n" : ""}`,

          {
            columns: [],
            pageBreak: `${
              this.productName === "Automobile" || this.productName === "Moto/Scooter" ? "after" : "after"
            }`,
          },
          `${this.productName === "Automobile" ? "\n" : ""}`,
          `${this.productName === "Automobile" ? "\n" : ""}`,
          `${this.productName === "Automobile" ? "\n" : ""}`,

          {
            text: `${
              this.productName === "Automobile" || this.productName === "Moto/Scooter" ? `Usage du véhicule :\r` : ``
            }`,
            bold: true,
          },
          `${this.productName === "Automobile" || this.productName === "Moto/Scooter" ? "\n" : ""}`,
          {
            text: `${
              this.productName === "Automobile" || this.productName === "Moto/Scooter"
                ? `L'assureur doit déterminer la fréquence à laquelle vous utilisez le véhicule et le type de trajet que vous effectuez pour réaliser sa tarification.\n
              Ainsi, vous nous avez précisé que vous utilisiez votre véhicule lors des déplacements suivants : \n${
                this.vehicleUsageSelected
              }.\n
              Vous avez déclaré faire ${this.customerDiscoveryVehicle.vehicleKilometersByYear} kilomètres par an.\n
              ${
                this.customerDiscoveryVehicle.vehicleKilometersByYear < 10000
                  ? `En fonction de l’assureur il est possible de limiter le kilométrage assuré dans l’année et ce afin de réduire l’impact tarifaire. `
                  : ``
              }`
                : ``
            }`,
            style: "test",
          },
          `${this.productName === "Automobile" || this.productName === "Moto/Scooter" ? "\n" : ""}`,
          {
            text: `${
              this.productName === "Automobile" || this.productName === "Moto/Scooter"
                ? `Lieu et le mode de stationnement du véhicule :\r`
                : ``
            }`,
            bold: true,
          },
          `${this.productName === "Automobile" || this.productName === "Moto/Scooter" ? "\n" : ""}`,

          {
            text: `${
              this.productName === "Automobile" || this.productName === "Moto/Scooter"
                ? `Le lieu de stationnement habituel du véhicule impacte également le tarif.\n
              Vous nous avez précisé que le véhicule serait stationné dans les conditions suivantes :
              \n ${this.vehicleParkingSelected}\n
              A l'adresse  : \n ${
                !this.otherAddress
                  ? `${this.streetClient}, ${this.zipClient} ${this.cityClient}`
                  : `${this.otherStreetClient}, ${this.otherZipClient} ${this.otherCityClient}`
              } `
                : ``
            }`,
            style: "test",
          },
          `${this.productName === "Automobile" || this.productName === "Moto/Scooter" ? "\n" : ""}`,
          {
            text: `${
              this.productName === "Automobile" || this.productName === "Moto/Scooter"
                ? `Mode d'acquisition du véhicule :\r`
                : ``
            }`,
            bold: true,
          },
          `${this.productName === "Automobile" || this.productName === "Moto/Scooter" ? "\n" : ""}`,

          {
            text: `${
              this.productName === "Automobile" || this.productName === "Moto/Scooter"
                ? `le mode d'acquisition du véhicule nous impose d'être vigilant quant aux couvertures d'assurances à mettre en place.\n
                        Vous nous avez précisé que le véhicule serait financé de la façon suivante : \n ${
                          this.vehicleProcurementSelected
                        } ${this.guaranteePurchase ? `par financement bancaire en tout ou partie` : ``}  \n
                        Vous vous avez précisé que le véhicule avait une valeur de ${
                          this.customerDiscoveryVehicle.vehiclePrice
                        }€.\n
                        ${
                          this.customerDiscoveryVehicle.vehiclePrice < 4000
                            ? `Nous considérons que la valeur vénale du véhicule ou de réparation n’est pas suffisante  pour envisager une couverture tous risques.
                        A titre d’information le véhicule sera considéré comme économiquement non réparable si le montant de la réparation équivaut à 80% de la valeur à dire d’expert de votre véhicule soit : ${
                          this.customerDiscoveryVehicle * 0.8
                        }€.
                        Cependant nous pouvons vous assurer tous risques sur simple demande.`
                            : ``
                        } ${
                    this.customerDiscoveryVehicle.vehiclePrice > 4000 &&
                    this.customerDiscoveryVehicle.vehiclePrice < 8000
                      ? `Certains de nos clients considèrent que la valeur vénale du véhicule ou de réparation n’est pas suffisante  pour envisager une couverture tous risques et dans ce cas de figure  ils décident d’assurer le véhicule au tiers ou au tiers plus.
          A titre d’information le véhicule sera considéré comme économiquement non réparable si le montant de la réparation équivaut à 80% de la valeur à dire d’expert de votre véhicule soit : ${
            this.customerDiscoveryVehicle.vehiclePrice * 0.8
          }€.
          `
                      : ``
                  }
          ${
            this.customerDiscoveryVehicle.vehiclePrice > 8000
              ? `La valeur vénale de votre véhicule est telle que nous préconisons d’assurer votre véhicule en tous risques.`
              : ``
          }
          `
                : ``
            }`,
            style: "test",
          },
          {
            text: `${
              (this.productName === "Automobile" && this.vehicleProcurementSelected === "Au comptant") ||
              (this.productName === "Moto/Scooter" && this.vehicleProcurementSelected === "Au comptant")
                ? `Il est possible d’améliorer l’indemnisation de votre véhicule s’il est récent.
           A titre d’exemple certains assureurs vont proposer une indemnisation en valeur d’achat pendant les trois premières années de possession du véhicule pour les véhicules ayant moins de sept ans.
          Ce principe générique varie d’un assureur à l’autre même si le principe reste identique.
          En effet, les garanties peuvent être différentes d’un assureur à l’autre
          ${
            this.customerDiscoveryVehicle.vehiclePrice > 15000
              ? `Nous vous conseillons de garantir le véhicule en valeur à neuf. Cette garantie, vous indemnise pendant une certaine période sans que la décote ARGUS du véhicule ne vous soit appliquée.`
              : ``
          }
          `
                : ``
            }`,
            style: "test",
          },

          {
            text: `${
              (this.productName === "Automobile" && this.vehicleProcurementSelected === "Via loa/ldd") ||
              (this.productName === "Moto/Scooter" && this.vehicleProcurementSelected === "Via loa/ldd")
                ? `Nous vous rappelons que vous n’êtes pas propriétaire du véhicule compte tenu du mode de financement pour lequel vous avez opté.
          En cas de destruction ou vol vous resterez redevable des conditions contractuelles qui vous lient avec la société de financement.
          De ce fait il est impératif de vous couvrir par une garantie de perte financière.
          En effet, cette garantie honorera la dette que vous avez contractée à l’égard de l’organisme de financement (${this.customerDiscoveryVehicle.funding.bank}) alors même que vous n’êtes plus en jouissance du bien à l’origine du contrat.
          `
                : ``
            }`,
            style: "test",
          },
          {
            text: `${
              (this.productName === "Automobile" && this.vehicleProcurementSelected === "Via crédit-bail") ||
              (this.productName === "Moto/Scooter" && this.vehicleProcurementSelected === "Via crédit-bail")
                ? `Nous vous rappelons que vous n’êtes pas propriétaire du véhicule compte tenu du mode de financement pour lequel vous avez opté.
          En cas de destruction ou vol vous resterez redevable des conditions contractuelles qui vous lient avec la société de financement.
          De ce fait il est impératif de vous couvrir par une garantie de perte financière.
          En effet, cette garantie honorera la dette que vous avez contractée à l’égard de l’organisme de financement (${this.customerDiscoveryVehicle.funding.bank}) alors même que vous n’êtes plus en jouissance du bien à l’origine du contrat.
          `
                : ``
            }`,
            style: "test",
            pageBreak: "after",
          },
          {
            text: `${
              this.productName === "Automobile" || this.productName === "Moto/Scooter"
                ? `\nGaranties principales :`
                : ``
            }`,
            bold: true,
          },
          `${this.productName === "Automobile" || this.productName === "Moto/Scooter" ? "\n" : ""}`,

          {
            text: `${
              this.productName === "Automobile" || this.productName === "Moto/Scooter"
                ? `Il existe plusieurs façons pour assurer un véhicule ; une garantie minimum qui ne couvrira que les dommages que vous occasionnez à des tiers dite "garantie aux tiers", une garantie améliorée qui indemnisera les tiers ainsi que certains dommages que pourraient subir votre véhicule dite "Tiers plus" et pour finir une garantie complète qui vous indemnisera dans les meilleurs conditions dite "Dommages tous accidents".`
                : ``
            }`,
            style: "test",
          },
          `${this.productName === "Automobile" || this.productName === "Moto/Scooter" ? "\n" : ""}`,
          {
            text: `${
              this.productName === "Automobile" || this.productName === "Moto/Scooter"
                ? `Vous avez choisi la garantie "${this.autoMainCategorySelected}" dont vous trouverez le détail des couvertures ci-dessous :`
                : ``
            }`,
            style: "test",
          },
          `${this.productName === "Automobile" || this.productName === "Moto/Scooter" ? "\n" : ""}`,
          {
            table: {
              headerRows: 1,
              border: [false, false, false, false],
              widths: ["*", "*"],

              // dontBreakRows: true,
              // keepWithHeaderRows: 1,
              body: [
                [
                  {
                    border: [false, false, false, false],

                    text: "VOTRE SOUHAIT",
                    style: "subheader",
                    alignment: "center",
                    fillColor: "#e1e5e9",
                  },
                  {
                    border: [false, false, false, false],

                    fillColor: "#e1e5e9",
                    text: "NOTRE CONSEIL",
                    style: "subheader",
                    alignment: "center",
                  },
                ],
                [
                  {
                    border: [false, false, false, false],

                    stack: [
                      {
                        ul: this.customerGuarantees.wish.mainGuarantees.map(item => ({
                          stack: [
                            {
                              alignment: "left",
                              columns: [
                                {
                                  svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>',
                                  width: 10,
                                  height: 10,
                                  fill: "#00a651",
                                  margin: [0, 3, 0, 0],
                                },
                                {
                                  text: item,
                                  margin: [5, 0, 0, 0],
                                },
                              ],
                            },
                          ],
                        })),
                        type: "none",
                        style: "test",
                      },
                    ],
                  },
                  {
                    border: [false, false, false, false],

                    stack: [
                      {
                        ul: this.customerGuarantees.advice.mainGuarantees.map(item => ({
                          stack: [
                            {
                              alignment: "left",
                              columns: [
                                {
                                  svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>',
                                  width: 10,
                                  height: 10,
                                  fill: "#00a651",
                                  margin: [0, 3, 0, 0],
                                },
                                {
                                  text: item,
                                  margin: [5, 0, 0, 0],
                                },
                              ],
                            },
                          ],
                        })),
                        type: "none",
                        style: "test",
                      },
                    ],
                  },
                ],
              ],
            },
          },
          // {
          //   ul: this.mainVehicleSelected,
          //   style: "test",
          // },
          // `${this.productName === "Moto/Scooter" && this.mainVehicleDontKeep.length > 1 ? "\n" : ""}`,
          // {
          //   text: `${
          //     (this.productName === "Automobile" && this.mainVehicleDontKeep.length > 1) ||
          //     (this.productName === "Moto/Scooter" && this.mainVehicleDontKeep.length > 1)
          //       ? `Vous n’avez pas souhaité garantir les éléments suivants :`
          //       : ``
          //   }`,
          //   style: "test",
          // },
          // `${this.productName === "Moto/Scooter" && this.mainVehicleDontKeep.length > 1 ? "\n" : ""}`,
          // {
          //   ul:
          //     this.productName === "Moto/Scooter" && this.mainVehicleDontKeep.length > 1
          //       ? this.mainVehicleDontKeep[0]
          //       : this.arrayNull,
          //   style: "test",
          // },
          // {
          //   text: `${
          //     (this.productName === "Automobile" && this.autoMainCategorySelected === "Tiers simple") ||
          //     (this.productName === "Moto/Scooter" && this.autoMainCategorySelected === "Tiers simple")
          //       ? `En complément des garanties accordées au tiers, vous n'avez pas souhaité garantir les dommages occasionnés à votre véhicule.`
          //       : ``
          //   } `,
          //   style: "test",
          // },
          // {
          //   text: `${
          //     (this.productName === "Automobile" && this.autoMainCategorySelected === "Tiers étendu") ||
          //     (this.productName === "Moto/Scooter" && this.autoMainCategorySelected === "Tiers étendu")
          //       ? `Vous n'avez pas souhaité assurer l'intégralité des types de dommages que pourrait subir votre propre véhicule.\n`
          //       : ``
          //   }`,
          //   style: "test",
          // },
          // {
          //   text: `${
          //     this.productName === "Automobile" && this.autoMainCategorySelected === "Tiers étendu" ? `\n` : ``
          //   }`,
          // },
          `${this.productName === "Automobile" || this.productName === "Moto/Scooter" ? "\n" : ""}`,

          {
            text: `${
              this.productName === "Automobile" || this.productName === "Moto/Scooter"
                ? `Garanties complémentaires :\r`
                : ``
            }`,
            bold: true,
          },
          `${this.productName === "Automobile" || this.productName === "Moto/Scooter" ? "\n" : ""}`,

          {
            text: `${
              this.productName === "Automobile" || this.productName === "Moto/Scooter"
                ? ` En complément du socle de garanties principales que vous avez choisi, il est possible et parfois nécessaire de garantir des éléments spécifiques qui améliorent votre niveau de protection où de prise en charge par l’assureur.
              `
                : ``
            }`,
            style: "test",
          },
          `${this.productName === "Automobile" || this.productName === "Moto/Scooter" ? "\n" : ""}`,
          {
            table: {
              headerRows: 1,
              border: [false, false, false, false],
              widths: ["*", "*"],

              // dontBreakRows: true,
              // keepWithHeaderRows: 1,
              body: [
                [
                  {
                    border: [false, false, false, false],

                    text: "VOTRE SOUHAIT",
                    style: "subheader",
                    alignment: "center",
                    fillColor: "#e1e5e9",
                  },
                  {
                    border: [false, false, false, false],

                    fillColor: "#e1e5e9",
                    text: "NOTRE CONSEIL",
                    style: "subheader",
                    alignment: "center",
                  },
                ],
                [
                  {
                    border: [false, false, false, false],

                    stack: [
                      {
                        ul: this.customerGuarantees.wish.additionalGuarantees.map(item => ({
                          stack: [
                            {
                              alignment: "left",
                              columns: [
                                {
                                  svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>',
                                  width: 10,
                                  height: 10,
                                  fill: "#00a651",
                                  margin: [0, 3, 0, 0],
                                },
                                {
                                  text: item,
                                  margin: [5, 0, 0, 0],
                                },
                              ],
                            },
                          ],
                        })),
                        type: "none",
                        style: "test",
                      },
                    ],
                  },
                  {
                    border: [false, false, false, false],

                    stack: [
                      {
                        ul: this.customerGuarantees.advice.additionalGuarantees.map(item => ({
                          stack: [
                            {
                              alignment: "left",
                              columns: [
                                {
                                  svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>',
                                  width: 10,
                                  height: 10,
                                  fill: "#00a651",
                                  margin: [0, 3, 0, 0],
                                },
                                {
                                  text: item,
                                  margin: [5, 0, 0, 0],
                                },
                              ],
                            },
                          ],
                        })),
                        type: "none",
                        style: "test",
                      },
                    ],
                  },
                ],
              ],
            },
          },
          // {
          //   text: `${
          //     (this.productName === "Automobile" && this.vehicleAdditionalSelected.length > 0) ||
          //     (this.productName === "Moto/Scooter" && this.vehicleAdditionalSelected.length > 0)
          //       ? "Ainsi lors de nos échanges vous avez souhaité couvrir :"
          //       : ""
          //   }`,
          //   style: "test",
          // },
          // `${
          //   (this.productName === "Automobile" && this.vehicleAdditionalSelected.length > 0) ||
          //   (this.productName === "Moto/Scooter" && this.vehicleAdditionalSelected.length > 0)
          //     ? "\n"
          //     : ""
          // }`,

          // {
          //   ul:
          //     this.productName === "Automobile" || this.productName === "Moto/Scooter"
          //       ? this.vehicleAdditionalSelected
          //       : this.arrayNull,
          //   style: "test",
          // },
          // {
          //   text: `${
          //     (this.productName === "Automobile" &&
          //       this.vehicleAdditionalDontKeep.length > 0 &&
          //       this.vehicleAdditionalSelected.length > 0) ||
          //     (this.productName === "Moto/Scooter" &&
          //       this.vehicleAdditionalDontKeep.length > 0 &&
          //       this.vehicleAdditionalSelected.length > 0)
          //       ? `\n`
          //       : ``
          //   }`,
          //   style: "test",
          // },
          // {
          //   text: `${
          //     this.vehicleAdditionalSelected.includes("Marchandise transportée")
          //       ? `\nVous nous avez préciser vouloir prendre la garantie marchandise transportée d'un montant de ${this.merchandiseTransportedAmount}€`
          //       : ""
          //   }`,
          //   style: "test",
          // },
          // `${
          //   (this.productName === "Automobile" && this.vehicleAdditionalSelected.includes("Marchandise transportée")) ||
          //   (this.productName === "Moto/Scooter" && this.vehicleAdditionalSelected.includes("Marchandise transportée"))
          //     ? "\n"
          //     : ""
          // }`,
          // {
          //   text: `${
          //     (this.productName === "Automobile" && this.vehicleAdditionalDontKeep.length > 0) ||
          //     (this.productName === "Moto/Scooter" && this.vehicleAdditionalDontKeep.length > 0)
          //       ? `Vous n’avez pas souhaité garantir les éléments suivants :`
          //       : ``
          //   }`,
          //   style: "test",
          // },
          // `${
          //   (this.productName === "Automobile" && this.vehicleAdditionalDontKeep.length > 0) ||
          //   (this.productName === "Moto/Scooter" && this.vehicleAdditionalDontKeep.length > 0)
          //     ? "\n"
          //     : ""
          // }`,

          // {
          //   ul:
          //     this.productName === "Automobile" || this.productName === "Moto/Scooter"
          //       ? this.productName === "Automobile"
          //         ? this.vehicleAdditionalDontKeep[0]
          //         : this.vehicleAdditionalDontKeep[0]
          //       : this.arrayNull,
          //   style: "test",
          //   pageBreak: `${
          //     (this.productName === "Moto/Scooter" && this.vehicleAdditionalSelected.includes("Hivernage")) ||
          //     (this.productName === "Moto/Scooter" &&
          //       this.vehicleAdditionalSelected.includes("Conduite exclusive") &&
          //       this.bodyProctectLendWheel === false)
          //       ? "after"
          //       : "after"
          //   }`,
          // },
          //           `${this.productName === "Moto/Scooter" ? "\n" : ""}`,
          //           {
          //             text: `${
          //               this.productName === "Moto/Scooter" && this.vehicleAdditionalSelected.includes("Hivernage")
          //                 ? `Nous attirons votre attention sur la bonne compréhension de cette garantie : l’option hivernage consiste à remiser le véhicule sur le lieu de garage désigné. Et à assurer le véhicule en cas d'incendie, par exemple.
          // Qu'aucune garantie circulation n'est accordée à aucun titre que ce soit, même pour un essai de véhicule. Si le véhicule sort du garage il convient d'appeler votre assureur avant toute mise en circulation sur la voie publique.`
          //                 : ``
          //             }`,
          //             style: "test",
          //           },
          //           {
          //             text: `${
          //               this.productName === "Moto/Scooter" &&
          //               this.vehicleAdditionalSelected.includes("Conduite exclusive") &&
          //               this.bodyProctectLendWheel === false
          //                 ? `\nVous avez choisi d’assurer votre véhicule dans le cadre de la conduite exclusive, à ce titre toute personne qui utiliserait la moto ne bénéficierait pas de la garantie corporelle du conducteur en cas d’accident.`
          //                 : ``
          //             }`,
          //             style: "test",
          //           },
          //           {
          //             columns: [],
          //             pageBreak: `${this.productName === "Automobile" ? "after" : "after"}`,
          //           },
          // {
          //   text: `${this.productName === "Automobile" ? `Véhicule de remplacement :\r` : ``}`,
          //   bold: true,
          // },
          //           `${this.productName === "Automobile" ? "\n" : ""}`,

          //           {
          //             text: `${
          //               this.productName === "Automobile"
          //                 ? `En cas de sinistre, votre véhicule peut-être inutilisable et vous êtes dans l’obligation de trouver une solution pour vous déplacer. Les assureurs peuvent pallier à cette problématique et proposent des couvertures élargies : Souhaitez-vous garantir un prêt ou une location de véhicule ?`
          //                 : ``
          //             }`,
          //             style: "test",
          //           },
          //           `${this.productName === "Automobile" && this.vehicleReplacementSelected.length > 0 ? "\n" : ""}`,

          //           {
          //             text: `${
          //               this.productName === "Automobile" && this.vehicleReplacementSelected.length > 0
          //                 ? "Ainsi lors de nos échanges vous avez souhaité couvrir :"
          //                 : ""
          //             }`,
          //             style: "test",
          //           },
          //           `${this.productName === "Automobile" && this.vehicleReplacementSelected.length > 0 ? "\n" : ""}`,
          //           {
          //             ul: this.productName === "Automobile" ? this.vehicleReplacementSelected : this.arrayNull,
          //             style: "test",
          //           },
          //           `${this.productName === "Automobile" ? `${this.vehicleReplacementDontKeep.length > 0 ? `\n` : ``}` : ""}`,
          //           {
          //             text: `${
          //               this.productName === "Automobile" && this.vehicleReplacementDontKeep.length > 0
          //                 ? `Ainsi lors de nos échanges vous n’avez pas souhaité garantir :`
          //                 : ``
          //             }`,

          //             style: "test",
          //           },
          //           `${this.productName === "Automobile" && this.vehicleReplacementDontKeep.length > 0 ? "\n" : ""}`,

          //           {
          //             ul: this.productName === "Automobile" ? this.vehicleReplacementDontKeep[0] : this.arrayNull,
          //             style: "test",
          //           },
          `${this.productName === "Automobile" ? "\n" : ""}`,
          {
            text: `${
              this.productName === "Automobile" && this.customerDiscoveryVehicle.trailers.length > 0
                ? `Usage d'une remorque :\r`
                : ``
            }`,
            bold: true,
          },
          `${this.productName === "Automobile" && this.customerDiscoveryVehicle.trailers.length > 0 ? "\n" : ""}`,
          {
            text: `${
              this.productName === "Automobile" && this.customerDiscoveryVehicle.trailers.length > 0
                ? `Vous nous avez précisé que vous aviez l’usage d’une remorque pour laquelle il existe des garanties particulières.\n
                            Ainsi, vous nous avez précisé utiliser ${
                              this.customerDiscoveryVehicle.trailers.length <= 1 ? `une` : `des`
                            } remorque${this.customerDiscoveryVehicle.trailers.length <= 1 ? `` : `(s)`}`
                : ``
            }`,

            style: "test",
          },
          `${this.productName === "Automobile" ? "\n" : ""}`,
          {
            table: {
              headerRows: 1,
              widths: ["*", "*", "*", "*"],
              body: [
                [
                  {
                    border: [false, false, false, false],

                    fillColor: "#e1e5e9",
                    stack: [
                      {
                        columns: [
                          {
                            svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M48 32C21.5 32 0 53.5 0 80V336c0 26.5 21.5 48 48 48H65.1c7.8-54.3 54.4-96 110.9-96s103.1 41.7 110.9 96H488h8H608c17.7 0 32-14.3 32-32s-14.3-32-32-32H544V80c0-26.5-21.5-48-48-48H48zM80 96c8.8 0 16 7.2 16 16l0 131.2c-11.4 5.9-22.2 12.9-32 21V112c0-8.8 7.2-16 16-16zm96 128c-5.4 0-10.7 .2-16 .7L160 112c0-8.8 7.2-16 16-16s16 7.2 16 16l0 112.7c-5.3-.5-10.6-.7-16-.7zm80 19.2L256 112c0-8.8 7.2-16 16-16s16 7.2 16 16l0 152.2c-9.8-8.1-20.6-15.2-32-21zM368 96c8.8 0 16 7.2 16 16l0 192c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-192c0-8.8 7.2-16 16-16zm112 16l0 192c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-192c0-8.8 7.2-16 16-16s16 7.2 16 16zM176 480a80 80 0 1 0 0-160 80 80 0 1 0 0 160zm0-112a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>',
                            width: 24,
                            height: 24,
                            fill: "#00a651",
                          },
                          {
                            /// align texte verticaly center

                            text: "",
                            margin: [5, 0, 0, 0],
                            alignment: "center",
                            // align verticaly center
                          },
                        ],
                      },
                    ],
                    style: "subheader",
                    alignment: "center",
                  },
                  {
                    border: [false, false, false, false],

                    fillColor: "#e1e5e9",
                    stack: [
                      {
                        columns: [
                          {
                            svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M128 176a128 128 0 1 1 256 0 128 128 0 1 1 -256 0zM391.8 64C359.5 24.9 310.7 0 256 0S152.5 24.9 120.2 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H391.8zM296 224c0-10.6-4.1-20.2-10.9-27.4l33.6-78.3c3.5-8.1-.3-17.5-8.4-21s-17.5 .3-21 8.4L255.7 184c-22 .1-39.7 18-39.7 40c0 22.1 17.9 40 40 40s40-17.9 40-40z"/></svg>',
                            width: 24,
                            height: 24,
                            fill: "#00a651",
                          },
                          {
                            /// align texte verticaly center

                            text: "POIDS",
                            margin: [5, 0, 0, 0],
                            alignment: "center",
                            // align verticaly center
                          },
                        ],
                      },
                    ],
                    style: "subheader",
                    alignment: "center",
                  },
                  {
                    border: [false, false, false, false],

                    fillColor: "#e1e5e9",
                    stack: [
                      {
                        columns: [
                          {
                            svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M58.9 42.1c3-6.1 9.6-9.6 16.3-8.7L320 64 564.8 33.4c6.7-.8 13.3 2.7 16.3 8.7l41.7 83.4c9 17.9-.6 39.6-19.8 45.1L439.6 217.3c-13.9 4-28.8-1.9-36.2-14.3L320 64 236.6 203c-7.4 12.4-22.3 18.3-36.2 14.3L37.1 170.6c-19.3-5.5-28.8-27.2-19.8-45.1L58.9 42.1zM321.1 128l54.9 91.4c14.9 24.8 44.6 36.6 72.5 28.6L576 211.6v167c0 22-15 41.2-36.4 46.6l-204.1 51c-10.2 2.6-20.9 2.6-31 0l-204.1-51C79 419.7 64 400.5 64 378.5v-167L191.6 248c27.8 8 57.6-3.8 72.5-28.6L318.9 128h2.2z"/></svg>',
                            width: 24,
                            height: 24,
                            fill: "#00a651",
                          },
                          {
                            text: "MARCHANDISES",
                            margin: [5, 0, 0, 0],
                            alignment: "center",
                          },
                        ],
                      },
                    ],
                    style: "subheader",
                    alignment: "center",
                  },
                  {
                    border: [false, false, false, false],

                    fillColor: "#e1e5e9",
                    stack: [
                      {
                        columns: [
                          {
                            svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M0 96l576 0c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96zm0 32V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128H0zM64 405.3c0-29.5 23.9-53.3 53.3-53.3H234.7c29.5 0 53.3 23.9 53.3 53.3c0 5.9-4.8 10.7-10.7 10.7H74.7c-5.9 0-10.7-4.8-10.7-10.7zM176 192a64 64 0 1 1 0 128 64 64 0 1 1 0-128zm176 16c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16z"/></svg>',
                            width: 24,
                            height: 24,
                            fill: "#00a651",
                          },
                          {
                            text: "PERMIS",
                            alignment: "center",

                            margin: [5, 0, 0, 0],
                          },
                        ],
                      },
                    ],
                    style: "subheader",
                    alignment: "center",
                  },
                ],
                ...this.customerDiscoveryVehicle.trailers.map(el => {
                  return [
                    {
                      border: [false, false, false, true],

                      text: `Remorque n°${el.id}`,
                      style: "test",
                    },
                    {
                      border: [false, false, false, true],

                      text: el.weight,
                      style: "test",
                    },
                    el.hasMerchandises
                      ? {
                          border: [false, false, false, true],
                          stack: [
                            {
                              ul: el.merchandises,
                              style: "test",
                            },
                          ],
                        }
                      : {
                          border: [false, false, false, true],
                          text: `Aucune marchandise transportée`,
                          style: "test",
                        },
                    {
                      border: [false, false, false, true],
                      text: `${
                        el.licence != "B"
                          ? `${el.licence}\n Nous attirons le fait de posséder ce permis pour pouvoir assurer cette remorque.`
                          : el.licence
                      }`,
                      alignment: "center",
                      fillColor: el.licence != "B" ? "#FCC03C" : "#92FF81",
                      style: "test",
                    },
                  ]
                }),
              ],
            },
          },
          `${this.productName === "Automobile" ? "\n" : ""}`,
          {
            text: `${
              this.productName === "Automobile" && this.customerDiscoveryVehicle.trailers.length > 0
                ? `Vous nous avez précisé vouloir garantir vos marchandises transportées pour un montant de ${this.customerGuarantees.wish.trailerMerchandiseAmount}€\r
                Nous vous recommandons ${this.customerGuarantees.advice.trailerMerchandiseAmount}€ afin de couvrir au mieux vos besoins.`
                : ``
            }`,
            style: "test",
          },

          //           `${
          //             this.productName === "Moto/Scooter" &&
          //             this.vehicleAdditionalSelected.includes("Conduite exclusive") &&
          //             this.bodyProctectLendWheel === false
          //               ? "\n"
          //               : ""
          //           }`,

          //           {
          //             text: `${this.productName === "Moto/Scooter" ? `Sécurité et vol :\r` : ``}`,
          //             bold: true,
          //           },
          //           `${this.productName === "Moto/Scooter" ? "\n" : ""}`,

          //           {
          //             text: `${
          //               this.productName === "Moto/Scooter"
          //                 ? `Vous déclarez disposer des dispositifs de sécurité suivant : ${this.itemsAgainstStealSelected.map(
          //                     el => {
          //                       return el + " "
          //                     },
          //                   )}`
          //                 : ``
          //             }`,
          //             style: "test",
          //             pageBreak: `${this.productName === "Moto/Scooter" ? "after" : "after"}`,
          //           },
          `${this.productName === "Automobile" ? "\n" : ""}`,
          {
            text: `${
              this.productName === "Automobile" || this.productName === "Moto/Scooter" ? `Niveau de franchise :\r` : ``
            }`,
            bold: true,
          },
          `${this.productName === "Automobile" || this.productName === "Moto/Scooter" ? "\n" : ""}`,

          {
            text: `${
              this.productName === "Automobile" || this.productName === "Moto/Scooter"
                ? `La franchise est un élément déterminant dans le calcul du prix de votre couverture d’assurance. Une franchise faible fera augmenter le coût de la cotisation alors qu’à l’inverse, une franchise plus forte réduira le coût de cette dernière.
                          `
                : ``
            }`,
            style: "test",
          },
          {
            table: {
              headerRows: 1,
              border: [false, false, false, false],
              widths: ["*", "*"],

              // dontBreakRows: true,
              // keepWithHeaderRows: 1,
              body: [
                [
                  {
                    border: [false, false, false, false],

                    text: "VOTRE SOUHAIT",
                    style: "subheader",
                    alignment: "center",
                    fillColor: "#e1e5e9",
                  },
                  {
                    border: [false, false, false, false],

                    fillColor: "#e1e5e9",
                    text: "NOTRE CONSEIL",
                    style: "subheader",
                    alignment: "center",
                  },
                ],
                [
                  {
                    border: [false, false, false, false],

                    stack: [
                      {
                        ul: this.customerGuarantees.wish.deductibleGuarantees.map(item => ({
                          stack: [
                            {
                              alignment: "left",
                              columns: [
                                {
                                  svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>',
                                  width: 10,
                                  height: 10,
                                  fill: "#00a651",
                                  margin: [0, 3, 0, 0],
                                },
                                {
                                  text: item,
                                  margin: [5, 0, 0, 0],
                                },
                              ],
                            },
                          ],
                        })),
                        type: "none",
                        style: "test",
                      },
                    ],
                  },
                  {
                    border: [false, false, false, false],

                    stack: [
                      {
                        ul: this.customerGuarantees.advice.deductibleGuarantees.map(item => ({
                          stack: [
                            {
                              alignment: "left",
                              columns: [
                                {
                                  svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>',
                                  width: 10,
                                  height: 10,
                                  fill: "#00a651",
                                  margin: [0, 3, 0, 0],
                                },
                                {
                                  text: item,
                                  margin: [5, 0, 0, 0],
                                },
                              ],
                            },
                          ],
                        })),
                        type: "none",
                        style: "test",
                      },
                    ],
                  },
                ],
              ],
            },
          },

          // {
          //   text: `${
          //     (this.productName === "Automobile" &&
          //       this.deductibleDontKeep[0].length > 0 &&
          //       this.deductibleSelected.length > 0) ||
          //     (this.productName === "Moto/Scooter" &&
          //       this.deductibleDontKeep[0].length > 0 &&
          //       this.deductibleSelected.length > 0)
          //       ? `\n`
          //       : ``
          //   }`,
          // },
          // {
          //   text: `${
          //     (this.productName === "Automobile" && this.deductibleDontKeep[0].length > 0) ||
          //     (this.productName === "Moto/Scooter" && this.deductibleDontKeep[0].length > 0)
          //       ? `Vous n’avez pas retenu les franchises :`
          //       : ``
          //   }`,

          //   style: "test",
          // },
          // {
          //   text: `${
          //     (this.productName === "Automobile" && this.deductibleDontKeep[0].length > 0) ||
          //     (this.productName === "Moto/Scooter" && this.deductibleDontKeep[0].length > 0)
          //       ? `\n`
          //       : ``
          //   }`,
          // },
          // {
          //   ul:
          //     (this.productName === "Automobile" && this.deductibleDontKeep[0].length > 0) ||
          //     (this.productName === "Moto/Scooter" && this.deductibleDontKeep[0].length > 0)
          //       ? this.deductibleDontKeep[0]
          //       : this.arrayNull,
          //   style: "test",
          // },

          /****************************************************************************************** */
          {
            text: `${
              this.productName === "Responsabilité civile des dirigeants"
                ? `GARANTIES DESTINÉES A LA PERSONNE PHYSIQUE :`
                : ``
            }`,
            bold: true,
          },
          `${this.productName === "Responsabilité civile des dirigeants" ? "\n" : ""}`,
          {
            text: `${
              this.productName === "Responsabilité civile des dirigeants"
                ? `En devenant chef d'entreprise, le créateur va engager sa responsabilité sur plusieurs terrains : pénal et civil. La responsabilité pénale sert à réprimer. La responsabilité civile permet, elle, de réparer un préjudice. Une même infraction peut entraîner à la fois la responsabilité pénale et civile du dirigeant. Les dirigeants de fait encourent les mêmes sanctions et responsabilités que les dirigeants de droit.\n
                En cas de mise en cause de la responsabilité personnelle du dirigeant, les dommages et intérêts mis à sa charge peuvent s’avérer très élevés et mettre en péril son patrimoine personnel et ce ne sont pas les seuls frais qu’il pourrait être amené à engager.\n
                C’est pourquoi et pour donner suite à nos échanges, vous nous avez fait part de votre souhait de couvrir les éléments suivants :`
                : ``
            }`,

            style: "test",
          },
          `${
            this.productName === "Responsabilité civile des dirigeants" && this.physicalPersonKeep[0].length > 0
              ? "\n"
              : ""
          }`,

          {
            ul:
              this.productName === "Responsabilité civile des dirigeants" && this.radios === "Professionnel"
                ? this.physicalPersonKeep[0]
                : this.arrayNull,
            style: "test",
          },
          `${
            this.productName === "Responsabilité civile des dirigeants" && this.physicalPersonKeep[0].length > 0
              ? "\n"
              : ""
          }`,

          {
            text: `${
              this.radios === "Professionnel" &&
              this.productName === "Responsabilité civile des dirigeants" &&
              this.physicalPersonDontKeep[0].length > 0
                ? `Vous n'avez pas souhaité souscrire aux garanties suivantes :`
                : ``
            }`,
            style: "test",
          },
          `${
            this.productName === "Responsabilité civile des dirigeants" && this.physicalPersonDontKeep[0].length > 0
              ? "\n"
              : ""
          }`,

          {
            ul:
              this.productName === "Responsabilité civile des dirigeants" && this.radios === "Professionnel"
                ? this.physicalPersonDontKeep[0]
                : this.arrayNull,
            style: "test",
          },
          `${
            this.productName === "Responsabilité civile des dirigeants" && this.physicalPersonDontKeep[0].length > 0
              ? "\n"
              : ""
          }`,

          //*

          {
            text: `${
              this.productName === "Responsabilité civile des dirigeants"
                ? `GARANTIES DESTINÉES A LA PERSONNE MORALE  :`
                : ``
            } `,
            bold: true,
          },
          `${this.productName === "Responsabilité civile des dirigeants" ? "\n" : ""}`,
          {
            text: `${
              this.productName === "Responsabilité civile des dirigeants" && this.corporationKeep[0].length > 0
                ? `Une personne morale (société civile ou commerciale, association, GIE, collectivités territoriales, etc.) peut être condamnée pénalement lorsqu'une infraction a été commise pour son compte par l'un de ses organes de direction ou par toute autre personne au titre d'une délégation de pouvoir.`
                : ``
            }`,
            style: "test",
          },

          {
            text: `${
              this.productName === "Responsabilité civile des dirigeants" && this.corporationKeep[0].length > 0
                ? `Pour donner suite à nos échanges, vous nous avez fait part de votre souhait de couvrir les éléments suivants :`
                : ``
            }`,
            style: "test",
          },
          `${
            this.productName === "Responsabilité civile des dirigeants" && this.corporationKeep[0].length > 0
              ? "\n"
              : ""
          }`,

          {
            ul:
              this.productName === "Responsabilité civile des dirigeants" && this.radios === "Professionnel"
                ? this.corporationKeep[0]
                : this.arrayNull,
            style: "test",
          },

          `${
            this.productName === "Responsabilité civile des dirigeants" && this.corporationKeep[0].length > 0
              ? "\n"
              : ""
          }`,

          {
            text: `${
              this.radios === "Professionnel" &&
              this.productName === "Responsabilité civile des dirigeants" &&
              this.corporationDontKeep[0].length > 0
                ? `Vous n'avez pas souhaité souscrire aux garanties suivantes :`
                : ``
            }`,
            style: "test",
          },
          `${
            this.productName === "Responsabilité civile des dirigeants" && this.corporationDontKeep[0].length > 0
              ? "\n"
              : ""
          }`,

          {
            ul:
              this.productName === "Responsabilité civile des dirigeants" && this.radios === "Professionnel"
                ? this.corporationDontKeep[0]
                : this.arrayNull,
            style: "test",
          },

          `${
            this.productName === "Responsabilité civile des dirigeants" && this.corporationDontKeep[0].length > 0
              ? "\n"
              : ""
          }`,

          {
            text: `${
              this.productName === "Responsabilité civile des dirigeants" && this.radios === "Professionnel"
                ? `MONTANT MINIMUM DE GARANTIE PRÉCONISÉ :`
                : ``
            } `,
            bold: true,
          },

          `${
            this.productName === "Responsabilité civile des dirigeants" && this.radios === "Professionnel" ? `\n` : ``
          }`,
          {
            text: `${
              this.radios === "Professionnel" && this.productName === "Responsabilité civile des dirigeants"
                ? `A la lecture des besoins exprimés, de votre chiffre d’affaires et de vos capitaux propres, nous vous conseillons de souscrire à minima une garantie à hauteur de ${
                    this.ticksLabels[this.recommandAmount]
                  }€ `
                : ``
            }`,
            style: "test",
            pageBreak: `${
              this.productName === "Responsabilité civile des dirigeants" && this.radios === "Professionnel"
                ? `after`
                : `after`
            }`,
          },

          /****************************************************************************************** */

          // {
          //   text: "RECHERCHE DE LA SOLUTION D’ASSURANCE",
          //   bold: true,
          // },
          // "\n",
          // {
          //   text: `Vous nous avez dûment mandaté pour interroger le marché et ce, afin que nous puissions trouver la solution la plus en adéquation à vos souhaits et à vos objectifs tarifaires.\n
          //     Pour ce faire, nous avons interrogé des assureurs ayant une bonne notoriété et un vrai savoir-faire sur la couverture de ces risques spécifiques.
          //     Ainsi, nous avons obtenu des propositions des trois assureurs ci-dessous :
          //     `,
          //   style: "test",
          // },
          // {
          //   ol: this.insurerSelected,
          //   style: "test",
          // },
          // "\n",
          // {
          //   text: `La proposition réalisée par ${this.companySelected} couvre au mieux les besoins que vous avez exprimés et c’est pourquoi, nous vous préconisons de retenir cette compagnie d’assurance. `,
          //   style: "test",
          // },
          // `${this.informationsAboutCompany === "" ? "" : `\n`}`,
          // {
          //   text: `${
          //     this.informationsAboutCompany === "" ? "" : `Pourquoi nous vous préconisons ${this.companySelected} :`
          //   }`,
          //   style: "subheader",
          // },
          // `${this.informationsAboutCompany === "" ? "" : `\n`}`,
          // {
          //   text: `${this.informationsAboutCompany === "" ? "" : this.informationsAboutCompany}`,
          //   style: "test",
          // },
          // `\n`,
          // {
          //   text: `Nous tenons les autres propositions commerciales à votre disposition, nous serons en capacité de vous les transmettre sur simple demande. Vous trouverez donc en éléments joints, l’ensemble des éléments précontractuels :

          //     -	La proposition commerciale accompagnée des tableaux de garanties et des franchises
          //     -	Les conditions générales du contrat
          //     -	Le document d’information sur le produit d’assurance

          //     Lors de nos différents entretiens, vous n’avez pas exprimé de besoin complémentaire à ceux détaillés ci-haut et nous nous tenons à votre disposition pour apporter toute modification que vous jugeriez nécessaire à cette proposition.`,
          //   style: "test",
          // },
          {
            alignment: "justify",
            columns: [{}, {}, {}],
            pageBreak: "after",
          },

          {
            text: "NOTRE POLITIQUE EN MATIERE DE PROTECTION DE VOS DONNEES PERSONNELLES",
            style: "subheader",
          },
          "\n",
          {
            text: `Les données que nous collectons à votre sujet :
              Nous collectons les informations nécessaires à l'exercice de notre activité, mais aussi pour vous fournir des services particuliers demandés par vos soins et pour vous tenir informés. Nous pouvons également utiliser les informations que vous fournissez à des fins marketings pour communiquer avec vous, sur nos produits et services. Il nous est également possible de partager vos informations avec des tiers afin de vous permettre d'accéder aux services que nous vous proposons.
              
              Nous pouvons collecter, utiliser, stocker et transférer différents types de données personnelles vous concernant, que nous avons regroupés de la façon suivante :`,
            style: "test",
          },
          {
            ul: this.personnalData,
            style: "test",
          },
          "\n",
          {
            text: ` Les données tierces que vous nous fournissez :
              Dans le cadre de l'utilisation de nos services, vous pouvez également nous fournir des informations personnelles sur des tiers, notamment des informations sur vos collègues ou proches que nous ne détenons pas dans notre base de données et que vous pouvez nous demander de traiter en votre nom (par exemple par le biais de notre fonctionnalité "Parrainage"). La façon dont nous traitons ces données tierces est définie dans notre contrat de services et ne fait pas l’objet de cet avis de confidentialité, dont le but est de vous informer sur la manière dont nous traitons vos données personnelles.

              Comment sont collectées vos données personnelles ?
              Généralement, nous collectons des informations personnelles vous concernant vous, vos employés et vos représentants lorsque vous décidez d'interagir avec nous, ou de nous solliciter dans le cadre de la réalisation de notre mission. La méthodologie de collecte des données comprend :`,
            style: "test",
          },
          {
            ul: this.collectData,
            style: "test",
          },
          "\n",

          {
            text: `Les raisons pour lesquelles nous utilisons vos données personnelles :
`,
            style: "test",
          },
          {
            ul: this.reasonCollectData,
            style: "test",
          },
          "\n",

          {
            text: `Nous utilisons vos données personnelles seulement lorsque la loi nous le permet, à savoir :`,
            style: "test",
          },
          {
            ul: this.useData,
            style: "test",
          },
          "\n",
          "\n",

          {
            text: `
              Comment nous partageons vous données personnelles, et avec qui ?
              Nous pouvons partager vos données personnelles avec des sociétés extérieures à C2T ASSURANCES. Les tierces parties externes peuvent inclure :`,
            style: "test",
          },
          {
            ul: this.shareData,
            style: "test",
          },
          "\n",
          {
            text: ` Nous pouvons également partager vos données personnelles avec des tiers à qui nous pouvons choisir de vendre, transférer ou fusionner des parties de nos activités ou de nos actifs. Alternativement, nous pouvons chercher à acquérir d'autres entreprises ou fusionner avec eux. Si un changement se produit dans notre entreprise, les nouveaux propriétaires peuvent utiliser vos données personnelles de la même manière que dans la présente déclaration de confidentialité.

              Nous demandons à tous les tiers de respecter la sécurité de vos données personnelles et de les traiter conformément à la loi. Nous n'autorisons pas nos fournisseurs de services tiers à utiliser vos données personnelles à leurs propres fins. Nous leur permettons seulement de traiter vos données personnelles à des fins spécifiques et conformément à des obligations contractuelles, qui assurent une protection adéquate de vos données.

              Pendant combien de temps utiliserons-nous vos données personnelles ?
              Nous conserverons vos données personnelles uniquement pendant le temps nécessaire aux fins énoncées dans cette déclaration de confidentialité et pour remplir nos obligations légales. Nous ne conserverons pas plus de données que nous n'en avons besoin. Tant que vous demeurez client, nous conservons vos données afin de continuer à vous fournir des services. 

              Si vous ne souhaitez plus utiliser nos services, nous conserverons vos données personnelles pendant une période de 5 ans avant de les supprimer. Nous sommes tenus de conserver certaines informations de base sur nos clients pour une période plus longue conformément aux lois fiscales applicables. Dans certaines circonstances, vous pouvez nous demander de supprimer vos données (voir ci-dessous).
              `,
            style: "test",
          },
          {
            text: `VOS DROITS`,
            style: "subheader",
          },
          "\n",
          {
            text: ` Dans certaines circonstances, vous disposez de droits en vertu des lois sur la protection des données relatives à vos données personnelles, y compris le droit d'accès, le droit à la rectification, le droit d'effacer, le droit de restreindre le traitement, le droit à la portabilité des données et le droit d'objecter.

              Si vous souhaitez exercer vos droits, veuillez contacter notre DPO : david.audren@eole-assurance.bzh

              En vertu des lois européennes sur la protection des données, vous disposez d'un certain nombre de droits relatifs à vos données personnelles. Ces droits ne sont pas absolus - dans certains cas, ils ne s'appliqueront pas à vous, ou à l'utilisation particulière que nous faisons de vos données. Il y a des exceptions, par exemple si nous devons traiter les données pour nous conformer à nos propres obligations légales, mais si tel est le cas nous vous le ferons savoir.`,
            style: "test",
          },
          {
            text: "Vos droits incluent :",
            style: "test",
          },
          {
            ul: this.rightData,
            style: "test",
          },
          "\n",
          {
            text: `Vous avez également le droit de déposer une plainte à tout moment auprès de la Commission Nationale de l’Informatique et des Libertés (CNIL, l'autorité de contrôle française pour les questions relatives à la protection des données) (www.cnil.fr). En revanche, veillez à nous contacter en premier lieu.`,
            style: "test",
            pageBreak: "after",
          },
          {
            text: `Nos équipes se tiennent à votre disposition pour vous apporter tous les éléments complémentaires dont vous pourriez avoir besoin suite à la transmission de ce conseils et des propositions d’assurances correspondantes.`,
            style: "test",
          },
          "\n",
          {
            text: "ANNEXES NÉCESSAIRES A L'ÉTUDE :",
            style: "subheader",
          },
          "\n",
          {
            table: {
              body: [
                [
                  {
                    text: this.insurerSelected.map(el => {
                      return `- Mandat compagnie : ${el}\n`
                    }),
                    style: "test",
                  },
                ],
              ],
            },
            layout: "noBorders",
            style: "test",
          },

          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          {
            alignment: "justify",
            columns: [
              {},
              {},
              {
                text: `Signature\nA Rennes le : ${this.getToday()}`,
                style: "test",
              },
            ],
          },
        ],

        styles: {
          header: {
            fontSize: 11,
            bold: true,
          },
          subheader: {
            fontSize: 11,
            bold: true,
            font: "mplus",
            color: "#001F47",
          },
          quote: {
            italics: true,
          },
          small: {
            fontSize: 8,
          },
          test: {
            fontSize: 10,
            font: "mplus",
          },
        },
      }

      pdfMake.fonts = {
        Roboto: {
          normal: "Roboto-Regular.ttf",
          bold: "Roboto-Medium.ttf",
          italics: "Roboto-Italic.ttf",
          bolditalics: "Roboto-Italic.ttf",
        },

        mplus: {
          normal: "MPLUS1p-Regular.ttf",
          bold: "MPLUS1p-Bold.ttf",
          italics: "MPLUS1p-Italic.ttf",
          bolditalics: "MPLUS1p-BoldItalic.ttf",
        },
      }

      // pdfMake.createPdf(dd).open()
      pdfMake.createPdf(dd).getDataUrl(function (outDoc) {
        document.getElementById("lddcVisualisator").src = outDoc
        documentData.push({
          content: outDoc,
          filename: dd.info.title,
        })
      })

      pdfMake.createPdf(dd).getBlob(blob => {
        const pdfFile = new File([blob], `${dd.info.title}.pdf`, {
          type: "application/pdf",
        })

        // this.uploadFile(pdfFile)
      })

      // get the document

      setTimeout(() => {
        this.documentsData = documentData
      }, 2000)
      // this.studyMake()
    },
    sendmail() {
      try {
        emailjs.send("service_5p49t7p", "template_jfi8qwa", {
          to_name: `${this.customerData.civility} ${this.customerData.firstname} ${this.customerData.lastname}`,
          to_email: this.customerData.email,
          filename: this.documentsData[0].filename,
          content: this.documentsData[0].content,
        })
      } catch (error) {
        console.log(error)
      }
    },
    reinitializeDocument() {
      document.getElementById("lddcVisualisator").src = ""
      document.getElementById("studyVisualisator").src = ""
      this.e1--
    },
    async fetchDataInsurer() {
      try {
        let headers = new Headers()
        let response
        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")
        this.insurerSelected.forEach(async element => {
          response = await fetch(`${config.apiUri}/insurers/${element}`, {
            mode: "cors",
            method: "GET",
            headers: headers,
          })

          const data = await response.json()
          // console.log(data)
          data.forEach(element => {
            this.studyItems.push({
              id: element.partnerId,
              name: element.name,
              url: element.website_url,
              city: element.city,
              street: element.street,
              zip: element.zip,
            })
          })
          console.log(this.studyItems)
        })
      } catch (e) {
        console.log(e)
      }
    },

    studyMake() {
      var dd = {
        info: {
          title: `Mandat d'étude projet ${this.productName}`,
        },
        content: [],
        styles: {
          header: {
            fontSize: 11,
            bold: true,
          },
          subheader: {
            fontSize: 11,
            bold: true,
          },
          quote: {
            italics: true,
          },
          small: {
            fontSize: 8,
          },
          test: {
            fontSize: 10,
          },
        },
      }

      this.studyItems.forEach((element, index) => {
        dd.content.push(
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          {
            alignment: "justify",
            columns: [
              {
                text: `${
                  this.radios === "Particulier"
                    ? `${this.genderClient} ${this.lastnameClient} ${this.firstnameClient}\r${this.streetClient}\r
                  ${this.zipClient} ${this.cityClient}`
                    : `${this.pappersInformations[0].nom_entreprise}\r
                  ${this.pappersInformations[0].siege.adresse_ligne_1}\r
                  ${this.pappersInformations[0].siege.code_postal} ${this.pappersInformations[0].siege.ville}`
                }
                  `,
              },
            ],
          },
          "\n",
          {
            columns: [
              {},
              {},
              {
                text: `${element.name}\r
                  ${element.street}\r
                  ${element.zip} ${element.city}`,
              },
            ],
          },
          "\n",
          "\n",
          "\n",
          "\n",
          {
            text: `Objet : Mandat d’étude et de placement \n`,
            bold: true,
          },
          "\n",
          "\n",
          {
            text: `${
              this.radios === "Particulier"
                ? `Je soussigné, ${this.genderClient} ${this.lastnameClient} ${this.firstnameClient} certifie être dûment habilité pour mandater C2T ASSURANCES, 1 Avenue du 41ème régiment d’infanterie 35000 Rennes,  à l’exclusion de tout autre intermédiaire  pour procéder à l’étude et au placement de la couverture d’assurance concernant le risque :`
                : `${this.signatory.length > 1 ? `Nous soussignons` : `Je soussigné`}, ${this.signatory.map(element => {
                    return " " + element.civility + ". " + element.name
                  })}, ${this.signatory.length > 1 ? `dirigeants` : `dirigeant`} de ${
                    this.pappersInformations[0].nom_entreprise
                  } ${this.signatory.length > 1 ? `certifient` : `certifie`} être dûment ${
                    this.signatory.length > 1 ? `habilités` : `habilité`
                  } pour mandater C2T ASSURANCES, 1 Avenue du 41ème régiment d’infanterie 35000 Rennes,  à l’exclusion de tout autre intermédiaire  pour procéder à l’étude et au placement de la couverture d’assurance concernant le risque :`
            }`,
            style: "test",
          },
          {
            text: `- ${this.productName}`,
            style: "test",
          },
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          {
            text: `Le présent mandat annulant tout ordre qui aurait pu être donné antérieurement.`,
            style: "test",
          },
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",

          {
            alignment: "justify",
            columns: [
              {},
              {},
              {
                text: `Rennes\n le : ${this.getToday()}
                ${this.signatory
                  .map(element => {
                    return element.name + "\n"
                  })
                  .join("")}`,
                style: "test",
              },
            ],
            pageBreak: index < this.insurerSelected.length - 1 ? "after" : "",
          },
        )
      })
      pdfMake.createPdf(dd).getDataUrl(function (outDoc) {
        document.getElementById("studyVisualisator").src = outDoc
      })
    },
    upRecommandAmount() {
      this.recommandAmount++
    },
    downRecommandAmount() {
      this.recommandAmount--
    },
    getCompanyPicture(website_url) {
      const logoInsurer = `https://logo.clearbit.com/https://${website_url}?size=128`
      return logoInsurer
    },
    onImageError(insurer) {
      this.errorImages.add(insurer.id)
      this.loadingImages.delete(insurer.id)
    },

    showLoadingIndicator(insurer) {
      return this.loadingImages.has(insurer.id)
    },
    showErrorIcon(insurer) {
      this.$set(insurer, "showErrorIcon", true)
    },
    async testMerger() {
      // await merger.add("http://mes-services.anavel.bzh/test.pdf") // merge only page 2
      // await merger.add("localhost:8080/test.pdf") // merge only page 2
      await merger.add(document.getElementById("lddcVisualisator").src)
      // await merger.add(document.getElementById("studyVisualisator").src)

      await merger.save(`LDDC-${this.productName}-${this.getTodayFormatFile()}`) //save under given name and reset the internal document
    },
    pushResume(title, value) {
      this.resume.push({
        title: title,
        value: value,
      })
    },
    chooseTypology(typology) {
      // lowerCase the typology and first letter to upperCase
      typology = typology.toLowerCase()
      typology = typology.charAt(0).toUpperCase() + typology.slice(1)
      this.radios = typology
      this.fetchAgencyProducts(typology)
      this.e1++
      this.hasUnsavedChanges = true
    },
    // ? Method to initialize array of cityItems empty
    initializeCity() {
      this.cityItems = []
    },
    // ? Method to upgdate progressbar value
    updateProgressBarUp() {
      this.progressionPdfProgressBar += 14.28571428571429
    },
    updateProgressBarDown() {
      this.progressionPdfProgressBar -= 14.28571428571429
    },
    // ? Method to get all insurers in Eole API
    async fetchInsurers() {
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(`${config.apiUri}/insurers`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        data.forEach(element => {
          this.statesInsurence.push(element.name)
          this.arrayChoiceInsurence.push(element.name)
        })
      } catch (e) {
        console.log(e)
      }
    },
    // ? Method to get products by insurerName
    async fetchAgencyProducts(typology) {
      console.log(this.radios)
      this.productsAgency = []

      let agencyId = localStorage.getItem("agency_id")
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(`${config.apiUri}/agencies/${agencyId}/products`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        data.products.forEach(element => {
          if (typology == "Particulier" && parseInt(element.particular) === 1) {
            this.productsArray.push(element)
          }
          if (typology === "Professionnel" && parseInt(element.professionnal) === 1) {
            this.productsArray.push(element)
          }
        })
        console.log(this.productsArray)
      } catch (e) {
        console.error(e)

        this.$toast.error("Impossible d'accéder aux données.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    // ? Method to get products by insurerName
    async fetchProductsByInsurerName(insurerName, clientTypology) {
      this.productsArray = []
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `${config.apiUri}/products?insurerName=${insurerName}&clientTypology=${clientTypology}`,
          {
            mode: "cors",
            method: "GET",
            headers: headers,
          },
        )
        const data = await response.json()

        data.products.forEach(element => {
          this.productsArray.push(element.productName)
        })
      } catch (e) {
        console.log(e)
      }
    },

    pushMain(value) {
      this.mainVehicle = []
      this.mainVehicleSelected = []
      this.mainVehicleDontKeep = []

      switch (this.productName) {
        case "Automobile":
          switch (value) {
            case "Tiers simple":
              this.guaranteesCopy[4].forEach(mv => {
                this.mainVehicle.push({
                  name: mv,
                })
                // this.mainVehicleSelected.push(mv)
              })

              break
            case "Tiers étendu":
              this.guaranteesCopy[5].forEach(mv => {
                this.mainVehicle.push(mv)
              })
              // this.mainVehicleSelected = [...this.mainVehicle]

              break
            case "Tous risques":
              this.guaranteesCopy[0].forEach(mv => {
                this.mainVehicle.push({
                  name: mv,
                })
                // this.mainVehicleSelected.push(mv)
              })
              // this.mainVehicleSelected = [...this.mainVehicle]
              break
          }
          break
        case "Moto/Scooter":
          switch (value) {
            case "Tiers simple":
              this.guaranteesCopy[2].forEach(mv => {
                this.mainVehicle.push(mv)
              })

              this.mainVehicleSelected = [...this.mainVehicle]
              break
            case "Tiers étendu":
              this.guaranteesCopy[0].forEach(mv => {
                this.mainVehicle.push(mv)
              })
              this.mainVehicleSelected = [...this.mainVehicle]

              break
            case "Tous risques":
              this.guaranteesCopy[3].forEach(mv => {
                this.mainVehicle.push(mv)
              })
              this.mainVehicleSelected = [...this.mainVehicle]

              break
          }
          break
      }
    },

    arrayDifference() {
      switch (this.productName) {
        case "Automobile":
          // this.guaranteesDontKeep = []

          // this.autoMainCategoryDontKeep.push(
          //   this.autoMainCategory.filter(x => !this.autoMainCategorySelected.includes(x)),
          // )

          // this.guaranteeKeep.forEach(el => {
          //   if (this.guaranteesFullForDifference.includes(el)) {
          //     //console.log("je rentre dans la condition si il existe dans l'array full diff")
          //     this.guaranteesFullForDifference.splice(this.guaranteesFullForDifference.indexOf(el), 1)
          //   }
          // })
          // this.guaranteesDontKeep.push(this.guaranteesFullForDifference.filter(x => !this.guaranteeKeep.includes(x)))
          // this.vehicleAdditionalDontKeep.push(
          //   this.vehicleAdditional.filter(x => !this.vehicleAdditionalSelected.includes(x)),
          // )
          // this.vehicleReplacementDontKeep.push(
          //   this.vehicleReplacement.filter(x => !this.vehicleReplacementSelected.includes(x)),
          // )

          // this.deductibleDontKeep.push(this.deductible.filter(x => !this.deductibleSelected.includes(x)))

          // this.mainVehicleDontKeep.push(this.mainVehicle.filter(x => !this.mainVehicleSelected.includes(x)))

          break
        case "Moto/Scooter":
          this.guaranteesDontKeep = []

          this.guaranteeKeep.forEach(el => {
            if (this.guaranteesFullForDifference.includes(el)) {
              //console.log("je rentre dans la condition si il existe dans l'array full diff")
              this.guaranteesFullForDifference.splice(this.guaranteesFullForDifference.indexOf(el), 1)
            }
          })
          this.guaranteesDontKeep.push(this.guaranteesFullForDifference.filter(x => !this.guaranteeKeep.includes(x)))

          this.vehicleAdditionalDontKeep.push(
            this.vehicleAdditional.filter(x => !this.vehicleAdditionalSelected.includes(x)),
          )
          console.log("Add scooter")
          console.log(this.vehicleAdditionalDontKeep)

          this.mainVehicleDontKeep.push(this.mainVehicle.filter(x => !this.mainVehicleSelected.includes(x)))
          this.deductibleDontKeep.push(this.deductible.filter(x => !this.deductibleSelected.includes(x)))

          break
        case "Responsabilité civile des dirigeants":
          this.physicalPersonDontKeep.push(this.guaranteesCopy[0].filter(x => !this.guaranteeKeep[0].includes(x)))
          this.physicalPersonKeep.push(this.guaranteeKeep[0])

          this.corporationDontKeep.push(this.guaranteesCopy[1].filter(x => !this.guaranteeKeep[1].includes(x)))
          this.corporationKeep.push(this.guaranteeKeep[1])

          break
        default:
          for (let index = 0; index < this.guaranteesCopy.length; index++) {
            // console.log(this.guaranteesCopy[index].filter(x => !this.guaranteeKeep[index].includes(x)))
            this.guaranteesDontKeep.push(this.guaranteesCopy[index].filter(x => !this.guaranteeKeep[index].includes(x)))
          }
          break
      }
      // if (this.productName != "Automobile") {
      //   for (let index = 0; index < this.guaranteesCopy.length; index++) {
      //     // console.log(this.guaranteesCopy[index].filter(x => !this.guaranteeKeep[index].includes(x)))
      //     this.guaranteesDontKeep.push(this.guaranteesCopy[index].filter(x => !this.guaranteeKeep[index].includes(x)))
      //   }
      // } else {
      //   this.guaranteesDontKeep = []

      //   this.guaranteeKeep.forEach(el => {
      //     if (this.guaranteesFullForDifference.includes(el)) {
      //       //console.log("je rentre dans la condition si il existe dans l'array full diff")
      //       this.guaranteesFullForDifference.splice(this.guaranteesFullForDifference.indexOf(el), 1)
      //     }
      //   })
      //   this.guaranteesDontKeep.push(this.guaranteesFullForDifference.filter(x => !this.guaranteeKeep.includes(x)))

      //   this.vehicleAdditionalDontKeep.push(
      //     this.vehicleAdditional.filter(x => !this.vehicleAdditionalSelected.includes(x)),
      //   )
      //   this.vehicleReplacementDontKeep.push(
      //     this.vehicleReplacement.filter(x => !this.vehicleReplacementSelected.includes(x)),
      //   )

      //   this.mainVehicleDontKeep.push(this.mainVehicle.filter(x => !this.mainVehicleSelected.includes(x)))
      // }

      //return this.guaranteesProposed.guaranteeName.filter(x => !this.guaranteesKeeped.guaranteeName.includes(x))
    },
    // arrayIntersection() {
    //   console.log(
    //     `Intersection du tableau (donc garanties souscrite : ${this.guaranteesProposed.filter(x =>
    //       this.guaranteesKeeped.includes(x),
    //     )}`,
    //   )
    //   return this.guaranteesProposed.filter(x => this.guaranteesKeeped.includes(x))
    // },
    // ? Method to get guarantee by product
    async fetchGuaranteesByProduct(productName) {
      this.guaranteesProposed = []
      this.guaranteeKeep = []
      this.guaranteesCopy = []
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(`${config.apiUri}/guarantees?productName=${productName}`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        data.guarantees.forEach((value, index) => {
          this.guaranteesProposed.push({
            categoryName: Object.getOwnPropertyNames(value)[0],
            guarantees: Object.values(value)[0].map(el => {
              return el.guaranteeName
            }),
          })
          this.guaranteesCopy.push(
            Object.values(value)[0].map(el => {
              return el.guaranteeName
            }),
          )
        })

        switch (this.productName) {
          case "Automobile":
            ///! Franchise
            this.guaranteesCopy[2].forEach(d => {
              this.deductible.push({
                name: d,
              })
            })

            ///! Remorque
            this.guaranteesCopy[3].forEach(t => {
              this.trailer.push(t)
            })

            //! Véhicule de remplacement
            this.guaranteesCopy[1].forEach(vr => {
              this.vehicleReplacement.push(vr)
            })
            //! garantie complémentaire
            this.guaranteesCopy[7].forEach(va => {
              this.vehicleAdditional.push({
                name: va,
              })
            })
            //! Mode de stationnement
            this.guaranteesCopy[8].forEach(vp => {
              // this.vehicleParking.push(vp)
              this.vehicleParking.push({
                guarantee: vp,
              })
            })

            for (let index = 4; index < this.guaranteesCopy.length; index++) {
              //("Element : HERE")

              for (let k = 0; k < this.guaranteesCopy[index].length; k++) {
                this.guaranteeKeep.push(this.guaranteesCopy[index][k])
              }
            }
            this.guaranteesCopy.forEach(el => {
              el.forEach(t => {
                this.guaranteesFullForDifference.push(t)
              })
            })
            this.guaranteesFetch = true

            break
          case "Moto/Scooter":
            ///! Permis - 1ère étape
            this.guaranteesCopy[7].forEach(licence => {
              this.licences.push(licence)
            })
            ///! cylindrée - 2me étape
            this.guaranteesCopy[8].forEach(engine => {
              this.engines.push(engine)
            })
            ///! Garanties tiers simples
            this.guaranteesCopy[2].forEach(ar => {
              this.guaranteeKeep.push(ar)
            })
            ///! Garanties tiers étendu
            this.guaranteesCopy[0].forEach(ar => {
              this.guaranteeKeep.push(ar)
            })
            ///! Garanties tous risques
            this.guaranteesCopy[3].forEach(ar => {
              this.guaranteeKeep.push(ar)
            })

            //! Mode de stationnement
            this.guaranteesCopy[6].forEach(vp => {
              this.vehicleParking.push(vp)
            })

            //! garantie complémentaire
            this.guaranteesCopy[5].forEach(va => {
              this.vehicleAdditional.push(va)
            })

            this.vehicleAdditional.push("Hivernage")
            this.vehicleAdditionalSelected.push("Hivernage")
            this.vehicleAdditional.push("Conduite exclusive")

            //! Protection vol
            this.guaranteesCopy[9].forEach(protection => {
              this.itemsAgainstSteal.push(protection)
            })

            ///! Franchise
            this.guaranteesCopy[1].forEach(d => {
              this.deductible.push(d)
            })

            this.guaranteesFetch = true

            break
          default:
            this.guaranteeKeep = [...this.guaranteesCopy]
            break
        }
      } catch (e) {
        console.log(e)
      }
    },

    // ? Method to get city by zip code using an API (https://github.com/BaseAdresseNationale/codes-postaux)
    fetchCityByZip(zip) {
      const codesPostaux = require("codes-postaux")
      this.initializeCity()
      if (this.zipClient.length === 5) {
        try {
          var data = codesPostaux.find(zip)
          for (let index = 0; index < data.length; index++) {
            this.cityItems.push(data[index].nomCommune)
          }

          this.successSelectCity = true
          this.successSelectCityMessage = `Nous avons trouvé ${data.length} villes ou communes ayant comme code postal ${zip}`
        } catch (e) {
          this.errorSelectCity = true
          this.errorSelectCityMessage = "Une erreur est survenue"
        }
      } else {
        this.errorSelectCity = true
        this.errorSelectCityMessage = "Le code postal doit être composé de 5 chiffres"
      }
    },

    // ? Method to get informations using a PAPPERS API (https://api.pappers.fr/)
    async fetchPappers(siret) {
      this.pappersInformations = []
      this.loadingData = true

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `https://api.pappers.fr/v2/entreprise?api_token=${config.tokenPappers}&siret=${siret}`,
          {
            mode: "cors",
            method: "GET",
            headers: headers,
          },
        )

        if (response.status === 400) {
          this.siretNotFound = true
          this.siretNotFoundMessage = "Impossible de trouver le siret que vous avez renseigné, merci de réessayer"
        } else {
          this.siretNotFound = false
          this.siretNotFoundMessage = ""
          let data = await response.json()

          if (data.representants[0].personne_morale === true) {
            let responseSiren = await fetch(
              `https://api.pappers.fr/v2/entreprise?api_token=${config.tokenPappers}&siren=${data.representants[0].siren}`,
              {
                mode: "cors",
                method: "GET",
                headers: headers,
              },
            )
            let dataSiren = await responseSiren.json()

            this.pappersInformations.push(data)
            this.pappersInformations[0].representants = []
            dataSiren.representants.forEach(ceo => {
              if (ceo.qualite === "Gérant") {
                this.pappersInformations.forEach(element => {
                  element.representants.push(ceo)
                })
              }
            })
          } else {
            this.pappersInformations.push(data)
          }
          this.dataPappers = true
          console.log(this.pappersInformations[0].representants)
        }
        this.loadingData = false
      } catch (e) {
        console.log(e)
      }
    },
  },
})
</script>
<style lang="scss">
// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }
.switchFont {
  font-size: 0.875rem !important;
}

.row-lddc {
  max-width: 450px;
}

.mullerFont {
  font-family: muller;
  font-size: 1rem;
}

.v-menu__content {
  max-width: 20rem !important;
}

.no-hover {
  background: inherit !important;
}

.search-client {
  color: white !important;
}

.row-drafts:hover {
  cursor: pointer;
  background-color: rgba(0, 31, 71, 0.3) !important;
  width: 100%;
}

.main-row {
  height: 90%;
}

.draft-placement {
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
}

.container-lddc {
  // //take full height of parent
  height: 100% !important;
  // // display flex
  // display: flex;
  // // align items center
  // align-items: center;
}

.container-typology {
  height: 100% !important;

  // // display flex
  display: flex !important;
  // // align items center
  align-items: center !important;
}

.transparent-bg {
  background-color: transparent !important;
}

.no-paddinLeftRight {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.no-border {
  border: none !important;
}

.group {
  display: flex;
}

.list-enter,
.list-leave-to {
  opacity: 0;
}

.list-leave-active {
  position: absolute;
}

.v-switch {
  color: red;
}

.v-slider {
  font-size: 10px;
}

.padding-insured {
  padding: 5px 24px 24px 24px;
}

.no-padding {
  padding: 0px !important;
}

.title-insured {
  padding: 0px 24px 0px 24px;
}

.no-padding-top {
  padding-top: 0px;
}
</style>
